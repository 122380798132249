// @flow

// eslint-disable-next-line import/prefer-default-export
import moment from 'moment';

export const convertSecondsToMinutes = (seconds: number) => Math.round(seconds / 60);

export const convertMinutesToSeconds = (minutes: number) => minutes * 60;

export const DEFAULT_DEBOUNCE_DURATION = 300; // ms

export const sleepDelay = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const handleDateChange = (date, time, setDate, updateDate) => {
  const dateTime = moment(`${date} ${time}`, 'DD.MM.YYYY HH:mm');
  setDate(dateTime.format('DD.MM.YYYY'));
  updateDate(dateTime.valueOf().toString());
};

const handleTimeChange = (time, date, setTime, updateDate) => {
  const dateTime = moment(`${date} ${time}`, 'DD.MM.YYYY HH:mm');
  setTime(dateTime.format('HH:mm'));
  updateDate(dateTime.valueOf().toString());
};

export const handleStartDateChange = (
  date: string,
  selectedStartTime: string,
  setSelectedStartDate: string => void,
  updateStartDate: string => void
) => handleDateChange(date, selectedStartTime, setSelectedStartDate, updateStartDate);
export const handleStartTimeChange = (
  time: string,
  selectedStartDate: string,
  setSelectedStartTime: string => void,
  updateStartDate: string => void
) => handleTimeChange(time, selectedStartDate, setSelectedStartTime, updateStartDate);
export const handleEndDateChange = (
  date: string,
  selectedEndTime: string,
  setSelectedEndDate: string => void,
  updateEndDate: string => void
) => handleDateChange(date, selectedEndTime, setSelectedEndDate, updateEndDate);
export const handleEndTimeChange = (
  time: string,
  selectedEndDate: string,
  setSelectedEndTime: string => void,
  updateEndDate: string => void
) => handleTimeChange(time, selectedEndDate, setSelectedEndTime, updateEndDate);

export const secondsToHumanReadableString = (seconds: number) => {
  if (seconds === 0) return '0s';
  const m = Math.floor(seconds / 60);
  const s = seconds % 60;
  return `${m ? `${m}min` : ''}${s ? `${s}s ` : ''}`;
};
