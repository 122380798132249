// @flow

import React, { type Element } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@design-system/component-library/src/components/Checkbox';

import styles from './ColumnSelect.module.scss';

type PropsT = {|
  visibleColumns: string[],
  onChange: (string[]) => void
|};

const ColumnSelect = ({ visibleColumns, onChange }: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  const MAX_FIELDS = 10;

  const ToggleVisibility = (fieldName: string): void => {
    let cc = JSON.parse(JSON.stringify(visibleColumns));
    if (cc.includes(fieldName)) {
      cc = cc.filter(k => k !== fieldName);
    } else {
      cc.push(fieldName);
    }
    onChange(cc);
  };

  const CanSelectMoreColumns = (): boolean => {
    return visibleColumns.length < MAX_FIELDS;
  };

  const ColumnCheckBox = (fieldName: string): Element<'div'> => {
    return (
      <Checkbox
        name={`${fieldName}_visibility`}
        label={t(`phonebook.fieldLabels.${fieldName}`)}
        checked={visibleColumns.includes(fieldName)}
        disabled={!visibleColumns.includes(fieldName) && !CanSelectMoreColumns()}
        onChange={() => {
          ToggleVisibility(fieldName);
        }}
      />
    );
  };

  return (
    <div className={styles['column-select']}>
      <div className={styles['title-text']}>{t(`phonebook.columnSelect.title`)}</div>
      <div>{t(`phonebook.columnSelect.description`)}</div>
      <div className={styles['column-group']}>
        {ColumnCheckBox('enterprise')}
        {ColumnCheckBox('department')}
        {ColumnCheckBox('recordType')}
      </div>
      <div className={styles['column-group']}>
        {ColumnCheckBox('lastName')}
        {ColumnCheckBox('firstName')}
        {ColumnCheckBox('title')}
        {ColumnCheckBox('emails')}
        {ColumnCheckBox('nickName')}
      </div>
      <div className={styles['column-group']}>
        {ColumnCheckBox('phoneNumbers')}
        {ColumnCheckBox('addressNumber')}
        {ColumnCheckBox('additionalExplanations')}
        {ColumnCheckBox('additionalInfo')}
        {ColumnCheckBox('contactInformation')}
      </div>
      <div className={styles['column-group']}>
        {ColumnCheckBox('corporateUserId')}
        {ColumnCheckBox('accessCtrlSystemPersonId')}
        {ColumnCheckBox('officeReferenceID')}
        {ColumnCheckBox('costCenter')}
      </div>
    </div>
  );
};
export default ColumnSelect;
