/* eslint-disable flowtype/no-weak-types */
// @flow

import React, { type Element, useState } from 'react';
import Tooltip from '@design-system/component-library/src/components/Tooltip';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import IconInformationRegular from '@design-system/component-library/src/components/Icon/lib/IconInformationRegular';
import { Button } from '@design-system/component-library';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OFFICE_HOURS } from './calendarTemplateUtil';
import Dismiss from '../../../components/Button/Dismiss';
import TimeSlotEditor from '../components/OpeningHoursDialog/TimeSlotEditor';
import 'react-time-picker/dist/TimePicker.css';
import { validateTimeSlots } from '../callFlowGrid/details/CalendarUtils';

import styles from './AddHolidaysDialog.module.scss';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';

export type PropsT = {
  onCancel: (*) => *,
  onConfirm: (any, any) => *,
  onClose?: (*) => *
};

const AddHolidaysDialog = ({ onCancel, onConfirm, onClose }: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  const [selectedDate, _setSelectedDate] = useState({
    slots: [],
    closedAllDay: true,
    repeats: false
  });
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const [closedAllDay, setClosedAllDay] = useState(selectedDate.closedAllDay);
  const [timeSlots, setTimeSlots] = useState(selectedDate.slots);

  const [selectedHolidays, setSelectedHolidays] = useState([]);

  const setSelectedDate = date => {
    _setSelectedDate(date);
    setClosedAllDay(date.closedAllDay);
    setTimeSlots(date.slots);
  };

  const toggleHoliday = (name: string) => {
    if (selectedHolidays.includes(name)) {
      selectedHolidays.splice(selectedHolidays.indexOf(name), 1);
    } else {
      selectedHolidays.push(name);
    }
    setSelectedHolidays(selectedHolidays);
  };

  const toggleClosedAllDay = () => {
    const val = !selectedDate.closedAllDay;
    selectedDate.closedAllDay = val;
    if (!val) {
      selectedDate.slots.push(OFFICE_HOURS);
    } else {
      selectedDate.slots = [];
    }
    setClosedAllDay(val);
  };

  const handleTimeSlotsUpdated = slots => {
    selectedDate.slots = slots;
    setSelectedDate({
      ...selectedDate
    });
  };

  const renderToggleFor = (holiday: string, infoText?: string): Element<'div'> => {
    return (
      <div className={styles['checkbox-container']}>
        <Checkbox
          id={`holidays-toggle-${holiday}`}
          key={`holidays-toggle-${holiday}`}
          name={`holidays-toggle-${holiday}`}
          data-cy={`holidays-toggle-${holiday}`}
          value={selectedHolidays.includes(holiday)}
          checked={selectedHolidays.includes(holiday)}
          onChange={() => {
            toggleHoliday(holiday);
          }}
          label={t(`holidays.${holiday}`)}
        />
        {infoText && (
          <Tooltip
            open
            triggerElement={<IconInformationRegular />}
            i18n_tooltip_contentText={infoText}
          />
        )}
      </div>
    );
  };

  return (
    <div
      className={`ea-modal ea-modal--open styleguide-dialog-position ${styles.container}`}
      role="dialog"
      aria-modal="true"
    >
      <div className="ea-modal__overlay" />
      <div className={`ea-modal__content ${styles.content}`}>
        {onClose && <Dismiss id="close-button" onClose={onClose} />}
        <div className={styles.box}>
          <h2 id="dialog-title" className="ea-h3 ea-h3--thick">
            {t('calendars.templates.addHolidaysDialogTitle')}
          </h2>
          <div className={styles['flex-container']}>
            <div>
              <div className={styles.subheader}>
                {t('calendars.templates.addHolidaysDialogHolidaysTitle')}
              </div>
              <div className={styles.infotext}>
                {t('calendars.templates.addHolidaysDialogHolidaysHelp')}
              </div>
              {renderToggleFor(
                'all_official',
                currentUser.country === 'ee'
                  ? t('calendars.templates.addHolidaysDialogHolidayListEE')
                  : t('calendars.templates.addHolidaysDialogHolidayList')
              )}
              {currentUser.country !== 'ee' &&
                renderToggleFor(
                  `christmas_eve${currentUser.country ? `_${currentUser.country}` : ''}`
                )}
              {currentUser.country !== 'ee' &&
                renderToggleFor(
                  `midsummers_eve${currentUser.country ? `_${currentUser.country}` : ''}`
                )}
            </div>
            <div>
              <div className={styles.subheader}>{t('calendars.templates.openingHoursTitle')}</div>
              <div className={styles.infotext}>{t('calendars.templates.openingHoursHelp')}</div>
              <Checkbox
                id="allday-switch"
                key="allday-switch"
                name="allday-switch"
                data-cy="allday-switch"
                value={closedAllDay}
                checked={closedAllDay}
                onChange={() => {
                  toggleClosedAllDay();
                }}
                label={t('generic.dateTimePicker.allDay')}
              />
              {!closedAllDay && (
                <div>
                  <TimeSlotEditor
                    slots={timeSlots}
                    disabled={false}
                    onUpdated={handleTimeSlotsUpdated}
                  />
                  {validateTimeSlots(closedAllDay, timeSlots) ? (
                    <div className={styles.error}>{t('calendars.timeSlotError')}</div>
                  ) : null}
                </div>
              )}

              <div className={styles.subheader}>
                {t('calendars.templates.addHolidaysDialogRepeatTitle')}
              </div>
              <div className={styles.infotext}>
                {t('calendars.templates.addHolidaysDialogRepeatHelp')}
              </div>
              <Checkbox
                id="repeat-toggle"
                key="repeat-toggle"
                name="repeat-toggle"
                data-cy="repeat-toggle"
                value={selectedDate.repeats}
                checked={selectedDate.repeats}
                onChange={() => {
                  selectedDate.repeats = !selectedDate.repeats;
                  console.log(selectedDate.repeats);
                }}
                label={t('calendars.templates.addHolidaysDialogRepeatLabel')}
              />
              <div className={styles.buttons}>
                <Button size="l" color="link" onClick={onCancel}>
                  {t('calendars.templates.dialogActions.cancel')}
                </Button>
                <Button
                  size="l"
                  color="primary"
                  onClick={() => onConfirm(selectedHolidays, selectedDate)}
                  disabled={validateTimeSlots(closedAllDay, timeSlots)}
                >
                  {t('calendars.templates.dialogActions.save')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHolidaysDialog;
