// @flow

/* eslint-disable import/prefer-default-export, flowtype/no-weak-types, no-restricted-syntax, no-await-in-loop */

import * as R from 'ramda';
import axios, { CancelToken } from 'axios';
import moment from 'moment';
import HTTP from 'http-status-codes';
import type { StoreStateT, TranslateT } from './commonTypes';
import type {
  AvailabilityT,
  PresenceStateSimpleT,
  TelephonicStateT
} from './ducks/entities/user/userTypes';
import { history as historyFromStore } from './store';
import configReducer from './ducks/config/configReducer';
import currentUserReducer from './ducks/currentUser/currentUserReducer';
import enterprisesUiReducer from './ducks/ui/enterprises/enterprisesUiReducer';
import headerUiReducer from './ducks/ui/header/headerUiReducer';
import notificationUiReducer from './ducks/ui/notification/notificationUiReducer';
import { initialState as tourInitialState } from './ducks/ui/tour/tourReducer';
import { initialState as userUiInitialState } from './ducks/ui/user/userUiReducer';
import { initialState as formsUiInitialState } from './ducks/ui/forms/formsUiReducer';
import departmentReducer from './ducks/entities/department/departmentReducer';
import { initialState as enterpriseEntityInitialState } from './ducks/entities/enterprise/enterpriseReducer';
import { initialState as departmentUiInitialState } from './ducks/ui/department/departmentUiReducer';
import { initialState as userImportUiInitialState } from './ducks/ui/userImport/userImportUiReducer';
import locationEntityReducer from './ducks/entities/location/locationReducer';
import userEntityReducer from './ducks/entities/user/userReducer';
import type {
  CallForwardingDestinationT,
  CallForwardingT
} from './ducks/entities/callForwarding/callForwardingTypes';
import type { DirectoryEntityT } from './ducks/entities/directory';
import type { AliasT } from './ducks/entities/directory/directoryTypes';
import { createCsrfHeader } from './utils/accessRightUtils';

type CreateRedirectParamFnT = (?{ location: $Shape<Location> }) => string;

export const createRedirectParam: CreateRedirectParamFnT = (history = historyFromStore): string => {
  if (history) {
    let currentPath = history.location.pathname;
    if (history.location.search) {
      currentPath = currentPath.concat(history.location.search);
    }
    return currentPath.startsWith('/login') || currentPath.length < 2
      ? ''
      : `?redirect=${currentPath}`;
  }
  return '';
};

const isRefVisible = (ref: *) => {
  if (ref && ref.current) {
    const rect = ref.current.getBoundingClientRect();
    const docElement = document.documentElement;
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || (docElement ? docElement.clientHeight : 0)) &&
      rect.right <= (window.innerWidth || (docElement ? docElement.clientWidth : 0))
    );
  }
  return false;
};

export const scrollToRef = (ref: *) => {
  const SCROLL_TIMEOUT = 1;
  if (ref && ref.current && !isRefVisible(ref)) {
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, SCROLL_TIMEOUT);
  }
};

type GetStateFnT = mixed => StoreStateT;
export const getState: GetStateFnT = slice =>
  R.mergeDeepRight(
    {
      config: configReducer.initialState,
      currentUser: currentUserReducer.initialState,
      form: {},
      localize: {},
      router: {},
      ui: {
        tour: tourInitialState,
        header: headerUiReducer.initialState,
        notification: notificationUiReducer.initialState,
        enterprises: enterprisesUiReducer.initialState,
        user: userUiInitialState,
        department: departmentUiInitialState,
        forms: formsUiInitialState,
        userImport: userImportUiInitialState
      },
      entities: {
        department: departmentReducer.initialState,
        enterprise: enterpriseEntityInitialState,
        location: locationEntityReducer.initialState,
        user: userEntityReducer.initialState
      }
    },
    slice
  );

export async function fetchExtensionIdWithAddressNumber(
  enterpriseId: string,
  addressNumber: string,
  cancelToken: CancelToken
): Promise<?string> {
  if (addressNumber) {
    return axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/userIds/byAddressNumber/${addressNumber}`,
      cancelToken
    }).then(returned => returned.data.extensionId);
  }
  return null;
}

export const retrieveTemporaryVoicemailSettings = async (
  enterpriseId: string,
  environment: string,
  entityId: string,
  entityType: string,
  setTemporaryVoicemailSettings: *
) => {
  const response = await axios({
    method: 'GET',
    url: `/api/v1/config/enterprise/${enterpriseId}/voicemail?environment=${environment}&targetId=${entityId}&targetType=${entityType}`
  });
  if (response && response.data) {
    setTemporaryVoicemailSettings(response.data);
  }
};

export const createTemporaryVoicemailSetting = async (
  formData: *,
  currentUser: *,
  enterpriseId: string,
  entityId: string,
  entityType: string,
  setTemporaryVoicemailSettings: *
) => {
  const payload = {
    environment: currentUser.environment,
    enterpriseId,
    targetId: entityId,
    targetType: entityType,
    startTimeInMillis: formData.voiceMailAudio.startTimeInMillis,
    endTimeInMillis: formData.voiceMailAudio.endTimeInMillis
  };
  const response = await axios({
    method: 'POST',
    url: `/api/v1/enterprises/${enterpriseId}/temporary_voicemail`,
    headers: createCsrfHeader(currentUser),
    data: payload,
    validateStatus: status => status === HTTP.OK
  });
  if (response && response.data) {
    setTemporaryVoicemailSettings(response.data);
  }
};

export const updateTemporaryVoicemailSetting = async (
  formData: *,
  id: string,
  enterpriseId: string,
  currentUser: *,
  entityId: string,
  entityType: string,
  setTemporaryVoicemailSettings: *
) => {
  const payload = {
    environment: currentUser.environment,
    enterpriseId,
    id,
    targetId: entityId,
    targetType: entityType,
    startTimeInMillis: formData.voiceMailAudio.startTimeInMillis,
    endTimeInMillis: formData.voiceMailAudio.endTimeInMillis
  };
  const response = await axios({
    method: 'PATCH',
    url: `/api/v1/enterprises/${enterpriseId}/temporary_voicemail/${id}`,
    headers: createCsrfHeader(currentUser),
    data: payload,
    validateStatus: status => status === HTTP.OK
  });
  if (response && response.data) {
    setTemporaryVoicemailSettings(response.data);
  }
};

export const deleteTemporaryVoicemailSetting = async (
  id: string,
  enterpriseId: string,
  currentUser: *,
  setTemporaryVoicemailSettings: *
) => {
  try {
    const payload = {
      environment: currentUser.environment,
      enterpriseId,
      id
    };
    await axios({
      method: 'DELETE',
      url: `/api/v1/enterprises/${enterpriseId}/temporary_voicemail/${id}`,
      headers: createCsrfHeader(currentUser),
      data: payload,
      validateStatus: status => status === HTTP.OK
    });
    setTemporaryVoicemailSettings(null);
  } catch (error) {
    console.error(error);
  }
};

export async function fetchExtensionIdsWithAddressNumbers(
  enterpriseId: string,
  addressNumbers: (string | typeof undefined)[],
  cancelToken: CancelToken,
  headers: {}
): Promise<string[]> {
  if (addressNumbers) {
    const returnValue = await axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/userIds/byAddressNumbers`,
      data: addressNumbers,
      cancelToken,
      headers
    });
    if (returnValue) {
      return returnValue.data.map(value => value.extensionId);
    }
    return [];
  }
  return [];
}

export async function asyncPool(poolLimit: number, array: *[], iteratorFn: Function) {
  const ret = [];
  const executing = [];
  // eslint-disable-next-line no-unused-vars
  for (const item of array) {
    const p = Promise.resolve().then(() => iteratorFn(item, array));
    ret.push(p);
    const e = p.then(() => executing.splice(executing.indexOf(e), 1));
    executing.push(e);
    if (executing.length >= poolLimit) {
      await Promise.race(executing);
    }
  }
  return Promise.all(ret);
}

export const getCallFlowType = (
  alias: AliasT,
  ivrName: string,
  displayName: string,
  pstnNumbers: ?(string[]),
  externalDestination: ?string
) => {
  if (alias === 'ACDGroupAddress') {
    if (displayName.startsWith('kutsu:')) {
      return 'ACD_SWITCHBOARD';
    }
    return 'ACD_CUSTOMER_SERVICE';
  }
  if (alias === 'GroupAddress') {
    return 'EXTENSION_GROUP';
  }
  if (alias === 'SpeedDial') {
    if (pstnNumbers && externalDestination && pstnNumbers.includes(externalDestination)) {
      return 'OC';
    }
    return 'SPEED_DIAL';
  }
  if (alias === 'IVRService') {
    if (ivrName.includes('WelcomeAttendant')) {
      return 'WELCOME_ATTENDANT';
    }
    if (ivrName.includes('PlayMusic')) {
      return 'PLAY_MUSIC';
    }
  }
  return null;
};

export function stripExtension(str: string) {
  return str && str.includes('.') ? str.substring(0, str.lastIndexOf('.')) : str;
}

export function basename(str: string, sep: string) {
  return str.substring(str.lastIndexOf(sep) + 1);
}

export function categoryHasData(category: {}) {
  return Object.keys(category).some(key => !R.isNil(category[key]));
}

export const NO_VALUE_FIELD_ID = '@NULL';

export function hasValues(category: {}) {
  return (
    category &&
    Object.keys(category).some(key => {
      // $FlowFixMe
      if (typeof category[key] === 'object' && !R.isNil(category[key])) {
        return hasValues(category[key]);
      }
      // $FlowFixMe
      if (key === 'key' && category[key] === NO_VALUE_FIELD_ID) {
        return false;
      }
      // $FlowFixMe
      return category[key] !== '' && !R.isNil(category[key]);
    })
  );
}

const CYPRESS_RUNNER_UA = 'Cypress';

// Some functionality (e.g. animated gifs) can cause instability on CI. Thus this helper.
export function isBrowserRunningE2eTests(): boolean {
  return navigator.userAgent === CYPRESS_RUNNER_UA || window.Cypress;
}

export const getAnchorElementPosition = (anchorElement: *): ?{ x: number, y: number } => {
  const anchorElementRect = anchorElement != null ? anchorElement.getBoundingClientRect() : null;
  if (anchorElementRect != null) {
    return {
      x: anchorElementRect.right - anchorElementRect.width / 2,
      y: anchorElementRect.top
    };
  }
  return null;
};

const getFilterOrSpecificCallerTextFragment = (
  forwardingRule: $Diff<CallForwardingT, { id: string }>,
  longOrTwoPart: 'long' | 'twoPart' = 'long',
  translate: TranslateT<string>
): string | void => {
  let filterOrSpecificCaller;
  if (forwardingRule.specificCaller) {
    if (forwardingRule.specificCaller.includes(',')) {
      filterOrSpecificCaller = translate(
        `forwardingSummary.${longOrTwoPart}.filter.SPECIFIC_CALLER.multiple`,
        {
          numbers: forwardingRule.specificCaller
            ? forwardingRule.specificCaller.split(',').join(', ')
            : ''
        }
      );
    } else {
      filterOrSpecificCaller = translate(
        `forwardingSummary.${longOrTwoPart}.filter.SPECIFIC_CALLER.single`,
        {
          number: forwardingRule.specificCaller
        }
      );
    }
  } else if (forwardingRule.filter && forwardingRule.filter !== 'ALL_CALLS') {
    filterOrSpecificCaller = translate(
      `forwardingSummary.${longOrTwoPart}.filter.${forwardingRule.filter}`
    );
  }
  return filterOrSpecificCaller;
};

const getFiltersOnTargetNumberTextFragment = (
  forwardingRule: $Diff<CallForwardingT, { id: string }>,
  longOrTwoPart: 'long' | 'twoPart' = 'long',
  translate: TranslateT<string>
): string | void => {
  let filtersOnTargetNumber;
  if (forwardingRule.filtersOnTargetNumber && forwardingRule.filtersOnTargetNumber.length !== 3) {
    filtersOnTargetNumber = forwardingRule.filtersOnTargetNumber
      .map(filter =>
        translate(`forwardingSummary.${longOrTwoPart}.filtersOnTargetNumber.${filter}`)
      )
      .join(` ${translate(`forwardingSummary.${longOrTwoPart}.or`)} `);
  }
  return filtersOnTargetNumber;
};

const getTypeTextFragment = (
  forwardingRule: $Diff<CallForwardingT, { id: string }>,
  longOrTwoPart: 'long' | 'twoPart' = 'long',
  translate: TranslateT<string>
): string | void => {
  let type;
  if (forwardingRule.forwardingType === 'NO_ANSWER') {
    type = translate(`forwardingSummary.${longOrTwoPart}.type.${forwardingRule.forwardingType}`, {
      seconds: forwardingRule.noAnswerForwardingDelay
        ? forwardingRule.noAnswerForwardingDelay / 1000
        : 0
    });
  } else {
    type = translate(`forwardingSummary.${longOrTwoPart}.type.${forwardingRule.forwardingType}`);
  }
  return type;
};

export const getTargetName = (target: ?CallForwardingDestinationT): string =>
  target && target.text ? target.text : '';

export const getDestinationTextFragment = (
  forwardingRule: $Diff<CallForwardingT, { id: string }>,
  longOrTwoPart: 'long' | 'twoPart' = 'long',
  translate: TranslateT<string>
): string | void => {
  let destination = '';
  if (forwardingRule.destination) {
    if (forwardingRule.destination.type === 'REJECTION') {
      return undefined;
    }
    if (forwardingRule.serviceName) {
      const supportedAdditionalServices = [
        'AvailabilityAttendant',
        'BossSecretary',
        'CallQueuingService',
        'CallScreeningService',
        'CallBackOnBusy'
      ];
      if (supportedAdditionalServices.includes(forwardingRule.serviceName)) {
        return translate(
          `forwardingSummary.destinationService.${forwardingRule.serviceName || 'missing'}`
        );
      }
      return forwardingRule.serviceName;
    }
    if (forwardingRule.destination.type === 'INTERNAL') {
      if (getTargetName(forwardingRule.destination)) {
        destination = translate(
          `forwardingSummary.${longOrTwoPart}.destination.${forwardingRule.destination.type}`,
          {
            name: getTargetName(forwardingRule.destination)
          }
        );
      } else {
        destination = translate(`forwardingSummary.${longOrTwoPart}.destination.EXTENSION`, {
          number: forwardingRule.destination.value || ''
        });
      }
    } else if (
      forwardingRule.destination.type === 'EXTERNAL' ||
      forwardingRule.destination.type === 'USER_MOBILE'
    ) {
      destination = translate(
        `forwardingSummary.${longOrTwoPart}.destination.${forwardingRule.destination.type}`,
        {
          number: forwardingRule.destination.value
        }
      );
    } else {
      destination = translate(
        `forwardingSummary.${longOrTwoPart}.destination.${forwardingRule.destination.type}`
      );
    }
  }
  return destination;
};

const getPresenceTextFragment = (
  forwardingRule: $Diff<CallForwardingT, { id: string }>,
  longOrTwoPart: 'long' | 'twoPart' = 'long',
  translate: TranslateT<string>
): string | void => {
  let presence;
  if (forwardingRule.presenceStateFilter && forwardingRule.presenceStateFilter.length > 0) {
    presence = translate(`forwardingSummary.${longOrTwoPart}.presenceStateFilter.combined`, {
      statuses: forwardingRule.presenceStateFilter
        .map(filter =>
          translate(`forwardingSummary.${longOrTwoPart}.presenceStateFilter.${filter}`)
        )
        .join(` ${translate(`forwardingSummary.${longOrTwoPart}.or`)} `)
    });
  }
  return presence;
};

export type TwoPartForwardingTextRepresentationTypeT = {
  conditions: string,
  destination: string,
  timeFilter: string
};

const getLongTextFragment = (
  forwardingRule: $Diff<CallForwardingT, { id: string }>,
  filter: string | void,
  filtersOnTarget: string | void,
  type: string | void,
  destination: string | void,
  presence: string | void,
  translate: TranslateT<string>
): string =>
  `${[
    destination
      ? translate('forwardingSummary.long.move')
      : translate('forwardingSummary.long.reject'),
    filter,
    filtersOnTarget,
    translate('forwardingSummary.long.incoming'),
    translate('forwardingSummary.long.calls'),
    ...(forwardingRule.forwardingType !== 'ALWAYS' && forwardingRule.forwardingType !== 'PRESENCE'
      ? [`${destination || ''},`, type]
      : [
          forwardingRule.forwardingType !== 'PRESENCE' ? type : undefined,
          (destination || '') + (presence ? ',' : '')
        ]),
    forwardingRule.forwardingType !== 'ALWAYS' &&
    forwardingRule.forwardingType !== 'PRESENCE' &&
    presence
      ? `${translate('forwardingSummary.long.and')} ${presence}`
      : undefined,
    (forwardingRule.forwardingType === 'ALWAYS' || forwardingRule.forwardingType === 'PRESENCE') &&
    presence
      ? presence
      : undefined
  ]
    .filter(token => token !== undefined)
    .join(' ')
    .replace(/\s,/g, ',')}.`;

const getTwoPartTextFragment = (
  fwd: $Diff<CallForwardingT, { id: string }>,
  filter: string | void,
  filtersOnTarget: string | void,
  type: string | void,
  destination: string | void,
  presence: string | void,
  translate: TranslateT<string>
): string => {
  let conds = '';

  // Refactor these conditions by formalizing these into an array
  if (!filter && !filtersOnTarget && fwd.forwardingType === 'ALWAYS' && presence) {
    conds = presence || '';
  } else if (!filter && !filtersOnTarget && fwd.forwardingType !== 'ALWAYS' && presence) {
    conds = `${type || ''}, ${translate('forwardingSummary.twoPart.and')} ${presence || ''}`;
  } else if (filter && filtersOnTarget && fwd.forwardingType === 'ALWAYS' && presence) {
    conds = `${filter || ''} ${filtersOnTarget || ''} ${translate(
      'forwardingSummary.twoPart.incoming'
    )} ${translate('forwardingSummary.twoPart.calls')}, ${presence || ''}`;
  } else if (filter && filtersOnTarget && fwd.forwardingType !== 'ALWAYS' && presence) {
    conds = `${filter || ''} ${filtersOnTarget || ''} ${translate(
      'forwardingSummary.twoPart.incoming'
    )} ${translate('forwardingSummary.twoPart.calls')}, ${type || ''}, ${presence || ''}`;
  } else if (!filter && !filtersOnTarget && fwd.forwardingType === 'ALWAYS' && !presence) {
    conds = type || '';
  } else if (!filter && !filtersOnTarget && fwd.forwardingType !== 'ALWAYS' && !presence) {
    conds = `${type || ''}`;
  } else if (filter && filtersOnTarget && fwd.forwardingType === 'ALWAYS' && !presence) {
    conds = `${filter || ''} ${filtersOnTarget || ''} ${translate(
      'forwardingSummary.twoPart.incoming'
    )} ${translate('forwardingSummary.twoPart.calls')}`;
  } else if (filter && filtersOnTarget && fwd.forwardingType !== 'ALWAYS' && !presence) {
    conds = `${filter || ''} ${filtersOnTarget || ''} ${translate(
      'forwardingSummary.twoPart.incoming'
    )} ${translate('forwardingSummary.twoPart.calls')}, ${type || ''}`;
  } else if (!filter && filtersOnTarget && fwd.forwardingType === 'ALWAYS' && presence) {
    conds = `${filtersOnTarget} ${translate('forwardingSummary.twoPart.incoming')} ${translate(
      'forwardingSummary.twoPart.calls'
    )}, ${presence || ''}`;
  } else if (filter && !filtersOnTarget && fwd.forwardingType !== 'ALWAYS' && presence) {
    conds = `${filter || ''} ${translate('forwardingSummary.twoPart.incoming')} ${translate(
      'forwardingSummary.twoPart.calls'
    )}, ${type || ''}, ${translate('forwardingSummary.twoPart.and')} ${presence || ''}`;
  } else if (!filter && filtersOnTarget && fwd.forwardingType === 'ALWAYS' && !presence) {
    conds = `${filtersOnTarget} ${translate('forwardingSummary.twoPart.incoming')} ${translate(
      'forwardingSummary.twoPart.calls'
    )}`;
  } else if (!filter && filtersOnTarget && fwd.forwardingType !== 'ALWAYS' && !presence) {
    conds = `${filtersOnTarget} ${translate('forwardingSummary.twoPart.incoming')} ${translate(
      'forwardingSummary.twoPart.calls'
    )}, ${type || ''}`;
  } else if (filter && !filtersOnTarget && fwd.forwardingType === 'ALWAYS' && presence) {
    conds = `${filter || ''} ${translate('forwardingSummary.twoPart.incoming')} ${translate(
      'forwardingSummary.twoPart.calls'
    )}, ${presence || ''}`;
  } else if (filter && !filtersOnTarget && fwd.forwardingType === 'ALWAYS' && !presence) {
    conds = `${filter || ''} ${translate('forwardingSummary.twoPart.incoming')} ${translate(
      'forwardingSummary.twoPart.calls'
    )}`;
  } else if (!filter && filtersOnTarget && fwd.forwardingType === 'ALWAYS' && presence) {
    conds = `${filtersOnTarget} ${translate('forwardingSummary.twoPart.incoming')} ${translate(
      'forwardingSummary.twoPart.calls'
    )}, ${presence || ''}`;
  } else if (!filter && filtersOnTarget && fwd.forwardingType !== 'ALWAYS' && presence) {
    conds = `${filtersOnTarget} ${translate('forwardingSummary.twoPart.incoming')} ${translate(
      'forwardingSummary.twoPart.calls'
    )}, ${type || ''}, ${translate('forwardingSummary.twoPart.and')} ${presence || ''}`;
  } else if (filter && !filtersOnTarget && fwd.forwardingType !== 'ALWAYS' && presence) {
    conds = `${filter || ''} ${translate('forwardingSummary.twoPart.incoming')} ${translate(
      'forwardingSummary.twoPart.calls'
    )}, ${type || ''}, ${translate('forwardingSummary.twoPart.and')} ${presence || ''}`;
  } else if (!filter && filtersOnTarget && fwd.forwardingType === 'ALWAYS' && !presence) {
    conds = `${filtersOnTarget || ''} ${translate(
      'forwardingSummary.twoPart.incoming'
    )} ${translate('forwardingSummary.twoPart.calls')}`;
  } else if (filter && !filtersOnTarget && fwd.forwardingType !== 'ALWAYS' && !presence) {
    conds = `${filter} ${translate('forwardingSummary.twoPart.incoming')} ${translate(
      'forwardingSummary.twoPart.calls'
    )}, ${type || ''}`;
  } else if (!filter && filtersOnTarget && fwd.forwardingType === 'ALWAYS' && !presence) {
    conds = `${filtersOnTarget || ''} ${translate(
      'forwardingSummary.twoPart.incoming'
    )} ${translate('forwardingSummary.twoPart.calls')}`;
  } else if (filter && !filtersOnTarget && fwd.forwardingType !== 'ALWAYS' && !presence) {
    conds = `${filter || ''} ${translate('forwardingSummary.twoPart.incoming')} ${translate(
      'forwardingSummary.twoPart.calls'
    )}, ${type || ''}`;
  } else {
    conds = 'unknown';
  }

  return `${conds[0].toLocaleUpperCase()}${conds.substring(1)}`;
};

export const getTextRepresentation = (
  rule: $Shape<CallForwardingT>,
  longOrTwoPart: 'long' | 'twoPart' = 'long',
  translate: TranslateT<string>
): string | TwoPartForwardingTextRepresentationTypeT => {
  // $FlowFixMe
  const ruleTyped: $Diff<CallForwardingT, { id: string }> = rule;
  const filter = getFilterOrSpecificCallerTextFragment(ruleTyped, longOrTwoPart, translate);
  const filtersOnTarget = getFiltersOnTargetNumberTextFragment(ruleTyped, longOrTwoPart, translate);
  const type = getTypeTextFragment(ruleTyped, longOrTwoPart, translate);
  const destination = getDestinationTextFragment(ruleTyped, longOrTwoPart, translate);
  const presence = getPresenceTextFragment(ruleTyped, longOrTwoPart, translate);

  if (longOrTwoPart === 'long') {
    return getLongTextFragment(
      ruleTyped,
      filter,
      filtersOnTarget,
      type,
      destination,
      presence,
      translate
    );
  }

  const conditionsText: string = getTwoPartTextFragment(
    ruleTyped,
    filter,
    filtersOnTarget,
    type,
    destination,
    presence,
    translate
  );

  return {
    conditions: conditionsText,
    destination: destination || translate('forwardingSummary.long.reject'),
    timeFilter: rule.timeFilter ?? ''
  };
};

type GenerateKeysFnT = (string[], string) => { [string]: string };
export const generateKeys: GenerateKeysFnT = (array, prefix): * =>
  array
    ? array.reduce((accumulator: {}, currentValue: string, index: number): * => {
        accumulator[`${prefix}_${index}`] = currentValue; // eslint-disable-line no-param-reassign
        return accumulator;
      }, {})
    : {};

export const extractFileName = (path: string) => path.replace(/^.*[\\\/]/, ''); // eslint-disable-line

export const welcomeAttendantAudioUrl = (
  enterpriseId: string,
  callflowId: string,
  audioName: string
) =>
  `/api/v1/enterprises/${enterpriseId}/services/welcomeattendants/${callflowId}/audio?audioName=${audioName}`;

export type UserAvailabilityT = {|
  icon: AvailabilityT,
  text: string
|};

const formPersonalCallForwarding = (
  accumulator: ?{},
  currentValue: ?CallForwardingT,
  translate: TranslateT<string>
) => {
  return (
    currentValue && {
      ...accumulator,
      [`forwarding-isActive-${currentValue.id}`]: currentValue.isActive,
      [`forwarding-destination-${currentValue.id}`]: currentValue.destination
        ? // $FlowFixMe Ramda missing types
          R.pathOr(
            translate(`generic.forwardingStatuses.${currentValue.destination.type}`),
            ['destination', 'value'],
            currentValue
          )
        : ''
    }
  );
};

export const formPersonalCallForwardings = (
  forwardings: ?(CallForwardingT[]),
  translate: TranslateT<string>
) => {
  return forwardings
    ? {
        ...forwardings.reduce((accumulator, currentValue) => {
          return formPersonalCallForwarding(accumulator, currentValue, translate);
        }, {})
      }
    : {};
};

export const isAbsoluteTimeSlot = (selectedCalendarSlot: *) =>
  selectedCalendarSlot &&
  (!selectedCalendarSlot.startTime || selectedCalendarSlot.startTime.includes('T'));

export const parsePresenceDate = (dateTime: string) =>
  dateTime.indexOf('T') !== -1 ? moment(dateTime) : moment(dateTime, 'HH:mm:ss');

export const findWholeDayEvent = (schedule: *, currentDate: moment) => {
  const foundWholeDayEvent = schedule.find(
    s =>
      !s.endTime ||
      (!parsePresenceDate(s.startTime).isSame(parsePresenceDate(s.endTime), 'date') &&
        currentDate.isBetween(parsePresenceDate(s.startTime), parsePresenceDate(s.endTime)))
  );

  if (foundWholeDayEvent) {
    return [foundWholeDayEvent];
  }
  return schedule;
};

export const closePopovers = (currentPopover: *) => {
  const elements = document.querySelectorAll('#presenceTable button.ds-popover-close');
  elements.forEach(
    element =>
      (!currentPopover || currentPopover.current.childNodes[0].id !== element.id) && element.click()
  );
};

export const getAvailabilityInfo = (
  presenceState: ?PresenceStateSimpleT,
  telephonicState: ?TelephonicStateT,
  translate: TranslateT<>
): UserAvailabilityT => {
  if (presenceState) {
    if (presenceState === 'WORKING') {
      return {
        icon: 'yellow',
        text: translate('generic.availabilityStatusTexts.busy')
      };
    }
    if (presenceState === 'CUSTOMER_MEETING') {
      return {
        icon: 'yellow',
        text: translate('generic.availabilityStatusTexts.customer')
      };
    }
    if (presenceState === 'TRAINING') {
      return {
        icon: 'yellow',
        text: translate('generic.availabilityStatusTexts.training')
      };
    }
    if (presenceState === 'BUSINESS_TRIP') {
      return {
        icon: 'yellow',
        text: translate('generic.availabilityStatusTexts.travelling')
      };
    }
    if (presenceState === 'LUNCH') {
      return {
        icon: 'yellow',
        text: translate('generic.availabilityStatusTexts.lunch')
      };
    }
    if (presenceState === 'ALL_MEETING') {
      return {
        icon: 'yellow',
        text: translate('generic.availabilityStatusTexts.meeting')
      };
    }
    if (presenceState === 'AWAY') {
      return {
        icon: 'red',
        text: translate('generic.availabilityStatusTexts.away')
      };
    }
    if (presenceState === 'SICK') {
      return {
        icon: 'red',
        text: translate('generic.availabilityStatusTexts.sick')
      };
    }
    if (presenceState === 'HOLIDAYS') {
      return {
        icon: 'red',
        text: translate('generic.availabilityStatusTexts.holidays')
      };
    }
  }
  return {
    icon: 'green',
    text: translate('generic.availabilityStatusTexts.available')
  };
};

export const isProd = (): boolean => process.env.REACT_APP_ENV === 'production';

export const isIntProd = (): boolean =>
  process.env.REACT_APP_ENV === 'devOrTest' && window.location.href.includes('int-prod.');

export const isTest = (): boolean =>
  process.env.REACT_APP_ENV === 'devOrTest' && window.location.href.includes('-test.');

export const isDev = (): boolean =>
  process.env.REACT_APP_ENV === 'devOrTest' && window.location.href.includes('-dev.');

export const isLocalhost = (): boolean => {
  const { href } = window.location;
  return href.startsWith('https://localhost') || href.startsWith('https://127.0.0.1');
};

export const getAvailability = (
  user: DirectoryEntityT,
  translate: TranslateT<>
): UserAvailabilityT => {
  const presenceState =
    R.path(['presenceState'], user) || R.path(['publicInfo', 'presenceState'], user);
  const telephonicState =
    R.path(['telephonicState'], user) || R.path(['publicInfo', 'telephonicState'], user);
  return getAvailabilityInfo(presenceState, telephonicState, translate);
};

export const isMobileNumber = (number: string): boolean =>
  number != null &&
  (number.startsWith('+3584') ||
    number.startsWith('+35850') ||
    number.startsWith('04') ||
    number.startsWith('050'));

export const enterpriseCalendarRegExp = /^~[0-9]*~/g;
