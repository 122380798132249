// @flow
import React, { type Element, useEffect, useState } from 'react';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AudioFileSelection from './AudioFileSelection';
import type {
  VoiceMailAudioTypesT,
  VoiceMailEntityT
} from '../../../../../../ducks/entities/voicemailTypes';
import TemporaryGreetingDateSelector from '../TemporaryGreetingDateSelector/TemporaryGreetingDateSelector';
import styles from './VoiceMailAudioField.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  description?: string,
  voicemailSettings: VoiceMailEntityT,
  enterpriseId: string,
  entityId: string,
  serviceType: VoiceMailAudioTypesT,
  temporaryVoicemailSettings: *
|};

export type VoiceMailAudioFieldT = {
  level: 'PhoneNumber' | 'AudioName' | 'Greeting' | 'TemporaryGreeting',
  audioNameFileToImport: *,
  greetingAudioToImport: *,
  temporaryGreetingAudioToImport: *,
  startTimeInMillis?: string,
  endTimeInMillis?: string,
  isTemporaryVoicemailActive?: boolean,
  removeTemporaryGreeting?: boolean
};

export const VoiceMailAudioField = (props: PropsT): Element<'div'> => {
  const {
    field,
    title,
    description,
    enterpriseId,
    entityId,
    voicemailSettings,
    serviceType,
    temporaryVoicemailSettings
  } = props;

  // form
  const { setValue, watch } = useFormContext();
  const values = watch();
  const audioField: VoiceMailAudioFieldT = values[field];

  const { t } = useTranslation();

  const hasAudioNameFile = !!R.path(
    ['audios', 'audioName', 'files', 'durationInSeconds'],
    voicemailSettings
  );
  const hasGreetingFile = !!R.path(
    ['audios', 'greeting', 'files', 'durationInSeconds'],
    voicemailSettings
  );

  const endTimeInMillis = temporaryVoicemailSettings
    ? temporaryVoicemailSettings.endTimeInMillis
    : audioField.endTimeInMillis
    ? audioField.endTimeInMillis
    : moment()
        .add(1, 'days')
        .valueOf()
        .toString();

  const [temporaryGreetingStartTime, setTemporaryGreetingStartTime] = useState(
    temporaryVoicemailSettings
      ? temporaryVoicemailSettings.startTimeInMillis
      : moment()
          .valueOf()
          .toString()
  );

  const [temporaryGreetingEndTime, setTemporaryGreetingEndTime] = useState(endTimeInMillis);
  const [isTemporaryVoicemailActive, setIsTemporaryVoicemailActive] = useState();

  useEffect(() => {
    if (temporaryVoicemailSettings) {
      setIsTemporaryVoicemailActive(!!temporaryVoicemailSettings);
      setTemporaryGreetingStartTime(temporaryVoicemailSettings.startTimeInMillis);
      setTemporaryGreetingEndTime(temporaryVoicemailSettings.endTimeInMillis);
    }
    setValue(
      field,
      {
        ...values[field],
        startTimeInMillis: temporaryGreetingStartTime,
        endTimeInMillis: temporaryGreetingEndTime,
        isTemporaryVoicemailActive: !!temporaryVoicemailSettings
      },
      {
        shouldValidate: false,
        shouldDirty: false
      }
    );
    return () => {};
  }, [temporaryVoicemailSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const audioNameAudio = {
    enterpriseId,
    callflowType: 'acds',
    callflowId: entityId,
    audioType: 'voicemail',
    propertyName: 'AudioName',
    serviceType
  };

  const greetingAudio = {
    enterpriseId,
    callflowType: 'acds',
    callflowId: entityId,
    audioType: 'voicemail',
    propertyName: 'Greeting',
    serviceType
  };

  const temporaryGreetingAudio = {
    enterpriseId,
    callflowType: 'acds',
    callflowId: entityId,
    audioType: 'voicemail',
    propertyName: 'TemporaryGreeting',
    serviceType
  };

  const getHelpText = () => {
    if (audioField.level === 'AudioName') {
      return t('voicemailSettings.audioDefaultTooltip');
    }
    if (audioField.level === 'Greeting') {
      return t('voicemailSettings.audioAudioTooltip');
    }
    if (audioField.level === 'TemporaryGreeting') {
      return t('voicemailSettings.temporaryGreetingTooltip', {
        lastSelection:
          voicemailSettings.audios.current.name &&
          voicemailSettings.audios.current.name !== 'TemporaryGreeting'
            ? t(`voicemailSettings.${voicemailSettings.audios.current.name}`)
            : ''
      });
    }
    return '';
  };

  return (
    <div className={title && styles['title-padding']}>
      {title && <div className={classnames(styles['small-title'])}>{title}</div>}
      <div className={styles.description}>
        {description === undefined ? t('callflows.audioField.description') : description}
      </div>
      <div
        className={styles.container}
        role="group"
        aria-label="Message type"
        id="voicemail-type-selection"
      >
        <RadioGroup value={audioField.level} i18n_radiogroup_helpText={getHelpText()}>
          <Radio
            name="voicemailMessage"
            label={t('voicemailSettings.audioDefault')}
            value="PhoneNumber"
            onChange={() => {
              setValue(
                field,
                {
                  ...values[field],
                  level: 'PhoneNumber'
                },
                {
                  shouldValidate: false,
                  shouldDirty: true
                }
              );
              setIsTemporaryVoicemailActive(false);
            }}
          />
          <Radio
            name="voicemailMessage"
            value="AudioName"
            onChange={() => {
              setValue(
                field,
                {
                  ...values[field],
                  level: 'AudioName'
                },
                {
                  shouldValidate: false,
                  shouldDirty: true
                }
              );
              setIsTemporaryVoicemailActive(false);
            }}
            label={t('voicemailSettings.audioAudioName')}
          />
          <Radio
            name="voicemailMessage"
            value="Greeting"
            onChange={() => {
              setValue(
                field,
                {
                  ...values[field],
                  level: 'Greeting'
                },
                {
                  shouldValidate: false,
                  shouldDirty: true
                }
              );
              setIsTemporaryVoicemailActive(false);
            }}
            label={t('voicemailSettings.audioGreeting')}
          />
          <Radio
            name="voicemailMessage"
            value="TemporaryGreeting"
            onChange={() =>
              setValue(
                field,
                {
                  ...values[field],
                  level: 'TemporaryGreeting'
                },
                {
                  shouldValidate: false,
                  shouldDirty: true
                }
              )
            }
            label={t('voicemailSettings.temporaryGreeting')}
          />
        </RadioGroup>
        {audioField.level === 'AudioName' && (
          <div className={styles.audio} id="audio-name-audio-file-selection">
            <AudioFileSelection
              audio={audioNameAudio}
              onChange={file =>
                setValue(
                  field,
                  {
                    ...values[field],
                    audioNameFileToImport: file
                  },
                  {
                    shouldValidate: false,
                    shouldDirty: true
                  }
                )
              }
              showInitialView={hasAudioNameFile}
              disabled={false}
              title={t('voicemailSettings.audioAudioName')}
            />
          </div>
        )}
        {audioField.level === 'Greeting' && (
          <div className={styles.audio} id="greeting-audio-file-selection">
            <AudioFileSelection
              className={styles.audio}
              audio={greetingAudio}
              onChange={file =>
                setValue(
                  field,
                  {
                    ...values[field],
                    greetingAudioToImport: file
                  },
                  {
                    shouldValidate: false,
                    shouldDirty: true
                  }
                )
              }
              showInitialView={hasGreetingFile}
              disabled={false}
              title={t('voicemailSettings.audioGreeting')}
            />
          </div>
        )}
        <TemporaryGreetingDateSelector
          updateRemoveTemporaryGreeting={value =>
            setValue(
              field,
              {
                ...values[field],
                removeTemporaryGreeting: value
              },
              {
                shouldValidate: false,
                shouldDirty: true
              }
            )
          }
          infoVisible={
            audioField.level !== 'TemporaryGreeting' &&
            !!isTemporaryVoicemailActive &&
            !values[field].removeTemporaryGreeting
          }
          visible={audioField.level === 'TemporaryGreeting'}
          temporaryGreetingAudio={temporaryGreetingAudio}
          voicemailSettings={voicemailSettings}
          updateAudioFileSelection={file =>
            setValue(
              field,
              {
                ...values[field],
                temporaryGreetingAudioToImport: file
              },
              {
                shouldValidate: false,
                shouldDirty: true
              }
            )
          }
          active={!!temporaryVoicemailSettings}
          updateIsTemporaryVoicemailActive={isManual => {
            setValue(
              field,
              {
                ...values[field],
                isTemporaryVoicemailActive: isManual
              },
              {
                shouldValidate: false,
                shouldDirty: true
              }
            );
            setIsTemporaryVoicemailActive(isManual);
          }}
          updateStartDate={dateTime => {
            setValue(
              field,
              {
                ...values[field],
                startTimeInMillis: dateTime,
                endTimeInMillis: temporaryGreetingEndTime
              },
              {
                shouldValidate: false,
                shouldDirty: true
              }
            );
            setTemporaryGreetingStartTime(dateTime);
          }}
          updateEndDate={dateTime => {
            setValue(
              field,
              {
                ...values[field],
                startTimeInMillis: temporaryGreetingStartTime,
                endTimeInMillis: dateTime
              },
              {
                shouldValidate: false,
                shouldDirty: true
              }
            );
            setTemporaryGreetingEndTime(dateTime);
          }}
          updateAudioLevel={level =>
            setValue(
              field,
              {
                ...values[field],
                level
              },
              {
                shouldValidate: false,
                shouldDirty: true
              }
            )
          }
          startTimeInMillis={parseInt(temporaryGreetingStartTime, 10)}
          endTimeInMillis={parseInt(temporaryGreetingEndTime, 10)}
        />
      </div>
    </div>
  );
};

export default VoiceMailAudioField;
