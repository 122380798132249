// @flow
import { CancelToken } from 'axios';
import React, { type Element, type ElementRef, useState } from 'react';
import Button from '@design-system/component-library/src/components/Button';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import type { Canceler } from 'axios';
import { useParams } from 'react-router-dom';
import IconUploadRegular from '@design-system/component-library/src/components/Icon/lib/IconUploadRegular';
import IconDownloadRegular from '@design-system/component-library/src/components/Icon/lib/IconDownloadRegular';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import ModalPopOver from '../../../components/ModalPopOver';
import type { FieldToShowT, ShowT } from '../../../ducks/ui/user/userUiTypes';
import { createUpdateUserColumnsToShow } from '../../../ducks/ui/user/userUiActions';
import { operations as userOps } from '../../../ducks/entities/user/index';
import Dismiss from '../../../components/Button/Dismiss';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';
import { pushAnalyticsEvent } from '../../../utils/analyticsHandler';
import { EXPORT_CONTACTS } from '../../../matomo_constants';
import { operations } from '../../../ducks/config';
import { serializeColumnsToShowConfigValue } from '../../../userConfigHelpers';
import styles from './ColumnSelector.module.scss';

type PropsT = {|
  onClose: () => void,
  showColumnSelector: boolean,
  anchorElementRef: ElementRef<*>,
  startImportUsers: () => void
|};

const MIN_COLUMNS = 2; // includes avatar
const MAX_COLUMNS = 11; // includes avatar
export const USER_TABLE_SETTINGS_CONFIG = 'user_table_settings_v1';

export const ColumnSelector = (props: PropsT): Element<typeof ModalPopOver> => {
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const userConfig = useSelector(state => state.config.userConfig);
  const { onClose, showColumnSelector, anchorElementRef, startImportUsers } = props;
  const columnsToShow = useSelector(state => state.ui.user.userColumnsToShow);
  const { id: enterpriseId } = useParams();
  const cancelRequest = React.useRef<Canceler>();
  const [userColumnsToShow, setUserColumnsToShow] = useState(columnsToShow);

  const calculatePopOverPosition = () => {
    const columnSelectorRect = anchorElementRef
      ? anchorElementRef.current.getBoundingClientRect()
      : null;
    if (columnSelectorRect != null) {
      return {
        x: columnSelectorRect.right - 15,
        y: columnSelectorRect.bottom + 22
      };
    }
    return {
      x: 0,
      y: 0
    };
  };
  const [popOverArrowLocation] = useState(calculatePopOverPosition());

  // eslint-disable-next-line
  const selectionCount = (columnsToShow) => (Object.values(columnsToShow): any).reduce(
      (acc: number, curr: ShowT) => acc + (curr.show ? 1 : 0),
      0
    );

  const handleCheckboxChange = (fieldName: string) => {
    const updatedColumnsToShow: FieldToShowT = {
      ...userColumnsToShow
    };
    if (userColumnsToShow[fieldName]) {
      updatedColumnsToShow[fieldName] = {
        show: !userColumnsToShow[fieldName].show
      };
    } else {
      updatedColumnsToShow[fieldName] = {
        show: true
      };
    }

    dispatch(
      operations.updateUserConfig(
        [
          ...R.reject(item => 'key' in item && item.key === USER_TABLE_SETTINGS_CONFIG, userConfig),
          {
            key: USER_TABLE_SETTINGS_CONFIG,
            value: serializeColumnsToShowConfigValue(updatedColumnsToShow || {})
          }
        ],
        {
          'X-CSRF-TOKEN': currentUser.csrf
        }
      )
    );
    dispatch(createUpdateUserColumnsToShow(updatedColumnsToShow));
    setUserColumnsToShow(updatedColumnsToShow);
  };

  const handleExport = (userType: string) => {
    if (enterpriseId) {
      pushAnalyticsEvent(`${EXPORT_CONTACTS}${userType.toUpperCase()}`);
      dispatch(
        userOps.exportUsers(
          enterpriseId,
          new CancelToken(canceler => {
            cancelRequest.current = canceler;
          }),
          { userType },
          createCsrfHeader(currentUser)
        )
      );
    }
  };

  const checkBoxForColumn = (fieldName: string): * => {
    return (
      <Checkbox
        id={`show-${fieldName}-checkbox`}
        checked={userColumnsToShow[fieldName] && userColumnsToShow[fieldName].show}
        disabled={
          (selectionCount(userColumnsToShow) >= MAX_COLUMNS &&
            !userColumnsToShow[fieldName]?.show) ||
          (selectionCount(userColumnsToShow) <= MIN_COLUMNS && userColumnsToShow[fieldName]?.show)
        }
        onChange={() => handleCheckboxChange(fieldName)}
        label={t(`phonebook.fieldLabels.${fieldName}`)}
      />
    );
  };

  const tableColumns = (
    <div className={styles['column-selector-container']}>
      {checkBoxForColumn('availability')}
      {checkBoxForColumn('firstName')}
      {checkBoxForColumn('lastName')}
      {checkBoxForColumn('title')}
      {checkBoxForColumn('phoneNumbers')}
      {checkBoxForColumn('addressNumber')}
      {checkBoxForColumn('emails')}
      {checkBoxForColumn('additionalInfo')}
      {checkBoxForColumn('additionalExplanations')}
      {checkBoxForColumn('nickName')}
      {checkBoxForColumn('contactInformation')}
      {checkBoxForColumn('corporateUserId')}
      {checkBoxForColumn('costCenter')}
      {checkBoxForColumn('officeReferenceID')}
      {checkBoxForColumn('postalAddress')}
      {checkBoxForColumn('accessCtrlSystemPersonId')}
      {checkBoxForColumn('department')}
    </div>
  );
  const fileActions = (
    <div className={styles['file-action-container']}>
      <span className={styles['file-header']}>{t('columnSelector.fileActionHeader')}</span>
      <Button
        id="import-button"
        type="button"
        onClick={startImportUsers}
        className={styles['import-export-button']}
        color="link"
      >
        <span>
          <IconUploadRegular color="brand-blue" />
          <span className={styles['button-icon']}>{t('users.import')}</span>
        </span>
      </Button>
      <Button
        id="export-button"
        type="button"
        onClick={() => handleExport('internalUser')}
        className={styles['import-export-button']}
        color="link"
      >
        <span>
          <IconDownloadRegular color="brand-blue" />
          <span className={styles['button-icon']}>{t('users.exportInternal')}</span>
        </span>
      </Button>
      <Button
        id="export-button"
        type="button"
        onClick={() => handleExport('externalUser')}
        className={styles['import-export-button']}
        color="link"
      >
        <span>
          <IconDownloadRegular color="brand-blue" />
          <span className={styles['button-icon']}>{t('users.exportExternal')}</span>
        </span>
      </Button>
    </div>
  );
  return (
    <ModalPopOver
      isOpen={showColumnSelector}
      location={popOverArrowLocation}
      locationType="arrow"
      contentLabel={t('columnSelector.header')}
      arrowOffsetLeft={260}
      popOverWidth={320}
      onClose={onClose}
      withArrow={false}
    >
      <div className={styles['column-selector']}>
        <div className={styles.header}>
          <span>{t('columnSelector.header')}</span>
          <Dismiss
            id="column-selector-button-close"
            onClose={onClose}
            tabIndex="0"
            onKeyPress={onClose}
            dismissStyle={styles['close-button']}
          />
        </div>
        <div className={styles.description}>
          {t('columnSelector.description', { max: MAX_COLUMNS - 1 })}
        </div>
        {tableColumns}
        {fileActions}
      </div>
    </ModalPopOver>
  );
};

export default ColumnSelector;
