// @flow strict-local

import React, { type Element } from 'react';
import classnames from 'classnames';
import { escapeRegExp } from '../../../utils/textUtils';
import styles from './HighlightText.module.scss';

type PropsT = {|
  text: string,
  highlight: string,
  highlightStyle?: string
|};

export const HighlightText = (props: PropsT): Element<'span'> => {
  const { text, highlight, highlightStyle } = props;
  if (typeof text !== 'string') return <span />;
  const parsedSearchTerm = (highlight || '').replace('*', ' ').toLowerCase();
  const keywords = escapeRegExp(parsedSearchTerm).split(' ');
  const rawKeywords = parsedSearchTerm.split(' ');
  // Split on highlight term and include term into parts, ignore case
  const parts = (text || '')
    .replace(/^kutsu:/, '')
    .split(new RegExp(`(${keywords.join('|')})`, 'gi'));

  // No match (department-case)
  if (text && parts.length === 1 && text.length > 3 && text.startsWith('...')) {
    const endpart = text.substring(3);
    return (
      <span>
        <span className={classnames(styles.highlight, highlightStyle)}>...</span>
        <span>{endpart}</span>
      </span>
    );
  }

  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i /* eslint-disable-line react/no-array-index-key */}
          className={
            rawKeywords.includes(part.toLowerCase())
              ? classnames(styles.highlight, highlightStyle)
              : ''
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export default HighlightText;
