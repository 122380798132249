// @flow strict-local
import { useLocation } from 'react-router-dom';
import React, { useState, type Element } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import queryString from 'querystring';
import { useTranslation } from 'react-i18next';
import RadioButton from '../callFlows/components/edit/children/RadioButton';
import type { CurrentUserT } from '../../ducks/currentUser/currentUserTypes';
import useFormAutoRegister from '../callFlows/callFlowGrid/details/useFormAutoRegister';
import { isIntProd, isProd, isTest } from '../../helpers';
import LoginBase from './LoginBase';
import ActionButton from '../../components/Button/ActionButton';
import CenterHorizontally from '../../components/CenterHorizontally/CenterHorizontally';
import { fetchCsrf, login } from '../../ducks/currentUser/currentUserOperations';
import { operations } from '../../ducks/config';
import { createOpenOnboardingAction } from '../../ducks/ui/header/headerUiActions';
import { selectors as currentUserSelectors } from '../../ducks/currentUser';
import { goTo, goToLandingPage } from '../../navigationOperations';
import { getLoginConfigs } from './LoginUtil';

export type PropsT = {||};

type FormT = {
  platformSelector: string
};

// eslint-disable-next-line no-unused-vars
export const LoginWithElisaAD = (props: PropsT): Element<typeof LoginBase> => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);

  const username = location.state ? location.state.username : '';
  const password = location.state ? location.state.password : '';
  const { languages } = i18n;
  const hasSession = useSelector(state => currentUserSelectors.hasSession(state));
  const { redirect } = queryString.parse(window.location.search.slice(1));

  const forwardUserIfLoggedIn = () => {
    if (hasSession) {
      if (redirect) {
        goTo(redirect);
      }
    }
    dispatch(goToLandingPage());
  };

  const handleSubmit = async (formValues): Promise<void> => {
    setLoading(true);
    const currentUser: CurrentUserT = await dispatch(
      login(formValues.platformSelector + username, password)
    );
    if (currentUser.status === 'otp_authentication') {
      const destination = {
        pathname: '/otp-authentication',
        state: {
          username,
          password,
          platform: formValues.platformSelector,
          token: currentUser.token
        }
      };
      dispatch(goTo(destination));
      return;
    }

    const csrfToken = await dispatch(fetchCsrf());
    if (languages.map(language => language.code).includes(currentUser.appLanguage)) {
      i18n.changeLanguage(currentUser.appLanguage || 'fi');
    }
    const userConfig = await dispatch(operations.getUserConfig());
    const bulletins = await dispatch(operations.getBulletins());
    const { modifiedUserConfig, shouldOpenOnboarding } = getLoginConfigs(
      `${currentUser.environment}${currentUser.country || ''}`,
      currentUser.enterprises?.map(enterprise => enterprise.ownerAdmtiveDomainId || '') || [],
      userConfig,
      bulletins
    );
    if (modifiedUserConfig) {
      await dispatch(
        operations.updateUserConfig(modifiedUserConfig, {
          'X-CSRF-TOKEN': csrfToken
        })
      );
    }
    if (shouldOpenOnboarding) {
      dispatch(createOpenOnboardingAction());
    }
    forwardUserIfLoggedIn();
  };

  const prodPlatformOptions = ['mob1', 'mob2', 'mob3'].map(platform => ({
    label: t(`login.platformSelector.${platform}`),
    value: `${platform}:`
  }));

  const devPlatformOptions = ['lab1', 'lab2', 'lab3'].map(platform => ({
    label: t(`login.platformSelector.${platform}`),
    value: `${platform}:`
  }));

  const platformSelectorOptions =
    isProd() || isIntProd() || isTest() ? prodPlatformOptions : devPlatformOptions;

  const initialFormValues: FormT = {
    // $FlowFixMe value always exists
    platformSelector: platformSelectorOptions[0].value
  };
  const methods = useFormAutoRegister(R.keys(initialFormValues), {
    defaultValues: initialFormValues
  });

  return (
    <LoginBase>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <RadioButton
            field="platformSelector"
            title={t('login.platformSelector.title')}
            options={platformSelectorOptions}
          />
          <CenterHorizontally>
            <ActionButton
              id="continue-button"
              label={t('login.platformSelector.continue')}
              type="submit"
              loading={loading}
            />
          </CenterHorizontally>
        </form>
      </FormProvider>
    </LoginBase>
  );
};

export default LoginWithElisaAD;
