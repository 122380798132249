// @flow

import React, { useEffect, useState } from 'react';
import axios, { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import fileSaver from 'file-saver';
import Button from '@design-system/component-library/src/components/Button';
import type { Canceler } from 'axios';
import HTTP from 'http-status-codes';
import { useDispatch, useSelector } from 'react-redux';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import type { CurrentUserT } from '../../ducks/currentUser/currentUserTypes';
import configOperations from '../../ducks/config/configOperations';
import styles from './AroundTheClockEnterpriseExport.module.scss';

export const AroundTheClockEnterpriseExport = () => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState();
  const cancelRequest = React.useRef<Canceler>();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const [uuid, setUuid] = useState();
  const PROCESS_CHECK_DELAY = 2000;
  const dispatch = useDispatch();

  const exportToExcel = async (exportType: string) => {
    setProcessing(true);
    try {
      const res = await dispatch(configOperations.getAllEnterpriseSettings());
      if (res) {
        const environments = [
          ...new Set(res.filter(r => r.aroundTheClockEnterprise).map(r => r.environment))
        ];
        const enterpriseIds = res.filter(r => r.aroundTheClockEnterprise).map(r => r.enterpriseId);
        const response = await axios({
          method: 'POST',
          url: `/api/v1/247ent/export`,
          data: { environments, enterpriseIds, exportType },
          headers: createCsrfHeader(currentUser),
          cancelToken: new CancelToken(canceler => {
            cancelRequest.current = canceler;
          })
        });
        if (response && response.status === HTTP.OK) {
          setUuid(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setProcessing(false);
    }
  };

  useEffect(() => {
    let id;
    if (uuid) {
      id = setInterval(async () => {
        try {
          const response = await axios({
            method: 'GET',
            url: `/api/v1/247ent/export/process?uuid=${uuid}`,
            cancelToken: new CancelToken(canceler => {
              cancelRequest.current = canceler;
            })
          });
          if (response) {
            if (response.data) {
              clearInterval(id);
              const res = await axios({
                method: 'POST',
                url: `/api/v1/247ent/export/done?uuid=${uuid}`,
                headers: createCsrfHeader(currentUser),
                responseType: 'blob',
                cancelToken: new CancelToken(canceler => {
                  cancelRequest.current = canceler;
                })
              });
              const blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              });
              const contentDisposition = res.headers['content-disposition'];
              let filename = 'exported_data.xlsx';
              if (contentDisposition) {
                const [, matchedFilename] = contentDisposition.match(/filename="(.+)"/) || [];
                if (matchedFilename) {
                  filename = matchedFilename;
                }
              }
              fileSaver.saveAs(blob, filename);
              setProcessing(false);
            }
          }
        } catch (e) {
          console.error(e);
        }
      }, PROCESS_CHECK_DELAY);
    }
    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className={styles['button-container']}>
        <Button loading={processing} onClick={() => exportToExcel('enterprises')}>
          {t('adminui.aroundTheClockEnterprisesExport.exportEnterprises')}
        </Button>
      </div>
      <div className={styles['button-container']}>
        <Button loading={processing} onClick={() => exportToExcel('adminsAndUsers')}>
          {t('adminui.aroundTheClockEnterprisesExport.exportUsers')}
        </Button>
      </div>
    </div>
  );
};

export default AroundTheClockEnterpriseExport;
