// @flow
import React, { type Element, useState } from 'react';
import Input from '@design-system/component-library/src/components/Input';
import IconErrorRegular from '@design-system/component-library/src/components/Icon/lib/IconErrorRegular';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../../components/Tooltip';
import styles from './IntegerField.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  inputDescription?: string,
  description?: string,
  shouldValidate?: boolean,
  tooltip?: string,
  className?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  disabledFn?: any => boolean,
  errorMessage?: string
|};

export const DurationField = (props: PropsT): Element<'div'> => {
  const {
    field,
    title,
    description,
    inputDescription,
    shouldValidate,
    tooltip,
    className,
    disabledFn,
    errorMessage
  } = props;

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();

  const getSeconds = (duration: number): number => {
    return duration % 60;
  };

  const getMinutes = (duration: number): number => {
    return Math.floor(duration / 60);
  };

  const values = watch();
  const { t } = useTranslation();
  const [minutes, _setMinutes] = useState(getMinutes(values[field]));
  const [seconds, _setSeconds] = useState(getSeconds(values[field]));

  const handleValueChange = (m: number, s: number) => {
    const totalSeconds = m * 60 + s;
    setValue(field, totalSeconds, {
      shouldValidate: shouldValidate === undefined ? true : shouldValidate,
      shouldDirty: true
    });
  };

  const setSeconds = (newVal: number): void => {
    _setSeconds(newVal);
    handleValueChange(minutes, newVal);
  };

  const setMinutes = (newVal: number): void => {
    _setMinutes(newVal);
    handleValueChange(newVal, seconds);
  };

  const disabledValue = disabledFn === undefined ? false : disabledFn(values);

  return (
    <div className={classnames(styles['field-group-section'], className)}>
      <div className={styles['field-section']}>
        <div className={styles.title}>
          {title}
          {tooltip && <Tooltip containerStyle={styles.tooltip}>{tooltip}</Tooltip>}
        </div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <div className={styles['field-section']}>
        <div className={styles['input-description']}>{inputDescription}</div>
        <div data-cy={field} className={styles['duration-fields']}>
          <Input
            name={`${field}Minutes`}
            data-cy="input"
            type="number"
            className={styles['integer-input']}
            onValueChange={e => {
              setMinutes(Number(e.target.value));
            }}
            defaultValue={`${minutes}`}
            min={0}
            touched
            i18n_input_errorMessage={errors[field] ? ' ' : ''}
            disabled={disabledValue}
            i18n_input_helpText={t('callflows.duration.minutes')}
          />
          <span className={styles['duration-divider']}>:</span>
          <Input
            name={`${field}Seconds`}
            data-cy="input"
            className={styles['integer-input']}
            onValueChange={e => {
              setSeconds(Number(e.target.value));
            }}
            defaultValue={`${seconds}`}
            type="number"
            maxlength={2}
            min={0}
            // max={60} enable if product or design people think its not cool to input 900 seconds
            touched
            i18n_input_errorMessage={errors[field] ? ' ' : ''}
            disabled={disabledValue}
            i18n_input_helpText={t('callflows.duration.seconds')}
          />
        </div>
        {errors[field] && errorMessage && (
          <div className="ds-inputerror">
            <IconErrorRegular size="s" />
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default DurationField;
