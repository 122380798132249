// @flow strict-local

import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import * as yup from 'yup';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VoiceMailIcon } from '../../../../../assets/callflow/details/voicemail_open.svg';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import VoiceMailAudioField from '../../../components/edit/children/audio/VoiceMailAudioField';
import type { VoiceMailAudioFieldT } from '../../../components/edit/children/audio/VoiceMailAudioField';
import { uploadVoiceMailAudio } from '../../../../../ducks/entities/callFlow/callFlowOperations';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import { updateExtensionGroup } from '../../../../../ducks/entities/extensionGroup/extensionGroupOperations';
import ToggleField from '../../../components/edit/children/ToggleField';
import { PasswordField } from '../../../components/edit/children/PasswordField';
import fieldValidators from '../../../../../fieldValidators';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';
import {
  createTemporaryVoicemailSetting,
  deleteTemporaryVoicemailSetting,
  retrieveTemporaryVoicemailSettings,
  updateTemporaryVoicemailSetting
} from '../../../../../helpers';

export type PropsT = {|
  callflowId: string
|};

type FormT = {
  isVoiceMailMessage: boolean,
  voiceMailAudio: VoiceMailAudioFieldT,
  groupPassword: ?string
};

let requestCancelTokenSource: CancelTokenSource;
let audioRequestCancelTokenSource: CancelTokenSource;

export const EditVoiceMail = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const extGroupData: ExtensionGroupEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const { voicemailSettings } = extGroupData;
  const [passwordFieldEnabled, setPasswordFieldEnabled] = useState(false);
  const [temporaryVoicemailSettings, setTemporaryVoicemailSettings] = useState();
  const currentlyActiveFile =
    R.path(['audios', 'current', 'name'], voicemailSettings) || 'PhoneNumber';

  const initialFormValues: FormT = {
    isVoiceMailMessage: voicemailSettings && !voicemailSettings.isVoiceMailNoMessage,
    voiceMailAudio: {
      level: currentlyActiveFile,
      audioNameFileToImport: undefined,
      greetingAudioToImport: undefined,
      temporaryGreetingAudioToImport: undefined,
      endTimeInMillis: voicemailSettings.audios.current.endTimeInMillis,
      isTemporaryVoicemailActive: false
    },
    groupPassword: null
  };

  const groupVoiceMailSchema = yup.object().shape({
    groupPassword: yup
      .string()
      .test({
        name: 'value',
        message: t('callflows.validation.passwordError'),
        test: value =>
          value ? fieldValidators.userPasswordComplexityValidator(value) === undefined : true
      })
      .nullable()
  });

  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    audioRequestCancelTokenSource = CancelToken.source();
    retrieveTemporaryVoicemailSettings(
      extGroupData.enterpriseId,
      currentUser.environment,
      extGroupData.id,
      'ExtensionGroup',
      setTemporaryVoicemailSettings
    );
    return () => {
      requestCancelTokenSource.cancel();
      audioRequestCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line

  // update
  const onSubmit = async (formData: FormT): Promise<ExtensionGroupEntityT> => {
    const patchPayload = {
      voicemailSettings: {
        isVoiceMailNoMessage: !formData.isVoiceMailMessage,
        audios: {
          current: {
            name: formData.voiceMailAudio.isTemporaryVoicemailActive
              ? undefined
              : formData.voiceMailAudio.level,
            endTimeInMillis:
              formData.voiceMailAudio.level === 'TemporaryGreeting'
                ? formData.voiceMailAudio.endTimeInMillis
                : undefined
          }
        }
      },
      ...(passwordFieldEnabled
        ? {
            groupPassword: formData.groupPassword
          }
        : {})
    };

    const uploadAudio = async (audioLevel, file) => {
      await uploadVoiceMailAudio(
        extGroupData.enterpriseId,
        extGroupData.id,
        audioLevel,
        'ExtensionGroup',
        file,
        audioRequestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      );
    };

    if (formData.voiceMailAudio.audioNameFileToImport) {
      await uploadAudio('AudioName', formData.voiceMailAudio.audioNameFileToImport);
    }
    if (formData.voiceMailAudio.greetingAudioToImport) {
      await uploadAudio('Greeting', formData.voiceMailAudio.greetingAudioToImport);
    }
    if (formData.voiceMailAudio.temporaryGreetingAudioToImport) {
      await uploadAudio(
        'TemporaryGreeting',
        formData.voiceMailAudio.temporaryGreetingAudioToImport
      );
    }
    if (temporaryVoicemailSettings && formData.voiceMailAudio.removeTemporaryGreeting) {
      deleteTemporaryVoicemailSetting(
        temporaryVoicemailSettings.id,
        extGroupData.enterpriseId,
        currentUser,
        setTemporaryVoicemailSettings
      );
    } else if (formData.voiceMailAudio.isTemporaryVoicemailActive) {
      if (temporaryVoicemailSettings && temporaryVoicemailSettings.id) {
        updateTemporaryVoicemailSetting(
          formData,
          temporaryVoicemailSettings.id,
          extGroupData.enterpriseId,
          currentUser,
          extGroupData.id,
          'ExtensionGroup',
          setTemporaryVoicemailSettings
        );
      } else {
        createTemporaryVoicemailSetting(
          formData,
          currentUser,
          extGroupData.enterpriseId,
          extGroupData.id,
          'ExtensionGroup',
          setTemporaryVoicemailSettings
        );
      }
    }
    return dispatch(
      updateExtensionGroup(
        extGroupData.enterpriseId,
        extGroupData.id,
        patchPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={extGroupData.id}
      icon={<VoiceMailIcon />}
      title={t('callflows.editVoiceMail.title')}
      description={t('callflows.editVoiceMail.description')}
      defaultValues={initialFormValues}
      validationSchema={groupVoiceMailSchema}
      onSaveForm={onSubmit}
    >
      <ToggleField
        field="isVoiceMailMessage"
        elementId="isVoiceMailMessage"
        label={t('voicemailSettings.isVoiceMailMessage')}
      />
      <VoiceMailAudioField
        key={temporaryVoicemailSettings}
        field="voiceMailAudio"
        enterpriseId={extGroupData.enterpriseId}
        entityId={extGroupData.id}
        voicemailSettings={voicemailSettings}
        title={t('voicemailSettings.title')}
        description={t('voicemailSettings.description')}
        serviceType="ExtensionGroup"
        temporaryVoicemailSettings={temporaryVoicemailSettings}
      />
      {!(currentUser.featureFlags || []).includes('FEATURE-HIDE-GROUP-PASSWORD') ? (
        <PasswordField
          title={t('callflows.viewVoiceMailExtra.groupPassword')}
          field="groupPassword"
          shouldValidate
          fieldEnabled={passwordFieldEnabled}
          setFieldEnabled={() => setPasswordFieldEnabled(!passwordFieldEnabled)}
        />
      ) : null}
    </EditCallflowDetails>
  );
};

export default EditVoiceMail;
