// @flow
import React, { type Element, useState } from 'react';
import DatePicker from '@design-system/component-library/src/components/DatePicker';
import Disclaimer from '@design-system/component-library/src/components/Disclaimer/Disclaimer';
import TimePicker from 'react-time-picker';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as R from 'ramda';
import Toggle from '@design-system/component-library/src/components/Toggle';
import IconCalendarTimeRegular from '@design-system/component-library/src/components/Icon/lib/IconCalendarTimeRegular';
import {
  handleEndDateChange,
  handleEndTimeChange,
  handleStartDateChange,
  handleStartTimeChange
} from '../../../../../../utils/timeutils';
import AudioFileSelection from '../audio/AudioFileSelection';
import LinkButton from '../../../../../../components/Button/LinkButton';
import styles from './TemporaryGreetingDateSelector.module.scss';

export type PropsT = {|
  updateIsTemporaryVoicemailActive: boolean => void,
  active: boolean,
  updateStartDate: string => void,
  updateEndDate: string => void,
  startTimeInMillis: number,
  endTimeInMillis: number,
  voicemailSettings: *,
  temporaryGreetingAudio: *,
  updateAudioFileSelection: *,
  infoVisible: boolean,
  visible: boolean,
  updateAudioLevel: string => void,
  updateRemoveTemporaryGreeting: boolean => void
|};

export const TemporaryGreetingDateSelector = (props: PropsT): Element<'div'> | null => {
  const {
    updateStartDate,
    startTimeInMillis,
    updateEndDate,
    endTimeInMillis,
    voicemailSettings,
    temporaryGreetingAudio,
    updateAudioFileSelection,
    updateIsTemporaryVoicemailActive,
    active,
    infoVisible,
    visible,
    updateAudioLevel,
    updateRemoveTemporaryGreeting
  } = props;
  const { t } = useTranslation();

  const [endTimeError, setEndTimeError] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment(startTimeInMillis).format('DD.MM.YYYY')
  );
  const [selectedStartTime, setSelectedStartTime] = useState(
    moment(startTimeInMillis).format('HH:mm')
  );

  const [selectedEndDate, setSelectedEndDate] = useState(
    moment(endTimeInMillis).format('DD.MM.YYYY')
  );
  const [selectedEndTime, setSelectedEndTime] = useState(moment(endTimeInMillis).format('HH:mm'));
  const [isTemporaryVoicemailActive, setIsTemporaryVoicemailActive] = useState(active);

  const validateDateTimes = (startDate, startTime, endDate, endTime) => {
    const startDateTime = moment(`${startDate} ${startTime}`, 'DD.MM.YYYY HH:mm');
    const endDateTime = moment(`${endDate} ${endTime}`, 'DD.MM.YYYY HH:mm');
    if (endDateTime.isSameOrBefore(startDateTime)) {
      setEndTimeError(true);
    } else {
      setEndTimeError(false);
    }
  };

  const hasTemporaryGreetingFile = !!R.path(
    ['audios', 'temporaryGreeting', 'files', 'durationInSeconds'],
    voicemailSettings
  );

  return infoVisible ? (
    <div className={styles['info-container']}>
      <Disclaimer
        icon={<IconCalendarTimeRegular />}
        title={t('voicemailSettings.temporaryVoicemailInfoTitle')}
        text={`${moment(startTimeInMillis).format('DD.MM.YYYY HH:mm')}-${moment(
          endTimeInMillis
        ).format('DD.MM.YYYY HH:mm')}`}
        buttons={
          <div>
            <LinkButton
              id="temporaryVoicemailInfoLink"
              label={t('voicemailSettings.temporaryVoicemailInfoModifyLink')}
              onClickAction={e => {
                e.preventDefault();
                updateAudioLevel('TemporaryGreeting');
                setIsTemporaryVoicemailActive(true);
              }}
            />
            <LinkButton
              id="temporaryVoicemailInfoLink"
              label={t('voicemailSettings.temporaryVoicemailInfoRemoveLink')}
              onClickAction={e => {
                e.preventDefault();
                updateRemoveTemporaryGreeting(true);
              }}
            />
          </div>
        }
      />
    </div>
  ) : visible ? (
    <div>
      <Toggle
        id="temporaryvoicemail-active"
        value={isTemporaryVoicemailActive}
        onToggle={() => {
          updateIsTemporaryVoicemailActive(!isTemporaryVoicemailActive);
          setIsTemporaryVoicemailActive(!isTemporaryVoicemailActive);
        }}
        label={t('voicemailSettings.temporaryVoicemailActivate')}
        labelPosition="right"
      />
      {isTemporaryVoicemailActive && (
        <div className={styles['date-selector']}>
          <DatePicker
            className={styles['date-picker']}
            i18n_datepicker_start_label={t('generic.dateTimePicker.startDate')}
            onValueChange={date => {
              handleStartDateChange(date, selectedStartTime, setSelectedStartDate, updateStartDate);
              validateDateTimes(date, selectedStartTime, selectedEndDate, selectedEndTime);
            }}
            selectedDay={new Date(startTimeInMillis)}
            dateInputPattern={/\d{1,2}[.]\d{1,2}[.]\d{4}/}
            dateMaxLength={10}
            visibleRange={[
              moment()
                .subtract(1, 'day')
                .toDate(),
              moment()
                .add(1, 'year')
                .toDate()
            ]}
            i18n_datepicker_helpText={t('voicemailSettings.temporaryVoicemailStartDateHelpText')}
          />
          <TimePicker
            id="time-begin"
            data-cy="time-begin-input"
            name="time-begin"
            onChange={time => {
              handleStartTimeChange(time, selectedStartDate, setSelectedStartTime, updateStartDate);
              validateDateTimes(selectedStartDate, time, selectedEndDate, selectedEndTime);
            }}
            className={styles['time-input']}
            value={new Date(startTimeInMillis)}
            disableClock
            required
            locale="fi-FI"
            format="HH:mm"
          />
        </div>
      )}
      <div className={styles['date-selector']}>
        <DatePicker
          className={styles['date-picker']}
          i18n_datepicker_start_label={t('generic.dateTimePicker.endDate')}
          onValueChange={date => {
            handleEndDateChange(date, selectedEndTime, setSelectedEndDate, updateEndDate);
            validateDateTimes(selectedStartDate, selectedStartTime, date, selectedEndTime);
          }}
          selectedDay={new Date(endTimeInMillis)}
          dateInputPattern={/\d{1,2}[.]\d{1,2}[.]\d{4}/}
          dateMaxLength={10}
          visibleRange={[
            moment(startTimeInMillis)
              .subtract(1, 'day')
              .toDate(),
            moment()
              .add(1, 'year')
              .toDate()
          ]}
          i18n_datepicker_errorMessage={
            endTimeError ? t('generic.dateTimePicker.endDateError') : null
          }
        />
        <TimePicker
          id="time-end"
          data-cy="time-end-input"
          name="time-end"
          onChange={time => {
            handleEndTimeChange(time, selectedEndDate, setSelectedEndTime, updateEndDate);
            validateDateTimes(selectedStartDate, selectedStartTime, selectedEndDate, time);
          }}
          className={styles['time-input']}
          value={new Date(endTimeInMillis)}
          disableClock
          required
          locale="fi-FI"
          format="HH:mm"
        />
      </div>
      <div className={styles.audio} id="temporary-greeting-audio-file-selection">
        <AudioFileSelection
          className={styles.audio}
          audio={temporaryGreetingAudio}
          onChange={updateAudioFileSelection}
          showInitialView={hasTemporaryGreetingFile}
          disabled={false}
          title={t('voicemailSettings.temporaryGreeting')}
        />
      </div>
    </div>
  ) : null;
};

export default TemporaryGreetingDateSelector;
