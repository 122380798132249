// @flow strict-local

import * as R from 'ramda';
import React, { type Element, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QueueIcon } from '../../../../../assets/callflow/details/queue-small.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowAudioProperty from '../../../components/view/children/CallflowAudioProperty';
import { secondsToHumanReadableString } from '../../../../../utils/timeutils';
import CallflowItem from '../../../components/view/children/CallflowItem';
import PropertyList from '../../../components/view/children/PropertyList';
import { getDurationFieldLimits } from '../../../../../utils/validationUtils';
import { acdQueueSchema } from './EditAcdQueue';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import {
  DEFAULT_QUEUE_SIZE,
  getMaxGroupQueueSize,
  MAX_QUEUE_SIZE,
  MIN_QUEUE_SIZE
} from '../../../components/edit/CallflowPropertyUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';
import CallflowAudioPropertyList from '../../../components/view/children/CallflowAudioPropertyList';

export type PropsT = {|
  callflowId: string
|};

export const QueueDetails = (props: PropsT): Element<typeof CallflowDetails> | null => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const [acdQueueMaxSize, setAcdQueueMaxSize] = useState(MAX_QUEUE_SIZE);
  const mounted = useRef(false);
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const getAcdQueueMaxSize = async () => {
    const maxGroupQueueSize = await getMaxGroupQueueSize(acdData.enterpriseId);
    if (mounted.current) {
      setAcdQueueMaxSize(maxGroupQueueSize);
    }
  };

  useEffect(() => {
    mounted.current = true;
    getAcdQueueMaxSize();
    return () => {
      mounted.current = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!acdData) {
    return null;
  }

  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const ivrAcdCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};

  const acdAudioFieldConverter = new AudioFieldConverter(acdData.enterpriseId, acdData.id, 'acds');

  const waitingMusicMsgAudioField = acdAudioFieldConverter.convertToAudioPropertyField(
    'WaitingMusicMsg',
    R.path(['audios', 'waitingMusicMsg', 'extensionAudio', 'name'], acdCallCenter),
    R.path(['waitingMusicMsg'], ivrAcdCallCenter)
  );
  const waitingAnnounceMsgAudioField = acdAudioFieldConverter.convertToAudioPropertyField(
    'WaitingAnnounceMsg',
    R.path(['audios', 'waitingAnnounceMsg', 'extensionAudio', 'name'], acdCallCenter),
    R.path(['waitingAnnounceMsg'], ivrAcdCallCenter)
  );
  const waitingMusicPlaylistField = acdAudioFieldConverter.convertToAudioPropertyField(
    'WaitingMusicPlaylist',
    R.path(['waitingMusicPlaylist', 'value'], ivrAcdCallCenter)
  );
  const waitingAnnouncementPlaylistField = acdAudioFieldConverter.convertToAudioPropertyField(
    'WaitingAnnouncementPlaylist',
    R.path(['waitingAnnouncementPlaylist', 'value'], ivrAcdCallCenter)
  );

  return (
    <CallflowDetails
      enterpriseId={acdData.enterpriseId}
      callflowId={acdData.id}
      elementType={acdData.type}
      nodeId={`acd_queue_${acdData.id}`}
      nodeType="ACD_QUEUE"
      icon={<QueueIcon />}
      title={t('callflows.viewAcdQueue.title')}
      active
    >
      <CallflowDetailsColumn id="acd-queue-column-1">
        <CallflowItem
          id="queueSize"
          title={t('callflows.viewAcdQueue.queueSize')}
          value={`${acdData.queueSize}`}
          tooltip={t('callflows.viewAcdQueue.queueSizeTooltip', {
            min: MIN_QUEUE_SIZE,
            max: acdQueueMaxSize,
            default: DEFAULT_QUEUE_SIZE
          })}
        />
        <CallflowItem
          id="maxDurationInQueue"
          title={t('callflows.viewAcdQueue.maxDurationInQueue')}
          value={secondsToHumanReadableString(R.path(['maxDurationInQueue'], acdCallCenter))}
          tooltip={t(
            'callflows.viewAcdQueue.maxDurationInQueueTooltip',
            // $FlowFixMe: TODO: fix
            getDurationFieldLimits(acdQueueSchema.fields.maxDurationInQueue)
          )}
        />
      </CallflowDetailsColumn>
      <CallflowDetailsColumn id="acd-queue-column-2">
        {!((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') ? (
          <CallflowAudioProperty
            title={t('callflows.viewAcdQueue.waitingMusicMsg')}
            audioField={waitingMusicMsgAudioField}
            isActive
          />
        ) : null}
        <PropertyList
          title={t('callflows.viewAcdQueue.queueAutomaticAnnouncements')}
          options={[
            {
              label: t('callflows.viewAcdQueue.showQueueSize'),
              property: ivrAcdCallCenter.queueSizeNotification // TODO: use servicesSettings
            },
            {
              label: t('callflows.viewAcdQueue.showQueueTime'),
              property: ivrAcdCallCenter.queueTimeNotification // TODO: use servicesSettings
            }
          ]}
        />
        {!((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') ? (
          <CallflowAudioProperty
            title={t('callflows.viewAcdQueue.waitingAnnouncement')}
            audioField={waitingAnnounceMsgAudioField}
            postDescription={t('callflows.viewAcdQueue.periodicityTimer', {
              value: R.path(['periodicityTimer'], acdCallCenter)
            })}
            isActive
          />
        ) : (
          <div>
            <CallflowAudioPropertyList
              title={t('callflows.viewAcdQueue.waitingMusicPlaylistTitle')}
              audioField={waitingMusicPlaylistField}
              isActive
            />
            <CallflowAudioPropertyList
              title={t('callflows.viewAcdQueue.waitingAnnouncementPlaylistTitle')}
              audioField={waitingAnnouncementPlaylistField}
              isActive
            />
          </div>
        )}
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default QueueDetails;
