// @flow

import React, { type Element, useEffect, useState } from 'react';
import axios, { AxiosPromise } from 'axios';
import DatePicker, { registerLocale } from 'react-datepicker';
import fi from 'date-fns/locale/fi';
import sv from 'date-fns/locale/sv';
import et from 'date-fns/locale/et';
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { CurrentUserT } from '../../ducks/currentUser/currentUserTypes';

import styles from './CustomDatePicker.module.scss';

export type PropsT = {
  selected: ?Date,
  onChange: () => void,
  minDate: Date,
  inline?: boolean,
  name?: string,
  bankHolidayData?: any, // eslint-disable-line
  highlightDates?: Array<any> // eslint-disable-line
};

const CustomDatePicker = ({
  selected,
  onChange,
  minDate,
  inline,
  bankHolidayData,
  name,
  highlightDates
}: PropsT): Element<'div'> => {
  const { t, i18n } = useTranslation();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const activeLanguage = i18n.language;
  if (activeLanguage === 'fi') {
    registerLocale(activeLanguage, fi);
  } else if (activeLanguage === 'en') {
    registerLocale('enGB', enGB);
  } else if (activeLanguage === 'sv') {
    registerLocale(activeLanguage, sv);
  } else if (activeLanguage === 'et') {
    registerLocale(activeLanguage, et);
  }

  const [bankHolidays, setBankHolidays] = useState(
    bankHolidayData
      ? bankHolidayData.map(bh => {
          return {
            date: bh.date,
            holidayName: t(`holidays.${bh.name}`)
          };
        })
      : bankHolidayData
  );
  const [customHighlights, setCustomHighlights] = useState();

  const setHighlighting = () => {
    const ch = [];
    if (highlightDates && highlightDates.length > 0) {
      ch.push({ 'react-datepicker__day--highlighted': highlightDates });
    }
    setCustomHighlights(ch);
  };

  const fetchHolidays = async () => {
    if (bankHolidays) return;
    try {
      const response: AxiosPromise = await axios({
        method: 'GET',
        url: `/api/v1/config/bank_holidays/${currentUser.country || 'fi'}`
      });
      if (response && response.data) {
        const holidays = [];
        const unofficialHolidays = [];
        for (let i = 0; i < response.data.length; i++) {
          const hd = response.data[i];
          holidays.push({
            date: hd.date,
            holidayName: t(`holidays.${hd.name}`)
          });
          if (!hd.official) {
            unofficialHolidays.push(new Date(hd.date));
          }
          // TODO: separate unofficial and highlight with custom dates
          // const ch = [{ 'react-datepicker__day--unofficial-holiday': unofficialHolidays }];
        }
        setBankHolidays(holidays);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log('Error', error.message);
      }
    }
  };

  useEffect(() => {
    fetchHolidays();
    setHighlighting();
  }, []); // eslint-disable-line

  return (
    <div className={styles['calendar-area']}>
      <DatePicker
        locale={activeLanguage}
        name={name}
        selected={selected}
        onChange={onChange}
        minDate={minDate}
        holidays={bankHolidays}
        highlightDates={customHighlights}
        inline={inline}
        showIcon
        toggleCalendarOnIconClick
        disabledKeyboardNavigation
        dateFormat="dd.MM.yyyy"
        placeholderText="pp.kk.vvvv" // TODO: translate or remove?
      />
    </div>
  );
};
export default CustomDatePicker;
