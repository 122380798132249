// @flow

/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */

import * as R from 'ramda';
import axios, { type CancelToken, type AxiosPromise } from 'axios';
import fileSaver from 'file-saver';
import HTTP from 'http-status-codes';
import moment from 'moment';
import {
  createRetrieveUserRequest,
  createRetrieveUserSuccess,
  createRetrieveUserFailure,
  createRetrieveUserCollectionRequest,
  createRetrieveUserCollectionSuccess,
  createRetrieveUserCollectionFailure,
  createUpdateUserRequest,
  createUpdateUserSuccess,
  createUpdateUserFailure,
  createDeleteUserRequest,
  createDeleteUserSuccess,
  createDeleteUserFailure,
  createCreateUserRequest,
  createCreateUserSuccess,
  createCreateUserFailure,
  createExportUsersRequest,
  createExportUsersSuccess,
  createExportUsersFailure,
  createRetrieveUserCompleteCollectionRequest,
  createRetrieveUserCompleteCollectionSuccess,
  createRetrieveUserCompleteCollectionFailure,
  createRetrieveUserCompleteCollectionCancel,
  createRetrieveUserCancel,
  createExportUsersCancel,
  createUpdateUserCancel,
  createCreateUserCancel,
  createDeleteUserCancel,
  createRetrieveUserCollectionCancel,
  createRetrieveUserForwardingsRequest,
  createRetrieveUserForwardingsSuccess,
  createRetrieveUserForwardingsFailure,
  createRemoveLocationFromUsers,
  createResetUserPasswordRequest,
  createResetUserPasswordSuccess,
  createResetUserPasswordFailure,
  createResetUserPasswordCancel,
  createExportUsersProcessRequest,
  createExportUsersProcessSuccess,
  createExportUsersProcessCancel,
  createExportUsersProcessFailure,
  createExportUsersStartedSuccess,
  createRetrieveAvatarRequest,
  createRetrieveAvatarSuccess,
  createRetrieveAvatarCancel,
  createRetrieveAvatarFailure
} from './userActions';
import type { ThunkActionT } from '../../../commonTypes';
import type {
  UserEntityT,
  DeleteUserActionT,
  RetrieveUserCollectionActionT,
  UpdateUserActionT,
  CreateUserActionT,
  ExportUserCollectionActionT,
  RetrieveUserCompleteCollectionActionT,
  RetrieveUserForwardingsActionT,
  RemoveLocationFromUsersActionT,
  ResetUserPasswordActionT,
  PasswordResetPayloadT,
  UsersPayloadT,
  UserUpdateEntityT,
  ExportUsersProcessActionT,
  AvatarEntitiesT,
  AvatarUploadT,
  DeleteAvatarT,
  InternalUserStateEntityT
} from './userTypes';
import type { UserConfigT } from '../../config';
import { removeFavourite } from '../../config/configOperations';

export type RemoveLocationFromUsersFnT = string => ThunkActionT<RemoveLocationFromUsersActionT>;

const removeLocationFromUsers: RemoveLocationFromUsersFnT = locationName => async dispatch => {
  dispatch(createRemoveLocationFromUsers(locationName));
};

type RetrieveForwardingFnT = (
  string,
  string,
  CancelToken
) => AxiosPromise<RetrieveUserForwardingsActionT>;

const retrieveUserForwardingsByExtensionId: RetrieveForwardingFnT = (
  enterpriseId,
  id,
  cancelToken
) => async dispatch => {
  dispatch(createRetrieveUserForwardingsRequest(enterpriseId, id));
  try {
    const response: AxiosPromise<UserEntityT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/users/${id}/forwardings`,
      cancelToken
    });
    // $FlowFixMe
    const { data } = await response;
    dispatch(createRetrieveUserForwardingsSuccess(enterpriseId, id, data));
    return data;
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch(createRetrieveUserForwardingsFailure(enterpriseId, id, error));
    }
  }
  return undefined;
};

export type RetrieveFnT = (
  string,
  string,
  UserConfigT | null,
  CancelToken,
  params?: { type?: string }
) => AxiosPromise<UserEntityT | typeof undefined>;

const retrieve: RetrieveFnT = (
  enterpriseId,
  id,
  userConfig,
  cancelToken,
  params = {}
) => async dispatch => {
  dispatch(createRetrieveUserRequest(enterpriseId, id));
  try {
    const response: AxiosPromise<UserEntityT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/users/${id}`,
      cancelToken,
      params
    });
    // $FlowFixMe
    const { data } = await response;
    dispatch(createRetrieveUserSuccess(enterpriseId, id, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveUserCancel(id));
    } else {
      if (error.response && error.response.status === HTTP.NOT_FOUND && userConfig !== null) {
        dispatch(
          // $FlowFixMe
          removeFavourite(
            enterpriseId,
            id,
            params.type === 'internalUser' ? 'internal' : 'external',
            userConfig
          )
        );
      }
      dispatch(createRetrieveUserFailure(enterpriseId, id, error));
    }
  }
  return undefined;
};

export type GetUsersFnT = (
  string,
  CancelToken,
  params?: {}
) => ThunkActionT<RetrieveUserCollectionActionT>;

const getUsers: GetUsersFnT = (enterpriseId, cancelToken, params = {}) => async dispatch => {
  dispatch(createRetrieveUserCollectionRequest(enterpriseId, params));
  try {
    const response: AxiosPromise<UsersPayloadT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/users`,
      cancelToken,
      params
    });
    // $FlowFixMe
    const { data } = await response;
    dispatch(createRetrieveUserCollectionSuccess(enterpriseId, data, params));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveUserCollectionCancel());
    } else {
      dispatch(createRetrieveUserCollectionFailure(enterpriseId, error, params));
    }
  }
  return undefined;
};

export type SearchUsersFnT = (
  string,
  CancelToken,
  params?: {}
) => ThunkActionT<RetrieveUserCollectionActionT>;

const searchUsers: SearchUsersFnT = (enterpriseId, cancelToken, params = {}) => async () => {
  try {
    const response: AxiosPromise<UsersPayloadT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/users`,
      cancelToken,
      params
    });
    // $FlowFixMe
    const { data } = await response;
    return data;
  } catch (error) {
    return undefined;
  }
};

export type RetrieveCollectionByIdsFnT = (
  string,
  serviceType: 'group' | 'ACDGroup',
  CancelToken,
  ids: string[]
) => ThunkActionT<RetrieveUserCollectionActionT>;

const retrieveCollectionByIds: RetrieveCollectionByIdsFnT = (
  enterpriseId,
  serviceType,
  cancelToken,
  ids
) => async dispatch => {
  dispatch(createRetrieveUserCollectionRequest(enterpriseId));
  try {
    const params = new URLSearchParams();
    params.append('serviceType', serviceType);
    ids.forEach(id => params.append('id', id));
    const response: AxiosPromise<UsersPayloadT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/users/byIds`,
      cancelToken,
      params
    });
    // $FlowFixMe
    const { data } = await response;
    dispatch(createRetrieveUserCollectionSuccess(enterpriseId, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveUserCollectionCancel());
    } else {
      dispatch(createRetrieveUserCollectionFailure(enterpriseId, error));
    }
    throw error;
  }
};

export type RetrieveCompleteCollectionFnT = (
  { enterpriseId: string },
  CancelToken
) => ThunkActionT<RetrieveUserCompleteCollectionActionT>;

const retrieveCompleteCollection: RetrieveCompleteCollectionFnT = (
  { enterpriseId },
  cancelToken
) => async dispatch => {
  dispatch(createRetrieveUserCompleteCollectionRequest(enterpriseId));
  try {
    let results = [];
    let fetchMore = true;
    let page = 1;
    const size = 500;

    while (fetchMore) {
      const response: AxiosPromise<UsersPayloadT> = axios({
        method: 'GET',
        url: `/api/v1/enterprises/${enterpriseId}/users`,
        cancelToken,
        params: { page, size }
      });
      const { data } = await response; // eslint-disable-line no-await-in-loop
      results = results.concat(data.results);
      if (results.length >= data.totalCount) {
        fetchMore = false;
      }
      page++;
    }

    const users = {
      results,
      totalCount: results.length,
      length: results.length,
      offset: 0
    };

    dispatch(createRetrieveUserCompleteCollectionSuccess(enterpriseId, users));
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveUserCompleteCollectionCancel());
    } else {
      dispatch(createRetrieveUserCompleteCollectionFailure(enterpriseId, error));
    }
  }
};

export type RetrieveCompleteCollectionForDepartmentFnT = (
  { enterpriseId: string, departmentId?: string },
  CancelToken
) => AxiosPromise<RetrieveUserCompleteCollectionActionT, ?(InternalUserStateEntityT[])>;

const retrieveCompleteCollectionForDepartment: RetrieveCompleteCollectionForDepartmentFnT = (
  { enterpriseId, departmentId },
  cancelToken
) => async dispatch => {
  dispatch(createRetrieveUserCompleteCollectionRequest(enterpriseId));
  try {
    let results = [];
    let fetchMore = true;
    let page = 1;
    const size = 500;
    const url = departmentId
      ? `/api/v1/enterprises/${enterpriseId}/departments/${departmentId}/users`
      : `/api/v1/enterprises/${enterpriseId}/departments/users`;

    while (fetchMore) {
      const response: AxiosPromise<UsersPayloadT> = axios({
        method: 'GET',
        url,
        cancelToken,
        params: { page, size }
      });
      const { data } = await response; // eslint-disable-line no-await-in-loop
      results = results.concat(data.results);
      if (results.length >= data.totalCount || data.results.length === 0) {
        fetchMore = false;
      }
      page++;
    }

    dispatch(
      createRetrieveUserCompleteCollectionSuccess(enterpriseId, {
        results,
        totalCount: 0,
        length: 0,
        offset: 0
      })
    );
    return results;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveUserCompleteCollectionCancel());
    } else {
      dispatch(createRetrieveUserCompleteCollectionFailure(enterpriseId, error));
    }
    return undefined;
  }
};

export type SearchFnT = (
  string,
  string,
  CancelToken,
  params?: {||}
) => ThunkActionT<RetrieveUserCollectionActionT>;

const search: SearchFnT = (enterpriseId, searchTerm, cancelToken, params = {}) =>
  userOperations.getUsers(enterpriseId, cancelToken, {
    search: searchTerm,
    ...params
  });

type ExportUsersFnT = (
  string,
  CancelToken,
  params?: {},
  headers: {}
) => ThunkActionT<ExportUserCollectionActionT>;

const exportUsers: ExportUsersFnT = (
  enterpriseId,
  cancelToken,
  params = {},
  headers = {}
) => async dispatch => {
  dispatch(createExportUsersRequest(enterpriseId, params));
  try {
    const response: AxiosPromise = axios({
      method: 'POST',
      url: `/api/v1/export/enterprises/${enterpriseId}/users`,
      cancelToken,
      params,
      headers
    });
    const { data } = await response;
    if (data) {
      dispatch(createExportUsersStartedSuccess(enterpriseId, data));
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createExportUsersCancel());
    } else {
      dispatch(createExportUsersFailure(enterpriseId, error));
    }
  }
};

type ExportUsersDoneFnT = (
  string,
  string,
  string,
  string,
  CancelToken,
  params?: {},
  headers?: {}
) => ThunkActionT<ExportUserCollectionActionT>;

const exportUsersDone: ExportUsersDoneFnT = (
  enterpriseId,
  enterpriseFullName,
  uuid,
  userType,
  cancelToken,
  headers = { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
) => async dispatch => {
  const params = new URLSearchParams();
  params.append('uuid', uuid);
  try {
    const response: AxiosPromise = axios({
      method: 'GET',
      url: `/api/v1/export/enterprises/${enterpriseId}/done`,
      responseType: 'blob',
      cancelToken,
      params,
      headers
    });
    const { data } = await response;
    if (data && data.size > 0) {
      userOperations.saveAsFile(data, enterpriseFullName, userType);
      dispatch(createExportUsersSuccess(enterpriseId));
      return data;
    }
    return undefined;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createExportUsersCancel());
    } else {
      dispatch(createExportUsersFailure(enterpriseId, error));
    }
    return undefined;
  }
};

const saveAsFile = (data: [*], enterpriseFullName: string, userType: string): * => {
  const timeStamp = moment().format('YYYYMMDD');
  const enterprise = enterpriseFullName.replace(/\s/g, '');

  let exportType = '';
  if (userType === 'internalUser') {
    exportType = 'internal';
  } else if (userType === 'externalUser') {
    exportType = 'external';
  }
  const fileName = `export_${enterprise}_${exportType}_${timeStamp}.xlsx`;

  fileSaver.saveAs(data, fileName);
};

type GetExportUsersProcessFnT = (
  string,
  string,
  CancelToken,
  params?: {},
  headers?: {}
) => ThunkActionT<ExportUsersProcessActionT>;

const getExportUsersProcess: GetExportUsersProcessFnT = (
  enterpriseId,
  uuid,
  cancelToken,
  headers = {}
) => async dispatch => {
  dispatch(createExportUsersProcessRequest(enterpriseId));
  const params = new URLSearchParams();
  params.append('uuid', uuid);
  try {
    const response: AxiosPromise = axios({
      method: 'GET',
      url: `/api/v1/export/enterprises/${enterpriseId}/process`,
      cancelToken,
      params,
      headers
    });
    const { data } = await response;
    if (data) {
      dispatch(createExportUsersProcessSuccess(enterpriseId, data));
      return data;
    }
    return undefined;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createExportUsersProcessCancel());
    } else {
      dispatch(createExportUsersProcessFailure(enterpriseId, error));
    }
    return undefined;
  }
};

const beautifyId = (id: string) => {
  return id.replace('IUser-', '').replace('UserContact-', '');
};

export type UpdateT = (
  UserUpdateEntityT,
  CancelToken,
  headers: {}
) => ThunkActionT<UpdateUserActionT>;

const update: UpdateT = (user, cancelToken, headers = {}) => {
  const { enterpriseId, id } = user;
  return async dispatch => {
    dispatch(createUpdateUserRequest(enterpriseId, id));
    try {
      const response: AxiosPromise<typeof user> = axios({
        method: 'PATCH',
        url: `/api/v1/enterprises/${enterpriseId}/users/${beautifyId(id)}`,
        headers,
        cancelToken,
        data: user
      });
      // $FlowFixMe
      const { data } = await response;
      dispatch(createUpdateUserSuccess(enterpriseId, id, data));
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        dispatch(createUpdateUserCancel(id));
      } else {
        dispatch(createUpdateUserFailure(enterpriseId, id, error));
      }
      return undefined;
    }
  };
};

// $FlowFixMe
const removeId = R.pipe(R.clone, R.omit(['id']));

type CreateFnT = (UserEntityT, CancelToken, headers: {}) => AxiosPromise<CreateUserActionT>;

const create: CreateFnT = (user, cancelToken, headers = {}) => {
  const { enterpriseId } = user;
  return async dispatch => {
    dispatch(createCreateUserRequest(enterpriseId));
    try {
      const response: AxiosPromise = axios({
        method: 'POST',
        url: `/api/v1/enterprises/${enterpriseId}/users/external`,
        cancelToken,
        data: removeId(user),
        headers
      });
      const { data } = await response;
      dispatch(createCreateUserSuccess(enterpriseId, data));
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        dispatch(createCreateUserCancel());
      } else {
        dispatch(createCreateUserFailure(enterpriseId, error));
      }
      return error;
    }
  };
};

type RemoveFnT = (
  string,
  string,
  params: {},
  CancelToken,
  headers: {}
) => ThunkActionT<DeleteUserActionT>;

const remove: RemoveFnT = (
  enterpriseId,
  id,
  params,
  cancelToken,
  headers = {}
) => async dispatch => {
  dispatch(createDeleteUserRequest(enterpriseId, id));
  try {
    const response: AxiosPromise<void> = axios({
      method: 'DELETE',
      url: `/api/v1/enterprises/${enterpriseId}/users/external/${id}`,
      cancelToken,
      params,
      headers
    });
    await response;
    dispatch(createDeleteUserSuccess(enterpriseId, id));
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createDeleteUserCancel(id));
    } else {
      dispatch(createDeleteUserFailure(enterpriseId, id, error));
    }
  }
};

export type ResetPasswordFnT = (
  string,
  string,
  payload: PasswordResetPayloadT,
  CancelToken,
  headers: {}
) => ThunkActionT<ResetUserPasswordActionT>;

const resetPassword: ResetPasswordFnT = (
  enterpriseId,
  id,
  payload,
  cancelToken,
  headers = {}
) => async dispatch => {
  dispatch(createResetUserPasswordRequest(id));
  try {
    const response: AxiosPromise<PasswordResetPayloadT> = axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/users/${id}/resetPassword`,
      data: payload,
      cancelToken,
      headers
    });

    // $FlowFixMe
    const { data } = await response;

    dispatch(createResetUserPasswordSuccess(id));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createResetUserPasswordCancel(id));
    } else {
      dispatch(createResetUserPasswordFailure(id, error));
    }
    return undefined;
  }
};

type RetrieveAvatarFnT = (
  string,
  string,
  CancelToken
) => AxiosPromise<AvatarEntitiesT | typeof undefined>;
const retrieveAvatar: RetrieveAvatarFnT = (enterpriseId, userId, cancelToken) => async dispatch => {
  if (!userId) {
    return undefined;
  }
  dispatch(createRetrieveAvatarRequest());
  try {
    const response: AxiosPromise<AvatarEntitiesT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/avatar/${userId}`,
      undefined,
      cancelToken
    });
    const { data } = await response;
    dispatch(createRetrieveAvatarSuccess(data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveAvatarCancel());
    } else {
      dispatch(createRetrieveAvatarFailure(error));
    }
    return undefined;
  }
};

type UploadAvatarFnT = (
  string,
  string,
  string,
  CancelToken,
  headers: {}
) => AxiosPromise<AvatarUploadT | typeof undefined>;
const uploadAvatar: UploadAvatarFnT = (
  enterpriseId,
  userId,
  imageFile,
  cancelToken,
  headers = {}
) => async () => {
  const formData = new FormData();
  const fetchedFileRes = await fetch(imageFile);
  const blob = await fetchedFileRes.blob();
  formData.append('file', blob);
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  try {
    const response: AxiosPromise<AvatarUploadT> = axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/avatar/${userId}`,
      data: formData,
      config,
      cancelToken,
      headers
    });
    const { data } = await response;
    return data;
  } catch (error) {
    return undefined;
  }
};

type DeleteAvatarFnT = (
  string,
  string,
  CancelToken,
  headers: {}
) => AxiosPromise<DeleteAvatarT | typeof undefined>;
const deleteAvatar: DeleteAvatarFnT = (
  enterpriseId,
  userId,
  cancelToken,
  headers = {}
) => async () => {
  try {
    const response: AxiosPromise = axios({
      method: 'DELETE',
      url: `/api/v1/enterprises/${enterpriseId}/avatar/${userId}`,
      cancelToken,
      headers
    });
    const { data } = await response;
    return data;
  } catch (error) {
    return undefined;
  }
};

const userOperations = {
  getUsers,
  retrieveCompleteCollection,
  retrieveCollectionByIds,
  retrieveCompleteCollectionForDepartment,
  searchUsers,
  retrieve,
  search,
  update,
  removeId,
  create,
  remove,
  saveAsFile,
  exportUsers,
  exportUsersDone,
  getExportUsersProcess,
  retrieveForwardings: retrieveUserForwardingsByExtensionId,
  resetPassword,
  removeLocationFromUsers,
  retrieveAvatar,
  uploadAvatar,
  deleteAvatar
};

export default userOperations;
