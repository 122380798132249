// @flow strict-local

import React, { type Element } from 'react';
import { useTranslation } from 'react-i18next';
import type { DynamicPropertyT } from '../../../../../ducks/entities/callFlow/commonCallFlowTypes';
import CallflowItem from './CallflowItem';
import type { DynamicPropertyFwdT } from '../../../../../ducks/entities/acd/acdTypes';
import { enterpriseVoiceMailAddress } from '../../edit/children/forwardingField/ForwardingUtils';

type PropsT = {|
  id: string,
  title: string,
  // eslint-disable-next-line flowtype/space-after-type-colon
  property:
    | ?DynamicPropertyT<boolean | number | string>
    | DynamicPropertyFwdT
    | boolean
    | number
    | string,
  notDefinedMsg?: string,
  booleanInUseMsg?: string,
  booleanNotInUseMsg?: string,
  postMsg?: string,
  preMsg?: string,
  active?: boolean,
  key?: string,
  tooltip?: string,
  reverseBooleanValues?: boolean,
  basicValue?: boolean,
  forceActive?: boolean
|};

export const CallflowProperty = (props: PropsT): Element<typeof CallflowItem> => {
  const {
    id,
    key,
    title,
    property,
    notDefinedMsg,
    booleanInUseMsg,
    booleanNotInUseMsg,
    postMsg,
    preMsg,
    active,
    forceActive,
    tooltip,
    reverseBooleanValues,
    basicValue
  } = props;

  const { t } = useTranslation();

  const getForwardingType = (propFwd: DynamicPropertyFwdT) => {
    if (!propFwd.destination) {
      return '';
    }
    switch (propFwd.destination.alias) {
      case 'ACDGroupAddress':
        return (propFwd.destination.label || '').startsWith('kutsu:')
          ? t('callflows.callflowProperty.destination.acdSwitchBoard')
          : t('callflows.callflowProperty.destination.acdCustomerBoard');
      case 'GroupAddress':
        return t('callflows.callflowProperty.destination.extensionGroup');
      case 'IVRService':
        // The type is either PlayMusic, WelcomeAttendant or VoiceMail
        return propFwd.value === enterpriseVoiceMailAddress
          ? t('callflows.callflowProperty.destination.voicemail')
          : '';
      case 'SpeedDial':
        return t('callflows.callflowProperty.destination.speedDial');
      case 'Extension':
        return t('callflows.callflowProperty.destination.user');
      case 'FaxAddress':
        return t('callflows.callflowProperty.destination.fax');
      default:
        return '';
    }
  };

  const getServiceName = (label: ?string): ?string =>
    (label || '').startsWith('kutsu:') ? (label || '').slice(6) : label;

  const isDefault = (): boolean => {
    // $FlowFixMe
    return property?.level === 'Default' || property?.level === 'Entreprise';
  };

  const forwardingProperty = (propertyFwd: DynamicPropertyFwdT) => {
    if (!propertyFwd.value || propertyFwd.value === 'none') {
      return [t('callflows.callflowProperty.endCall'), '', ''];
    }
    let target = propertyFwd.value || '';
    if (propertyFwd.destination) {
      const label: string =
        propertyFwd.destination.userName || getServiceName(propertyFwd.destination.label) || '';
      target = `${label} - ${getForwardingType(propertyFwd)} (${propertyFwd.value || ''})`;
    }
    return [t('callflows.callflowProperty.destinationPreMsg'), target, ''];
  };

  if (!basicValue) {
    if (!property || property.value === undefined || property.value === null) {
      let msg = isDefault()
        ? t('callflows.callflowProperty.default')
        : t('callflows.callflowProperty.notDefinedMsg');

      msg = notDefinedMsg || msg;
      return <CallflowItem id={id} key={key} title={title} value={msg} active={active} />;
    }
    let { value } = property;
    let postFix = '';
    let preFix = preMsg || '';
    if (property.type === 'Boolean') {
      const val = reverseBooleanValues ? !property.value : property.value;
      value = val
        ? booleanInUseMsg || t('callflows.callflowProperty.booleanInUseMsg')
        : booleanNotInUseMsg || t('callflows.callflowProperty.booleanNotInUseMsg');
    } else if (property.type === 'Integer' && value) {
      postFix = postMsg !== undefined ? postMsg : t('callflows.callflowProperty.integerPostMsg');
    } else if (property.type === 'Destination' && value) {
      // $FlowFixMe
      [preFix, value, postFix] = forwardingProperty(property);
    }
    if (isDefault()) preFix = `${t('callflows.callflowProperty.default')}: ${preFix}`;

    return (
      <CallflowItem
        id={id}
        key={key}
        title={title}
        // $FlowFixMe Specify DynamicPropertyT typing
        value={preFix + value + postFix}
        active={active}
        tooltip={tooltip}
      />
    );
  }
  let postFix = '';
  let value = property;
  let preFix = preMsg || '';
  if (typeof property === 'boolean') {
    const val = reverseBooleanValues ? !property : property;
    value = val
      ? booleanInUseMsg || t('callflows.callflowProperty.booleanInUseMsg')
      : booleanNotInUseMsg || t('callflows.callflowProperty.booleanNotInUseMsg');
  } else if (typeof property === 'number' && property) {
    postFix = postMsg !== undefined ? postMsg : t('callflows.callflowProperty.integerPostMsg');
  }
  if (isDefault()) preFix = `${t('callflows.callflowProperty.default')}: ${preFix}`;

  return (
    <CallflowItem
      id={id}
      key={key}
      title={title}
      // $FlowFixMe
      value={preFix + value + postFix}
      active={forceActive || active}
      tooltip={tooltip}
    />
  );
};

export default CallflowProperty;
