// @flow

import React, { type Element, Fragment, useEffect, useState, useCallback, useRef } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorWithRetry from '../../../../components/ErrorWithRetry/ErrorWithRetry';
import useLoadingIndicator from '../../../../components/useLoadingIndicator';
import ErrorBoundary from '../../../../components/Error/ErrorBoundary';
import GenericError from '../../../../components/Error/GenericError';
import type { TranslateT } from '../../../../commonTypes';
import { createUpdateEditStatus } from '../../../../ducks/ui/callflow/callflowUiActions';
import FormButtons from './children/FormButtons';
import { IntegerField } from './children/IntegerField';
import { DurationField } from './children/DurationField';
import { InputField } from './children/InputField';
import { AcdAudioField } from './children/audio/AcdAudioField';
import { IvrAudioField } from './children/audio/IvrAudioField';
import { RadioButton } from './children/RadioButton';
import { ForwardingField } from './children/forwardingField/ForwardingField';
import { CallDistributionSelector } from './children/CallDistributionSelector';
import { UserSelector } from './children/UserSelector';
import { DropdownField } from './children/DropdownField';
import { ToggleDropdown } from './children/ToggleDropdown';
import { CalendarSelector } from './children/calendar/CalendarSelector';
import { MultiAudiosSelection } from './children/audio/MultiAudiosSelection';
import { CalendarField } from './children/calendar/CalendarField';
import { WelcomeAttendantStepField } from './children/welcomeAttendantStepField/WelcomeAttendantStepField';
import { InteractionNextStepsField } from '../../callFlowGrid/details/welcomeAttendant/interaction/InteractionNextStepsField';
import { UnattachedStepsField } from './children/welcomeAttendantUnattachedSteps/UnattachedStepsField';
import { NumberRangeSelector } from './children/NumberRangeSelector/NumberRangeSelector';
import AbsoluteTimesField from '../../calendar/AbsoluteTimesField';
import ConfirmButton from '../../../../components/Button/ConfirmButton';
import DateTimePicker from '../../../../components/DateTimePicker/DateTimePicker';
import Dialog from '../../../../components/Dialog';
import GroupPriority from '../GroupPriority/GroupPriority';
import { SubTopic } from './children/SubTopic';
import { PasswordField } from './children/PasswordField';
import { actions as notificationActions } from '../../../../ducks/ui/notification';
import CenterHorizontally from '../../../../components/CenterHorizontally/CenterHorizontally';
import DirectorySelector from '../../../../components/DirectorySelector/DirectorySelector';
import { DestinationField } from './children/destinationField/DestinationField';
import { InvalidConfigurationInfo } from '../view/children/InvalidConfigurationInfo';
import { CalendarWithRadio } from '../../callFlowGrid/details/extensionGroup/CalendarWithRadio';
import GroupDisplayedLabels from '../GroupDisplayedLabels/GroupDisplayedLabels';
import { ACDForwardingField } from './children/forwardingField/ACDForwardingField';
import { VoiceMailAudioField } from './children/audio/VoiceMailAudioField';
import { SpeedDialForwardingField } from './children/forwardingField/SpeedDialForwardingField';
import ToggleField from './children/ToggleField';
import AcdExtensionAudioField from './children/audio/AcdExtensionAudioField';
import CheckboxField from './children/CheckboxField';
import styles from './EditCallflowDetails.module.scss';

type ChildTypeT =
  | 'div'
  | 'h4'
  | typeof ToggleField
  | typeof CheckboxField
  | typeof IntegerField
  | typeof DurationField
  | typeof InputField
  | typeof IvrAudioField
  | typeof AcdAudioField
  | typeof AcdExtensionAudioField
  | typeof RadioButton
  | typeof CallDistributionSelector
  | typeof UserSelector
  | typeof ForwardingField
  | typeof DropdownField
  | typeof CalendarSelector
  | typeof CalendarField
  | typeof MultiAudiosSelection
  | typeof WelcomeAttendantStepField
  | typeof InteractionNextStepsField
  | typeof ToggleDropdown
  | typeof AbsoluteTimesField
  | typeof ConfirmButton
  | typeof DateTimePicker
  | typeof Dialog
  | typeof UnattachedStepsField
  | typeof VoiceMailAudioField
  | typeof SubTopic
  | typeof PasswordField
  | typeof Fragment
  | typeof CalendarWithRadio
  | typeof InvalidConfigurationInfo
  | typeof NumberRangeSelector
  | typeof GroupPriority
  | typeof DestinationField
  | typeof ACDForwardingField
  | typeof DirectorySelector
  | typeof GroupDisplayedLabels
  | typeof SpeedDialForwardingField;

type PropsT = {
  nodeId?: string, // TODO: make nodeId mandatory field
  icon: React$Node,
  title: string,
  description?: string,
  children: Element<ChildTypeT> | (Element<ChildTypeT> | null)[],
  defaultValues: {
    [string]: *
  },
  validationSchema?: ?{
    [string]: *
  },
  onSaveForm: (*) => Promise<*> | void,
  onLoadData?: () => Promise<*>,
  onCancel?: () => void,
  onDelete?: (*) => void,
  deleteButtonLabel?: string,
  containerStyle?: string,
  translateMock?: TranslateT<>,
  disableCallflowNotifications?: boolean,
  doNotCloseOnSubmit?: boolean
};

export const EditCallflowDetails = (props: PropsT): Element<'div'> => {
  const {
    nodeId,
    icon,
    title,
    description,
    children,
    defaultValues,
    onSaveForm,
    validationSchema,
    onLoadData,
    onCancel,
    onDelete,
    deleteButtonLabel,
    containerStyle,
    translateMock,
    disableCallflowNotifications,
    doNotCloseOnSubmit
  } = props;

  // state
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [loadingDataError, setLoadingDataError] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const allowCallflowNotifications =
    disableCallflowNotifications === undefined ? true : !disableCallflowNotifications;

  // redux
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onClick = onCancel || (() => dispatch(createUpdateEditStatus(nodeId || '', false)));

  // form
  const methods = useForm({
    defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : null
  });

  const fieldNames = R.keys(defaultValues);
  fieldNames.forEach(name =>
    methods.register(name, {
      shouldUnregister: true
    })
  );

  const mounted = useRef(false);

  const loadData = useCallback(async () => {
    if (onLoadData) {
      try {
        await onLoadData();
        setLoadingDataError(false);
      } catch (error) {
        setLoadingDataError(true);
      }
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
    }
  }, [onLoadData]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    loadData();
    methods.trigger();
  }, [loadData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCallFlowNotification = success => {
    const notification = {
      tag: success ? 'callflow-edit-success' : 'callflow-edit-fail',
      duration: 15000,
      type: success ? 'info' : 'error',
      message: success
        ? t('callflow.editCallflow.successNotification')
        : t('callflow.editCallflow.failedNotification')
    };

    dispatch(notificationActions.createCreateNotificationAction(notification));
  };

  // update
  const onSubmit = async formData => {
    setIsUpdating(true);
    try {
      const returnValue = await onSaveForm(formData);

      if (!doNotCloseOnSubmit) {
        if (allowCallflowNotifications) {
          handleCallFlowNotification(!!returnValue);
        }
        if (mounted.current) {
          setIsUpdating(false);
        }
        onClick();
      }
      setIsUpdating(false);
    } catch (error) {
      if (allowCallflowNotifications) {
        handleCallFlowNotification(false);
      }
      setIsUpdating(false);
    }
  };

  const showLoadingIndicator = useLoadingIndicator(isLoadingData);

  let content;
  if (showLoadingIndicator) {
    content = (
      <CenterHorizontally>
        <LoadingSpinner />
      </CenterHorizontally>
    );
  } else if (loadingDataError) {
    content = (
      <ErrorWithRetry
        className={styles['retry-error']}
        onRetry={loadData}
        buttonText={t('callflows.editCallflowDetails.fetchErrorRetryLabel')}
        text={t('callflows.editCallflowDetails.fetchErrorMessage')}
      />
    );
  } else if (!isLoadingData) {
    content = children;
  }

  return (
    <div className={classnames(styles.container, containerStyle)}>
      <ErrorBoundary
        errorElement={
          <GenericError
            showReloadLink
            message={t('callflows.editCallflowDetails.genericErrorMessage')}
            translateMock={translateMock}
          />
        }
      >
        <FormProvider {...methods}>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <form
            onKeyPress={e => {
              // eslint-disable-next-line no-unused-expressions
              e.key === 'Enter' && e.preventDefault();
            }}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className={styles['topic-icon']}>{icon}</div>
            <div className={styles.title}>{title}</div>
            {description && <div className={styles.description}>{description}</div>}
            <div className={styles.content}>{content}</div>
            <div className={styles['button-area']}>
              <FormButtons
                id="callflow"
                onSaveLabel={t('callflows.editCallflowDetails.onSaveLabel')}
                onCancelLabel={t('callflows.editCallflowDetails.onCancelLabel')}
                {...(deleteButtonLabel ? { onDeleteLabel: deleteButtonLabel } : {})}
                saveDisabled={
                  !methods.formState.isValid ||
                  Object.keys(methods.formState.dirtyFields).length === 0 ||
                  isUpdating
                }
                isUpdating={isUpdating}
                onSave={() => {}}
                onCancel={onClick}
                onDelete={onDelete}
                errorMessage=""
              />
            </div>
          </form>
        </FormProvider>
      </ErrorBoundary>
    </div>
  );
};

export default EditCallflowDetails;
