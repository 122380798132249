// @flow

/* eslint-disable react/prop-types */

import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import classnames from 'classnames';
import { ReactComponent as PauseSVG } from '../../assets/pause.svg';
import { ReactComponent as PlaySVG } from '../../assets/play.svg';
import { ReactComponent as DownloadIcon } from '../../assets/download_icon.svg';
import { SmallLoadingIndicator } from '../SmallLoadingIndicator/SmallLoadingIndicator';
import { ErrorWithRetry } from '../ErrorWithRetry/ErrorWithRetry';
import type { AudioLevelT } from '../../ducks/entities/callFlow/callFlowTypes';
import { basename, stripExtension } from '../../helpers';

import styles from './AudioPlayer.module.scss';

const style = {
  drag(left) {
    return {
      boxSizing: 'border-box',
      position: 'absolute',
      width: '3px',
      height: '17px',
      left,
      top: '4px',
      background: '#767677',
      opacity: '0.8',
      cursor: 'pointer'
    };
  }
};

type PropsT = {
  duration: number,
  currentTime: number,
  isPlaying: boolean,
  dragLeft: number,
  dragX: number,
  isDragging: boolean,
  audioUrl: string,
  audioFilename: string,
  loading: boolean,
  error: boolean,
  incompatibilityMessage: string,
  onCalculateTime: (
    number,
    number
  ) => { currentTimeMin: string, currentTimeSec: string, durationMin: string, durationSec: string },
  onTogglePlay: () => void,
  onMouseDownProgressBar: *,
  loadingText: string,
  errorText: string,
  errorButtonText: string,
  onRetry: () => Promise<void>,
  active?: boolean,
  level?: AudioLevelT
};

const AudioPlayer = forwardRef<
  PropsT,
  { audio: HTMLAudioElement | null, bar: HTMLDivElement | null, slider: HTMLDivElement | null }
>(
  (
    {
      currentTime,
      duration,
      isPlaying,
      dragLeft,
      audioUrl,
      loading,
      error,
      incompatibilityMessage,
      onCalculateTime,
      onTogglePlay,
      onMouseDownProgressBar,
      loadingText,
      errorText,
      errorButtonText,
      onRetry,
      active,
      audioFilename,
      level
    },
    ref
  ) => {
    const audioRef = useRef(null);
    const barRef = useRef(null);
    const sliderRef = useRef(null);
    const inactiveStyle = active ? '' : styles.inactive;

    useImperativeHandle(ref, () => ({
      audio: audioRef.current,
      bar: barRef.current,
      slider: sliderRef.current
    }));

    const { currentTimeMin, currentTimeSec, durationMin, durationSec } = onCalculateTime(
      currentTime,
      duration
    );

    const LoadingIndicator = (
      <div className={styles['loader-wrapper']}>
        <SmallLoadingIndicator text={loadingText} />
      </div>
    );
    const ErrorIndicator = (
      <div className={styles['error-wrapper']}>
        <ErrorWithRetry
          text={errorText}
          onRetry={onRetry}
          buttonText={errorButtonText}
          className={styles['error-container-style']}
        />
      </div>
    );

    const getBaseFilename = (fp: string) => {
      return stripExtension(fp.includes('/') ? basename(fp, '/') : fp);
    };

    const Player = (
      <div>
        <div className={styles['flex-wrapper']}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio
            src={audioUrl}
            controls={false}
            autoPlay={false}
            ref={audioRef}
            crossOrigin="anonymous"
          >
            <p>{incompatibilityMessage}</p>
          </audio>
          <div className={styles['toggle-play-wrapper']}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
              id="audio-player"
              className={styles['toggle-play']}
              onClick={() => audioUrl && onTogglePlay()}
            >
              {isPlaying ? <PauseSVG /> : <PlaySVG />}
            </a>
          </div>
          {audioUrl && (
            <div className={styles['slider-area']}>
              <div className={classnames(styles.time, inactiveStyle)}>
                {currentTimeMin}:{currentTimeSec}
              </div>
              <div className={styles['progress-bar-wrapper']}>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                  className={classnames(styles['progress-bar'], inactiveStyle)}
                  ref={barRef}
                  onMouseDown={onMouseDownProgressBar}
                />
                <div
                  draggable="true"
                  className={styles.indicator}
                  ref={sliderRef}
                  style={style.drag(dragLeft)}
                />
              </div>
              <div className={classnames(styles.time, inactiveStyle)}>
                {durationMin}:{durationSec}
              </div>
              <div className={styles['download-button']}>
                <a href={audioUrl} download={audioFilename}>
                  <DownloadIcon alt="download" />
                </a>
              </div>
            </div>
          )}
        </div>
        {level !== 'Default' && level !== 'Entreprise' && (
          <div className={styles.filename}>{getBaseFilename(audioFilename)}</div>
        )}
      </div>
    );

    return (
      <div className={styles['audio-player']}>
        {loading && LoadingIndicator}
        {error && ErrorIndicator}
        {!loading && !error && Player}
      </div>
    );
  }
);

export default AudioPlayer;
