// @flow

import * as R from 'ramda';
import axios, { CancelToken, type CancelTokenSource } from 'axios';
import React, { Component, type Element, type ElementRef } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  reduxForm,
  type FormProps as FormPropsT,
  getFormValues,
  change,
  getFormSyncErrors
} from 'redux-form';
import type { ContextRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import AccordionItem from '@design-system/component-library/src/components/Accordion/AccordionItem';
import IconViewOffFilled from '@design-system/component-library/src/components/Icon/lib/IconViewOffFilled';
import IconCameraFilled from '@design-system/component-library/src/components/Icon/lib/IconCameraFilled';
import IconEditFilled from '@design-system/component-library/src/components/Icon/lib/IconEditFilled';
import IconPukFilled from '@design-system/component-library/src/components/Icon/lib/IconPukFilled';
import IconLockRegular from '@design-system/component-library/src/components/Icon/lib/IconLockRegular';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import Button from '@design-system/component-library/src/components/Button';
import Accordion from '@design-system/component-library/src/components/Accordion';
import Badge from '@design-system/component-library/src/components/Badge';
import HTTP from 'http-status-codes';
import Input from '@design-system/component-library/src/components/Input';
import type { StoreStateT } from '../../../../commonTypes';
import {
  MAX_FAVOURITES_RESULTS,
  MAX_MANUAL_CUSTOM_PRESENCE_STATE_CHARS,
  USERS_PAGE_SIZE
} from '../../../../constants';
import { operations as departmentOps } from '../../../../ducks/entities/department';
import { operations as directoryOps } from '../../../../ducks/entities/directory';
import {
  operations as configOps,
  selectors as selectConfig,
  type LanguagesT
} from '../../../../ducks/config';
import {
  operations as locationOps,
  selectors as selectLocations
} from '../../../../ducks/entities/location';
import type { IdTreeT } from '../../../../ducks/entities/department/departmentTypes';
import { operations as userOps, selectors as userSelectors } from '../../../../ducks/entities/user';
import {
  NO_VALUE_FIELD_ID,
  getAvailabilityInfo,
  getAvailability,
  formPersonalCallForwardings,
  retrieveTemporaryVoicemailSettings
} from '../../../../helpers';
import TextError from '../../../../components/Error/TextError';
import ErrorBoundary from '../../../../components/Error/ErrorBoundary';
import type {
  InternalUserEntityT,
  InternalUserStateEntityT,
  UserListElementT
} from '../../../../ducks/entities/user/userTypes';
import {
  goToDepartment,
  goToEnterpriseUserForwardings,
  goToUserDetails,
  goToEditUser,
  goToEditAcdSupervisor
} from '../../../../navigationOperations';
import FixedTitle from '../FixedTitle';
import Title from '../Title';
import {
  DUTIES_MAX_LENGTH,
  TAG_NAMES_MAX_LENGTH,
  renderAdditionalInformationSection,
  renderAvailabilitySection,
  renderDepartmentAndColleaguesSection,
  renderLockedSection,
  renderPasswordResetSection,
  renderPersonalCallForwardingsSection,
  renderPersonalDetailsSection,
  renderUserAccountSection,
  renderVoiceMailSettings,
  renderUploadAvatarDialog,
  EMAILS_SP,
  NICKNAME_SP,
  MOBILE_NUMBER_SP,
  HOME_NUMBER_SP,
  PROFESSIONAL_POSTAL_ADDRESS_SP,
  CORPORATE_USER_ID_SP,
  CONTACT_INFORMATION_SP,
  COST_CENTER_SP,
  ACCESS_CTRL_SYSTEM_PERSON_ID_SP,
  ADDITIONAL_EXPLANATIONS_SP,
  ADDITIONAL_INFO_SP,
  DUTIES_SP,
  LOCATION_SP,
  SUBSTITUTES_SP,
  SUPERIORS_SP,
  ASSISTANTS_SP,
  PSTN_NUMBERS_SP,
  PLMN_NUMBERS_SP,
  MOBILE_NUMBERS_SP,
  HOME_NUMBERS_SP,
  renderACDSettings
} from '../FormHelpers';
import Section from '../../../../components/Section/Section';
import fieldValidators, {
  ACCESS_CTRL_SYSTEM_PERSON_ID_MAX_LENGTH,
  ADDITIONAL_EXPLANATIONS_MAX_LENGTH,
  ADDITIONAL_INFO_MAX_LENGTH,
  CONTACT_INFORMATION_MAX_LENGTH,
  CORPORATE_USER_ID_MAX_LENGTH,
  COST_CENTER_MAX_LENGTH,
  NICKNAME_MAX_LENGTH,
  OFFICE_REFERENCE_ID_MAX_LENGTH,
  TITLE_MAX_LENGTH
} from '../../../../fieldValidators';
import type { PasswordResetPayloadT } from '../../../../ducks/entities/user';
import {
  actions as notificationActions,
  selectors as notificationUiSelectors
} from '../../../../ducks/ui/notification';
import Avatar from '../../../../components/Avatar/Avatar';
import ActionButton from '../../../../components/Button/ActionButton';
import CancelButton from '../../../../components/Button/CancelButton';
import Dismiss from '../../../../components/Button/Dismiss';
import UserLoadingError from '../UserLoadingError';
import type { CallForwardingT } from '../../../../ducks/entities/callForwarding/callForwardingTypes';
import { pushAnalyticsEvent } from '../../../../utils/analyticsHandler';
import { createCsrfHeader, isAdmin } from '../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../ducks/currentUser/currentUserTypes';
import type { VoiceMailAudioFieldT } from '../../../callFlows/components/edit/children/audio/VoiceMailAudioField';
import { uploadVoiceMailAudio } from '../../../../ducks/entities/callFlow/callFlowOperations';
import type { DirectoryAvatarStateEntityT } from '../../../../ducks/entities/directory/directoryTypes';
import {
  ADMIN_CLICKED_RESET_USER_PASSWORD,
  ADMIN_INITIALIZED_RESET_USER_PASSWORD_FAILURE,
  ADMIN_INITIALIZED_RESET_USER_PASSWORD_SUCCESS,
  SHOW_INTERNAL_USER
} from '../../../../matomo_constants';
import CustomCallerID from '../../../../components/CustomCallerID/CustomCallerID';
import styles from './InternalUserDetails.module.scss';

export type InitialFormValuesT = {|
  +internalUserDetails: {|
    +firstName: ?string,
    +lastName: ?string,
    +hideCallerId: ?boolean,
    +personalAvailability: {|
      +availability: ?string,
      +customPresenceState: ?string
    |},
    +personalDetails: {|
      +nickName: ?string,
      +hideNickname: ?boolean,
      +addressNumber: ?string,
      +plmnNumbers: ?{ [string]: string },
      +hidePlmnNumbers: ?boolean,
      +pstnNumbers: ?{ [string]: string },
      +hidePstnNumbers: ?boolean,
      +emails: ?string,
      +hideEmails: ?boolean,
      +title: ?string,
      +officeReferenceID: ?string,
      +mobileNumber: ?string,
      +hideMobileNumber: ?boolean,
      +homeNumber: ?string,
      +hideHomeNumber: ?boolean,
      +professionalPostalAddress: ?{ value: string, label: ?string },
      +hideProfessionalPostalAddress: ?boolean
    |},
    +departmentAndColleagues: {|
      +department: ?{ value: string, label: ?string },
      +hideLocation: ?boolean,
      +superiors: ?(UserListElementT[]),
      +hideSuperiors: ?boolean,
      +assistants: ?(UserListElementT[]),
      +hideAssistants: ?boolean,
      +substitutes: ?(UserListElementT[]),
      +hideSubstitutes: ?boolean
    |},
    +additionalInformation: {|
      +additionalExplanations: ?string,
      +hideAdditionalExplanations: ?boolean,
      +additionalInfo: ?string,
      +hideAdditionalInfo: ?boolean,
      +contactInformation: ?string,
      +hideContactInformation: ?boolean,
      +corporateUserId: ?string,
      +hideCorporateUserId: ?boolean,
      +costCenter: ?string,
      +hideCostCenter: ?boolean,
      +accessCtrlSystemPersonId: ?string,
      +hideAccessCtrlSystemPersonId: ?boolean,
      +duties: ?string,
      +hideDuties: ?boolean,
      +tagNames: ?string
    |},
    +personalCallForwardings: { [string]: string },
    +userAccount: {|
      +login: ?string,
      +language: { label: string, value: string }
    |},
    +passwordReset: {|
      +phoneNumber: ?string,
      +email: ?string
    |},
    +voicemailSettings: {|
      +isVoiceMailNoMessage: boolean,
      +voiceMailAudio: VoiceMailAudioFieldT,
      +startTimeInMillis: ?string,
      +endTimeInMillis: ?string
    |}
  |}
|};

type OwnPropsT = {
  onSave: InternalUserEntityT => Promise<void>,
  onClose: () => void,
  userId: string,
  showLoadingSpinner: boolean,
  languages?: LanguagesT,
  showFixedTitle: boolean,
  onToggleFavourite: (selected: boolean) => *,
  showLoadingError?: boolean,
  reloadFunc: () => void,
  alwaysEdit?: boolean
};

type StatePropsT = {
  initialValues: InitialFormValuesT,
  currentValues: InitialFormValuesT,
  user: InternalUserStateEntityT,
  isUpdating: boolean,
  hasError: boolean,
  usersWithUserName: InternalUserStateEntityT[],
  departmentIdTree: IdTreeT,
  isFavourite: boolean,
  resetSuccessIndicatorToggled: boolean,
  fieldErrors: {},
  formInitialValues: InitialFormValuesT,
  currentUser: CurrentUserT,
  directoryAvatar: DirectoryAvatarStateEntityT,
  numberOfFavourites: number,
  hasResetError: boolean
};

type DispatchPropsT = {
  goToDepartment: (string, string) => *,
  goToUserDetails: (string, string, string) => *,
  goToEditUser: (string, string, string) => *,
  goToEditAcdSupervisor: (string, string) => *,
  fetchLanguages: () => *,
  retrieveDepartments: (string, CancelToken) => *,
  retrieveLocations: (string, CancelToken) => *,
  getUsers: (string, CancelToken, {}) => *,
  changeFormValue: *,
  goToEnterpriseUserForwardings: typeof goToEnterpriseUserForwardings,
  retrieveForwardings: typeof userOps.retrieveForwardings,
  retrieveAvatar: typeof userOps.retrieveAvatar,
  uploadAvatar: typeof userOps.uploadAvatar,
  deleteAvatar: typeof userOps.deleteAvatar,
  notify: typeof notificationActions.createCreateNotificationAction,
  resetPassword: typeof userOps.resetPassword
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  // $FlowFixMe
  ...FormPropsT,
  ...$Exact<ContextRouter>
|};

type StateT = {
  successIndicatorToggled: boolean,
  foundSuperiors: InternalUserEntityT[],
  foundAssistants: InternalUserEntityT[],
  foundSubstitutes: InternalUserEntityT[],
  selectedSuperiors: UserListElementT[],
  selectedAssistants: UserListElementT[],
  selectedSubstitutes: UserListElementT[],
  originalUser: InternalUserStateEntityT,
  forwardings?: CallForwardingT[],
  selectedResetMethod: 'email' | 'phoneNumber' | '',
  resetButtonClicked: boolean,
  showUploadDialog: boolean,
  showHiddenDirectoryDialog: boolean,
  showPasswordResetDialog: boolean,
  showPresenceStateDialog: boolean,
  hiddenDirectorySelection: boolean,
  uploadDialogLoading: boolean,
  avatarToUpload: ?string,
  urlPhoto: ?string,
  userAccount: *,
  originalUserAccount: ?string,
  activeBarrings: string[],
  isAvatarCustomized: boolean,
  temporaryVoicemailSettings: *
};

export const DETAILS_SECTION = 'internal-user-details-personal-details-section';
const SUCCESS_INDICATOR_DURATION = 1500;

const propIsEqual = (
  propPath: string[],
  oldValues: InitialFormValuesT,
  newValues: InitialFormValuesT
): boolean => R.equals(R.path(propPath, oldValues), R.path(propPath, newValues));

const simpleObjIfNonEqual = (
  propPath: string[],
  oldValues: InitialFormValuesT,
  newValues: InitialFormValuesT,
  key: ?string,
  wsNullReset?: boolean
): {} =>
  !propIsEqual(propPath, oldValues, newValues) && R.path(propPath, newValues) != null
    ? {
        // $FlowFixMe
        [key || R.last(propPath)]:
          wsNullReset && R.path(propPath, newValues) === '' ? null : R.path(propPath, newValues)
      }
    : {};

export class InternalUserDetails extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    const { user, directoryAvatar } = props;
    this.getDepartmentsRequestCancelTokenSource = CancelToken.source();
    this.getLocationsRequestCancelTokenSource = CancelToken.source();
    this.avatarCancelTokenSource = CancelToken.source();
    this.uploadAudioCancelTokenSource = CancelToken.source();
    this.uploadAvatarCancelTokenSource = CancelToken.source();
    this.deleteAvatarCancelTokenSource = CancelToken.source();
    this.saveUserAccount = this.saveUserAccount.bind(this);
    this.disableEditMode = this.disableEditMode.bind(this);
    this.showDepartment = this.showDepartment.bind(this);
    this.handleSectionSave = this.handleSectionSave.bind(this);
    this.retrieveBarrings = this.retrieveBarrings.bind(this);
    this.retrieveUserAccount = this.retrieveUserAccount.bind(this);
    this.createDetailsSectionUpdateUserPayload = this.createDetailsSectionUpdateUserPayload.bind(
      this
    );
    this.saveTemporaryVoicemailSettings = this.saveTemporaryVoicemailSettings.bind(this);
    this.retrieveTemporaryVoicemailSettings = this.retrieveTemporaryVoicemailSettings.bind(this);
    this.deleteTemporaryVoicemailSettings = this.deleteTemporaryVoicemailSettings.bind(this);
    this.wasSuccessFullyUpdated = this.wasSuccessFullyUpdated.bind(this);
    this.wasUnsuccessFullyUpdated = this.wasUnsuccessFullyUpdated.bind(this);
    this.scheduleSuccessIndicator = this.scheduleSuccessIndicator.bind(this);
    this.personalDetailSectionRef = React.createRef();
    this.handleSearchInputChanged = this.handleSearchInputChanged.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.removeUserFromList = this.removeUserFromList.bind(this);
    this.showUser = this.showUser.bind(this);
    this.hasListsChanged = this.hasListsChanged.bind(this);
    this.handleAddToSelectedList = this.handleAddToSelectedList.bind(this);
    this.superiorsInput = React.createRef();
    this.assistantsInput = React.createRef();
    this.substitutesInput = React.createRef();
    this.forwardingsCancelToken = CancelToken.source();
    this.loadForwardings = this.loadForwardings.bind(this);
    this.validateNickname = this.validateNickname.bind(this);
    this.validateTitle = this.validateTitle.bind(this);
    this.validateMobileNumber = this.validateMobileNumber.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validateOfficeReferenceID = this.validateOfficeReferenceID.bind(this);
    this.validateDuties = this.validateDuties.bind(this);
    this.validateAdditionalExplanations = this.validateAdditionalExplanations.bind(this);
    this.validateAdditionalInfo = this.validateAdditionalInfo.bind(this);
    this.validateContactInformation = this.validateContactInformation.bind(this);
    this.validateCorporateUserId = this.validateCorporateUserId.bind(this);
    this.validateCostCenter = this.validateCostCenter.bind(this);
    this.validateAccessCtrlSystemPersonId = this.validateAccessCtrlSystemPersonId.bind(this);
    this.validateTagNames = this.validateTagNames.bind(this);
    this.getRequestCancelTokenSource = CancelToken.source();
    this.validateRequired = this.validateRequired.bind(this);
    this.resetUserPassword = this.resetUserPassword.bind(this);
    this.hasSmsError = this.hasSmsError.bind(this);
    this.hasEmailError = this.hasEmailError.bind(this);
    this.handleSmsClick = this.handleSmsClick.bind(this);
    this.handleEmailClick = this.handleEmailClick.bind(this);
    this.handleSendClick = this.handleSendClick.bind(this);
    this.resetButtonClicked = this.resetButtonClicked.bind(this);
    this.stopEditing = this.stopEditing.bind(this);
    this.startEditing = this.startEditing.bind(this);
    this.handleUploadAvatar = this.handleUploadAvatar.bind(this);
    this.handleDeleteAvatar = this.handleDeleteAvatar.bind(this);
    this.hiddenFieldsChanged = this.hiddenFieldsChanged.bind(this);
    this.hiddenFieldsData = this.hiddenFieldsData.bind(this);
    this.updateDropdownValue = this.updateDropdownValue.bind(this);
    this.updateUserAccount = this.updateUserAccount.bind(this);

    this.state = {
      successIndicatorToggled: false,
      originalUser: JSON.parse(JSON.stringify(user)),
      foundSuperiors: [],
      foundAssistants: [],
      foundSubstitutes: [],
      selectedSuperiors: user.superiors || [],
      selectedAssistants: user.assistants || [],
      selectedSubstitutes: user.substitutes || [],
      forwardings: [],
      selectedResetMethod: 'phoneNumber',
      resetButtonClicked: false,
      showUploadDialog: false,
      uploadDialogLoading: false,
      showHiddenDirectoryDialog: false,
      showPasswordResetDialog: false,
      showPresenceStateDialog: false,
      hiddenDirectorySelection: false,
      avatarToUpload: null,
      urlPhoto: user.urlPhoto,
      userAccount: null,
      originalUserAccount: null,
      activeBarrings: [],
      isAvatarCustomized: directoryAvatar && directoryAvatar.isAvatarCustomized,
      temporaryVoicemailSettings: null
    };
  }

  retrieveBarrings: *;

  async retrieveBarrings() {
    const { user, currentUser } = this.props;
    if (
      !((currentUser && currentUser.featureFlags) || []).includes('FEATURE-HIDE-BARRINGS-SECTION')
    ) {
      const response = await axios({
        method: 'GET',
        url: `/api/v2/enterprises/${user.enterpriseId}/extension/${user.id}/barrings`
      });
      if (response && response.data && response.data.results) {
        this.setState({
          activeBarrings: response.data.results
            .filter(d => d.activated)
            .map(d => d.restrictedCallRule.name)
        });
      }
    }
  }

  retrieveUserAccount: *;

  async retrieveUserAccount() {
    const { user, currentUser } = this.props;
    if (((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API')) {
      const response = await axios({
        method: 'GET',
        url: `/api/v2/enterprises/${user.enterpriseId}/useraccount/${user.personId}`
      });
      if (response && response.data) {
        this.setState({
          userAccount: response.data,
          originalUserAccount: JSON.stringify(response.data)
        });
      }
    }
  }

  retrieveTemporaryVoicemailSettings: *;

  async retrieveTemporaryVoicemailSettings() {
    const { user, currentUser } = this.props;
    retrieveTemporaryVoicemailSettings(
      user.enterpriseId,
      currentUser.environment,
      user.id,
      'User',
      data =>
        this.setState({
          temporaryVoicemailSettings: data
        })
    );
  }

  saveTemporaryVoicemailSettings: *;

  async saveTemporaryVoicemailSettings(values: InitialFormValuesT) {
    const { user, currentUser } = this.props;
    const { temporaryVoicemailSettings } = this.state;

    if (temporaryVoicemailSettings && temporaryVoicemailSettings.id) {
      const payload = {
        environment: currentUser.environment,
        enterpriseId: temporaryVoicemailSettings.enterpriseId,
        id: temporaryVoicemailSettings.id,
        targetId: user.id,
        targetType: 'User',
        startTimeInMillis: values.internalUserDetails.voicemailSettings.startTimeInMillis,
        endTimeInMillis: values.internalUserDetails.voicemailSettings.endTimeInMillis
      };
      const response = await axios({
        method: 'PATCH',
        url: `/api/v1/enterprises/${user.enterpriseId}/temporary_voicemail/${temporaryVoicemailSettings.id}`,
        headers: createCsrfHeader(currentUser),
        data: payload,
        validateStatus: status => status === HTTP.OK
      });
      if (response && response.data) {
        this.setState({
          temporaryVoicemailSettings: response.data
        });
      }
    } else {
      const payload = {
        environment: currentUser.environment,
        enterpriseId: user.enterpriseId,
        targetId: user.id,
        targetType: 'User',
        startTimeInMillis: values.internalUserDetails.voicemailSettings.startTimeInMillis,
        endTimeInMillis: values.internalUserDetails.voicemailSettings.endTimeInMillis
      };
      const response = await axios({
        method: 'POST',
        url: `/api/v1/enterprises/${user.enterpriseId}/temporary_voicemail`,
        headers: createCsrfHeader(currentUser),
        data: payload,
        validateStatus: status => status === HTTP.OK
      });
      if (response && response.data) {
        this.setState({
          temporaryVoicemailSettings: response.data
        });
      }
    }
  }

  deleteTemporaryVoicemailSettings: *;

  async deleteTemporaryVoicemailSettings() {
    const { user, currentUser } = this.props;
    const { temporaryVoicemailSettings } = this.state;
    if (temporaryVoicemailSettings) {
      try {
        const payload = {
          environment: currentUser.environment,
          enterpriseId: user.enterpriseId,
          id: temporaryVoicemailSettings.id
        };
        await axios({
          method: 'DELETE',
          url: `/api/v1/enterprises/${user.enterpriseId}/temporary_voicemail/${temporaryVoicemailSettings.id}`,
          headers: createCsrfHeader(currentUser),
          data: payload
        });
        this.setState({
          temporaryVoicemailSettings: undefined
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  componentDidMount() {
    const {
      user: { enterpriseId, personId },
      retrieveDepartments,
      retrieveLocations,
      fetchLanguages,
      languages,
      formInitialValues,
      retrieveAvatar,
      directoryAvatar,
      currentUser
    } = this.props;
    this.props.initialize(formInitialValues);
    pushAnalyticsEvent(
      SHOW_INTERNAL_USER,
      ((currentUser && currentUser.featureFlags) || []).includes('FEATURE-MATOMO-DEBUG')
        ? `${enterpriseId}_${currentUser.id}`
        : enterpriseId
    );

    retrieveDepartments(enterpriseId, this.getDepartmentsRequestCancelTokenSource.token);
    retrieveLocations(enterpriseId, this.getLocationsRequestCancelTokenSource.token);
    if (!languages) {
      fetchLanguages();
    }
    this.loadForwardings(this.props.user.enterpriseId, this.props.user.id);
    if (!directoryAvatar) {
      retrieveAvatar(enterpriseId, personId, this.avatarCancelTokenSource.token);
    }
    this.retrieveBarrings();
    this.retrieveUserAccount();
    this.retrieveTemporaryVoicemailSettings();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: PropsT) {
    const {
      user: { id: newUserId },
      isUpdating
    } = nextProps;
    if (!isUpdating) {
      if (this.props.user.id !== newUserId) {
        this.disableEditMode();
      }

      if (this.wasSuccessFullyUpdated(nextProps)) {
        this.scheduleSuccessIndicator();
      }
    }
  }

  componentDidUpdate(oldProps: PropsT) {
    const { user: oldUser } = oldProps;
    const { user } = this.props;
    if (user.id !== oldUser.id) {
      this.loadForwardings(user.enterpriseId, user.id);
    }
    if (user.superiors !== oldUser.superiors && user.superiors !== undefined) {
      this.setState({
        selectedSuperiors: user.superiors ? user.superiors : []
      });
    }
    if (user.assistants !== oldUser.assistants && user.assistants !== undefined) {
      this.setState({
        selectedAssistants: user.assistants ? user.assistants : []
      });
    }
    if (user.substitutes !== oldUser.substitutes && user.substitutes !== undefined) {
      this.setState({
        selectedSubstitutes: user.substitutes ? user.substitutes : []
      });
    }
  }

  componentWillUnmount() {
    this.getDepartmentsRequestCancelTokenSource.cancel();
    this.getLocationsRequestCancelTokenSource.cancel();
    this.forwardingsCancelToken.cancel();
    this.getRequestCancelTokenSource.cancel();
    this.avatarCancelTokenSource.cancel();
  }

  getRequestCancelTokenSource: CancelTokenSource;

  getDepartmentsRequestCancelTokenSource: CancelTokenSource;

  getLocationsRequestCancelTokenSource: CancelTokenSource;

  avatarCancelTokenSource: CancelTokenSource;

  uploadAudioCancelTokenSource: CancelTokenSource;

  uploadAvatarCancelTokenSource: CancelTokenSource;

  deleteAvatarCancelTokenSource: CancelTokenSource;

  superiorsInput: { current: null | HTMLInputElement };

  assistantsInput: { current: null | HTMLInputElement };

  substitutesInput: { current: null | HTMLInputElement };

  resetFields: *;

  resetFields() {
    this.setState({
      foundSuperiors: [],
      foundAssistants: [],
      foundSubstitutes: []
    });
  }

  forwardingsCancelToken: CancelTokenSource;

  loadForwardings: (string, string) => void;

  async loadForwardings(enterpriseId: string, userId: string) {
    const { retrieveForwardings } = this.props;
    const result = await retrieveForwardings(
      enterpriseId,
      userId,
      this.forwardingsCancelToken.token
    );
    if (result) {
      this.setState({ forwardings: result });
    }
  }

  updateUserAccount: *;

  updateUserAccount(account: *): void {
    if (account) {
      this.setState({ userAccount: account });
      this.props.change('internalUserDetails.hideCallerId', account.hideCallerId);
    }
  }

  showUser: *;

  showUser(userId: ?string): void {
    const {
      user: { enterpriseId },
      goToUserDetails: goToUserPage
    } = this.props;
    if (enterpriseId != null && userId != null) {
      this.resetFields();
      goToUserPage(enterpriseId, userId, 'internalUser');
    }
  }

  validateAdditionalExplanations: string => ?string;

  validateAdditionalExplanations(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      ADDITIONAL_EXPLANATIONS_MAX_LENGTH,
      this.props.t('generic.validators.additionalExplanations')
    );
  }

  validateAdditionalInfo: *;

  validateAdditionalInfo(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      ADDITIONAL_INFO_MAX_LENGTH,
      this.props.t('generic.validators.additionalInfo')
    );
  }

  validateContactInformation: string => ?string;

  validateContactInformation(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      CONTACT_INFORMATION_MAX_LENGTH,
      this.props.t('generic.validators.contactInformation')
    );
  }

  validateCorporateUserId: string => ?string;

  validateCorporateUserId(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      CORPORATE_USER_ID_MAX_LENGTH,
      this.props.t('generic.validators.corporateUserId')
    );
  }

  validateCostCenter: string => ?string;

  validateCostCenter(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      COST_CENTER_MAX_LENGTH,
      this.props.t('generic.validators.costCenter')
    );
  }

  validateAccessCtrlSystemPersonId: string => ?string;

  validateAccessCtrlSystemPersonId(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      ACCESS_CTRL_SYSTEM_PERSON_ID_MAX_LENGTH,
      this.props.t('generic.validators.accessCtrlSystemPersonId')
    );
  }

  validateTagNames: string => ?string;

  validateTagNames(value: string): ?string {
    const { t } = this.props;
    return fieldValidators.maxLengthValidator(
      value,
      TAG_NAMES_MAX_LENGTH,
      t('generic.validators.tagNames')
    );
  }

  validateNickname: string => ?string;

  validateNickname(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      NICKNAME_MAX_LENGTH,
      this.props.t('generic.validators.nickName')
    );
  }

  validateTitle: string => ?string;

  validateTitle(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      TITLE_MAX_LENGTH,
      this.props.t('generic.validators.title')
    );
  }

  validateOfficeReferenceID: string => ?string;

  validateOfficeReferenceID(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      OFFICE_REFERENCE_ID_MAX_LENGTH,
      this.props.t('generic.validators.officeReferenceID')
    );
  }

  validateMobileNumber: string => ?string;

  validateMobileNumber(value: string): ?string {
    return fieldValidators.simpleNumberValidator(value, this.props.t('generic.validators.phone'));
  }

  validateEmail: string => ?string;

  validateEmail(value: string): ?string {
    return fieldValidators.commaSeparatedUniqueEmailsValidator(
      value,
      this.props.t('generic.validators.email')
    );
  }

  validateDuties: string => ?string;

  validateDuties(value: string): ?string {
    const { t } = this.props;
    return fieldValidators.maxLengthValidator(
      value,
      DUTIES_MAX_LENGTH,
      t('generic.validators.duties')
    );
  }

  validateRequired: string => ?string;

  validateRequired(value: string) {
    const { t } = this.props;
    return fieldValidators.requiredValidator(value, t('generic.validators.required'));
  }

  resetUserPassword: string => void;

  async resetUserPassword(selectedMethod: string) {
    const { user, resetPassword, currentValues, t, notify, currentUser } = this.props;
    pushAnalyticsEvent(
      ADMIN_CLICKED_RESET_USER_PASSWORD,
      ((currentUser && currentUser.featureFlags) || []).includes('FEATURE-MATOMO-DEBUG')
        ? `${user.enterpriseId}_${currentUser.id}`
        : user.enterpriseId
    );
    const selectedLanguage = R.path(
      ['internalUserDetails', 'userAccount', 'language', 'value'],
      currentValues
    );
    const params: PasswordResetPayloadT = {
      email:
        (selectedMethod === 'email' && currentValues.internalUserDetails.passwordReset.email) || '',
      mobileNumber:
        (selectedMethod === 'phoneNumber' &&
          currentValues.internalUserDetails.passwordReset.phoneNumber) ||
        '',
      userId: user.personId,
      username: user.login || '',
      language: selectedLanguage
    };
    const success = await resetPassword(
      user.enterpriseId,
      user.id,
      params,
      this.getRequestCancelTokenSource.token,
      createCsrfHeader(currentUser)
    );
    if (success !== undefined) {
      notify({
        tag: 'internal-user-password-deleted',
        duration: 15000,
        type: 'info',
        message: t('users.account.passwordReset.successNotification')
      });
      pushAnalyticsEvent(
        ADMIN_INITIALIZED_RESET_USER_PASSWORD_SUCCESS,
        ((currentUser && currentUser.featureFlags) || []).includes('FEATURE-MATOMO-DEBUG')
          ? `${user.enterpriseId}_${currentUser.id}`
          : user.enterpriseId
      );
    } else {
      notify({
        tag: 'internal-user-password-deleted',
        duration: 150000,
        type: 'error',
        message: t('users.account.passwordReset.failNotification')
      });
      pushAnalyticsEvent(
        ADMIN_INITIALIZED_RESET_USER_PASSWORD_FAILURE,
        ((currentUser && currentUser.featureFlags) || []).includes('FEATURE-MATOMO-DEBUG')
          ? `${user.enterpriseId}_${currentUser.id}`
          : user.enterpriseId
      );
    }

    this.setState({ resetButtonClicked: false });
  }

  hasSmsError: () => boolean;

  hasSmsError = () =>
    !!R.path(['internalUserDetails', 'passwordReset', 'phoneNumber'], this.props.fieldErrors);

  hasEmailError: () => boolean;

  hasEmailError = () =>
    !!R.path(['internalUserDetails', 'passwordReset', 'email'], this.props.fieldErrors);

  handleSmsClick: () => void;

  handleSmsClick = () =>
    this.setState({ selectedResetMethod: 'phoneNumber', resetButtonClicked: false });

  handleEmailClick: () => void;

  handleEmailClick = () =>
    this.setState({ selectedResetMethod: 'email', resetButtonClicked: false });

  resetButtonClicked: () => void;

  resetButtonClicked = () => this.setState({ resetButtonClicked: false });

  toggleUploadDialog: () => void;

  toggleUploadDialog = () => this.setState({ showUploadDialog: !this.state.showUploadDialog });

  toggleHiddenDirectoryDialog = () =>
    this.setState({ showHiddenDirectoryDialog: !this.state.showHiddenDirectoryDialog });

  setHiddenDirectorySelection = (val: boolean) => this.setState({ hiddenDirectorySelection: val });

  togglePasswordResetDialog = () =>
    this.setState({ showPasswordResetDialog: !this.state.showPasswordResetDialog });

  togglePresenceStateDialog = () =>
    this.setState({ showPresenceStateDialog: !this.state.showPresenceStateDialog });

  setAvatarToUpload: (avatar?: string) => void;

  setAvatarToUpload = (avatar?: string) => {
    this.setState({ avatarToUpload: avatar });
  };

  setUrlPhoto: (urlPhoto?: string) => void;

  setUrlPhoto = (urlPhoto?: string) => {
    this.setState({ urlPhoto });
  };

  toggleUploadDialogLoading: () => void;

  toggleUploadDialogLoading = () =>
    this.setState({ uploadDialogLoading: !this.state.uploadDialogLoading });

  handleSendClick: (
    event?: SyntheticEvent<HTMLButtonElement>,
    hasSelectedMethodFieldError: boolean
  ) => void;

  handleSendClick = (
    event?: SyntheticEvent<HTMLButtonElement>,
    hasSelectedMethodFieldError: boolean
  ) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (!hasSelectedMethodFieldError) {
      this.setState({ resetButtonClicked: true });
      this.resetUserPassword(this.state.selectedResetMethod);
    }
  };

  handleSearchInputChanged: *;

  async handleSearchInputChanged(searchTerm: string, target: string) {
    const {
      searchDirectory,
      user: { enterpriseId, addressNumber }
    } = this.props;
    this.setState({
      foundSuperiors: [],
      foundAssistants: [],
      foundSubstitutes: []
    });
    const newTokenSource = CancelToken.source();
    const params = {};
    params.sort = 'userName,asc';
    params.page = 1;
    params.size = USERS_PAGE_SIZE;
    params.search = searchTerm;
    params.type = 'users';
    if (searchTerm && searchTerm.length > 2) {
      const foundUsers = await searchDirectory(enterpriseId, newTokenSource.token, params);

      if (foundUsers && foundUsers.totalCount > 0) {
        const results = foundUsers.results
          .map(res => ({
            id: res.internalAddressID,
            userName: res.displayName,
            addressNumber: res.publicInfo.addressNumber
          }))
          .filter(result => {
            return (
              addressNumber !== result.addressNumber &&
              ((target === 'internalUserDetails.departmentAndColleagues.superiors' &&
                !this.state.selectedSuperiors.find(
                  user => result.addressNumber === user.addressNumber
                )) ||
                (target === 'internalUserDetails.departmentAndColleagues.assistants' &&
                  !this.state.selectedAssistants.find(
                    user => result.addressNumber === user.addressNumber
                  )) ||
                (target === 'internalUserDetails.departmentAndColleagues.substitutes' &&
                  !this.state.selectedSubstitutes.find(
                    user => result.addressNumber === user.addressNumber
                  )))
            );
          });
        switch (target) {
          case 'internalUserDetails.departmentAndColleagues.superiors':
            this.setState({
              foundSuperiors: results
            });
            break;
          case 'internalUserDetails.departmentAndColleagues.assistants':
            this.setState({
              foundAssistants: results
            });
            break;
          case 'internalUserDetails.departmentAndColleagues.substitutes':
            this.setState({
              foundSubstitutes: results
            });
            break;
          default:
        }
      }
    }
  }

  hasListsChanged: *;

  hasListsChanged() {
    return (
      !R.equals(this.state.originalUser.superiors, this.state.selectedSuperiors) ||
      !R.equals(this.state.originalUser.substitutes, this.state.selectedSubstitutes) ||
      !R.equals(this.state.originalUser.assistants, this.state.selectedAssistants)
    );
  }

  removeUserFromList: *;

  removeUserFromList(id: string, target: string) {
    switch (target) {
      case 'superiors':
        this.setState(state => ({
          selectedSuperiors: state.selectedSuperiors.filter(user => user.addressNumber !== id)
        }));
        break;
      case 'assistants':
        this.setState(state => ({
          selectedAssistants: state.selectedAssistants.filter(user => user.addressNumber !== id)
        }));
        break;
      case 'substitutes':
        this.setState(state => ({
          selectedSubstitutes: state.selectedSubstitutes.filter(user => user.addressNumber !== id)
        }));
        break;
      default:
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleSelectUser(result: *) {
    return result
      ? [
          {
            id: result.id,
            addressNumber: result.addressNumber,
            userName: result.userName || result.label
          }
        ]
      : [];
  }

  handleAddToSelectedList: *;

  handleAddToSelectedList(result: *, target: string, selectedResultIndex: number) {
    const { foundSuperiors, foundAssistants, foundSubstitutes } = this.state;
    switch (target) {
      // eslint-disable-next-line no-case-declarations
      case 'departmentAndColleagues.superiors':
        this.setState(state => ({
          selectedSuperiors: [
            ...state.selectedSuperiors,
            ...this.handleSelectUser(result || foundSuperiors[selectedResultIndex])
          ]
        }));
        this.props.change('internalUserDetails.departmentAndColleagues.superiors', '');
        break;
      case 'departmentAndColleagues.assistants':
        this.setState(state => ({
          selectedAssistants: [
            ...state.selectedAssistants,
            ...this.handleSelectUser(result || foundAssistants[selectedResultIndex])
          ]
        }));
        this.props.change('internalUserDetails.departmentAndColleagues.assistants', '');
        break;
      case 'departmentAndColleagues.substitutes':
        this.setState(() => ({
          selectedSubstitutes: [
            ...this.handleSelectUser(result || foundSubstitutes[selectedResultIndex])
          ]
        }));
        this.props.change('internalUserDetails.departmentAndColleagues.substitutes', '');
        break;
      default:
    }
    this.setState({
      foundSuperiors: [],
      foundAssistants: [],
      foundSubstitutes: []
    });
  }

  personalDetailSectionRef: ElementRef<*>;

  scheduleSuccessIndicator: *;

  scheduleSuccessIndicator(): void {
    this.setState(
      {
        successIndicatorToggled: true
      },
      () => {
        setTimeout(() => {
          this.setState({
            successIndicatorToggled: false
          });
        }, SUCCESS_INDICATOR_DURATION);
      }
    );
  }

  wasSuccessFullyUpdated: *;

  wasSuccessFullyUpdated(nextProps: PropsT): boolean {
    return this.props.isUpdating && !nextProps.isUpdating && !nextProps.hasError;
  }

  wasUnsuccessFullyUpdated: *;

  wasUnsuccessFullyUpdated(nextProps: PropsT): boolean {
    return this.props.isUpdating && !nextProps.isUpdating && nextProps.hasError;
  }

  disableEditMode: *;

  disableEditMode(): void {
    this.props.reset();
  }

  showDepartment: *;

  showDepartment(): void {
    const {
      initialValues: { internalUserDetails },
      user: { enterpriseId },
      goToDepartment: goToDepartmentPage
    } = this.props;

    const departmentId = R.path(
      ['department', 'value'],
      internalUserDetails.departmentAndColleagues
    );
    if (enterpriseId != null && departmentId != null) {
      goToDepartmentPage(enterpriseId, departmentId, { departmentTabSelected: true });
    }
  }

  hiddenFieldsChanged: *;

  // eslint-disable-next-line class-methods-use-this
  hiddenFieldsChanged(initialValues: *, values: *): boolean {
    const path = (suffix: string[]) => R.prepend('internalUserDetails', suffix);
    return (
      !propIsEqual(path(['personalDetails', 'hideEmails']), initialValues, values) ||
      !propIsEqual(path(['personalDetails', 'hideNickname']), initialValues, values) ||
      !propIsEqual(path(['personalDetails', 'hideMobileNumber']), initialValues, values) ||
      !propIsEqual(path(['personalDetails', 'hideHomeNumber']), initialValues, values) ||
      !propIsEqual(
        path(['personalDetails', 'hideProfessionalPostalAddress']),
        initialValues,
        values
      ) ||
      !propIsEqual(path(['personalDetails', 'hidePstnNumbers']), initialValues, values) ||
      !propIsEqual(path(['personalDetails', 'hidePlmnNumbers']), initialValues, values) ||
      !propIsEqual(path(['additionalInformation', 'hideCorporateUserId']), initialValues, values) ||
      !propIsEqual(
        path(['additionalInformation', 'hideContactInformation']),
        initialValues,
        values
      ) ||
      !propIsEqual(path(['additionalInformation', 'hideCostCenter']), initialValues, values) ||
      !propIsEqual(
        path(['additionalInformation', 'hideAccessCtrlSystemPersonId']),
        initialValues,
        values
      ) ||
      !propIsEqual(
        path(['additionalInformation', 'hideAdditionalExplanations']),
        initialValues,
        values
      ) ||
      !propIsEqual(path(['additionalInformation', 'hideAdditionalInfo']), initialValues, values) ||
      !propIsEqual(path(['additionalInformation', 'hideDuties']), initialValues, values) ||
      !propIsEqual(path(['departmentAndColleagues', 'hideLocation']), initialValues, values) ||
      !propIsEqual(path(['departmentAndColleagues', 'hideSubstitutes']), initialValues, values) ||
      !propIsEqual(path(['departmentAndColleagues', 'hideSuperiors']), initialValues, values) ||
      !propIsEqual(path(['departmentAndColleagues', 'hideAssistants']), initialValues, values)
    );
  }

  hiddenFieldsData: *;

  // eslint-disable-next-line class-methods-use-this
  hiddenFieldsData(values: *): * {
    const SPs = [];
    const extSPs = [];
    if (R.path(['internalUserDetails', 'personalDetails', 'hideEmails'], values)) {
      SPs.push(EMAILS_SP);
    }
    if (R.path(['internalUserDetails', 'personalDetails', 'hideNickname'], values)) {
      SPs.push(NICKNAME_SP);
    }
    if (R.path(['internalUserDetails', 'personalDetails', 'hideMobileNumber'], values)) {
      SPs.push(MOBILE_NUMBER_SP);
    }
    if (R.path(['internalUserDetails', 'personalDetails', 'hideHomeNumber'], values)) {
      SPs.push(HOME_NUMBER_SP);
    }
    if (
      R.path(['internalUserDetails', 'personalDetails', 'hideProfessionalPostalAddress'], values)
    ) {
      SPs.push(PROFESSIONAL_POSTAL_ADDRESS_SP);
    }
    if (R.path(['internalUserDetails', 'additionalInformation', 'hideCorporateUserId'], values)) {
      SPs.push(CORPORATE_USER_ID_SP);
    }
    if (
      R.path(['internalUserDetails', 'additionalInformation', 'hideContactInformation'], values)
    ) {
      SPs.push(CONTACT_INFORMATION_SP);
    }
    if (R.path(['internalUserDetails', 'additionalInformation', 'hideCostCenter'], values)) {
      SPs.push(COST_CENTER_SP);
    }
    if (
      R.path(
        ['internalUserDetails', 'additionalInformation', 'hideAccessCtrlSystemPersonId'],
        values
      )
    ) {
      SPs.push(ACCESS_CTRL_SYSTEM_PERSON_ID_SP);
    }
    if (
      R.path(['internalUserDetails', 'additionalInformation', 'hideAdditionalExplanations'], values)
    ) {
      SPs.push(ADDITIONAL_EXPLANATIONS_SP);
    }
    if (R.path(['internalUserDetails', 'additionalInformation', 'hideAdditionalInfo'], values)) {
      SPs.push(ADDITIONAL_INFO_SP);
    }
    if (R.path(['internalUserDetails', 'additionalInformation', 'hideDuties'], values)) {
      SPs.push(DUTIES_SP);
    }
    if (R.path(['internalUserDetails', 'departmentAndColleagues', 'hideLocation'], values)) {
      SPs.push(LOCATION_SP);
    }

    if (R.path(['internalUserDetails', 'departmentAndColleagues', 'hideSubstitutes'], values)) {
      extSPs.push(SUBSTITUTES_SP);
    }
    if (R.path(['internalUserDetails', 'departmentAndColleagues', 'hideSuperiors'], values)) {
      extSPs.push(SUPERIORS_SP);
    }
    if (R.path(['internalUserDetails', 'departmentAndColleagues', 'hideAssistants'], values)) {
      extSPs.push(ASSISTANTS_SP);
    }
    if (R.path(['internalUserDetails', 'personalDetails', 'hidePstnNumbers'], values)) {
      extSPs.push(PSTN_NUMBERS_SP);
    }
    if (R.path(['internalUserDetails', 'personalDetails', 'hidePlmnNumbers'], values)) {
      extSPs.push(PLMN_NUMBERS_SP);
    }
    return { protectedFields: SPs, extensionProtectedFields: extSPs };
  }

  createDetailsSectionUpdateUserPayload: *;

  createDetailsSectionUpdateUserPayload(
    values: InitialFormValuesT,
    selectedAssistants: UserListElementT[],
    selectedSubstitutes: UserListElementT[],
    selectedSuperiors: UserListElementT[],
    hasListsChanged: *
  ): { updated: boolean, user: InternalUserEntityT } {
    const { user, initialValues, currentUser, locations } = this.props;
    const updateUserBase: InternalUserEntityT = {
      id: user.id,
      personId: user.personId,
      enterpriseId: user.enterpriseId,
      userType: 'internalUser',
      login: user.login,
      addressNumber: user.addressNumber,
      locked: user.locked,
      isAcdAgent: false
    };

    const path = (suffix: string[]) => R.prepend('internalUserDetails', suffix);

    const hasChanges: boolean =
      !propIsEqual(path(['hideCallerId']), initialValues, values) ||
      !propIsEqual(path(['personalDetails', 'title']), initialValues, values) ||
      !propIsEqual(path(['personalDetails', 'officeReferenceID']), initialValues, values) ||
      this.hiddenFieldsChanged(initialValues, values) ||
      !propIsEqual(path(['personalDetails', 'nickName']), initialValues, values) ||
      !propIsEqual(path(['personalDetails', 'mobileNumber']), initialValues, values) ||
      !propIsEqual(path(['personalDetails', 'homeNumber']), initialValues, values) ||
      !propIsEqual(path(['personalDetails', 'emails']), initialValues, values) ||
      !propIsEqual(path(['personalDetails', 'professionalPostalAddress']), initialValues, values) ||
      !propIsEqual(
        path(['departmentAndColleagues', 'department', 'value']),
        initialValues,
        values
      ) ||
      !propIsEqual(path(['additionalInformation', 'duties']), initialValues, values) ||
      hasListsChanged() ||
      !propIsEqual(
        path(['additionalInformation', 'additionalExplanations']),
        initialValues,
        values
      ) ||
      !propIsEqual(path(['additionalInformation', 'additionalInfo']), initialValues, values) ||
      !propIsEqual(path(['additionalInformation', 'contactInformation']), initialValues, values) ||
      !propIsEqual(path(['additionalInformation', 'corporateUserId']), initialValues, values) ||
      !propIsEqual(path(['additionalInformation', 'costCenter']), initialValues, values) ||
      !propIsEqual(path(['additionalInformation', 'tagNames']), initialValues, values) ||
      !propIsEqual(
        path(['additionalInformation', 'accessCtrlSystemPersonId']),
        initialValues,
        values
      ) ||
      !propIsEqual(path(['userAccount', 'language']), initialValues, values) ||
      !propIsEqual(path(['voicemailSettings', 'isVoiceMailNoMessage']), initialValues, values) ||
      !propIsEqual(path(['voicemailSettings', 'voiceMailAudio', 'level']), initialValues, values) ||
      !propIsEqual(
        path(['voicemailSettings', 'voiceMailAudio', 'audioNameFileToImport']),
        initialValues,
        values
      ) ||
      !propIsEqual(
        path(['voicemailSettings', 'voiceMailAudio', 'greetingAudioToImport']),
        initialValues,
        values
      ) ||
      !propIsEqual(
        path(['voicemailSettings', 'voiceMailAudio', 'temporaryGreetingAudioToImport']),
        initialValues,
        values
      ) ||
      !propIsEqual(path(['voicemailSettings', 'startTimeInMillis']), initialValues, values) ||
      !propIsEqual(path(['voicemailSettings', 'endTimeInMillis']), initialValues, values);

    return {
      updated: hasChanges,
      user: {
        ...updateUserBase,
        ...(this.hiddenFieldsChanged(initialValues, values) ? this.hiddenFieldsData(values) : {}),
        ...simpleObjIfNonEqual(path(['hideCallerId']), initialValues, values),
        ...simpleObjIfNonEqual(path(['personalDetails', 'title']), initialValues, values),
        ...simpleObjIfNonEqual(
          path(['personalDetails', 'officeReferenceID']),
          initialValues,
          values
        ),
        ...simpleObjIfNonEqual(
          path(['personalDetails', 'nickName']),
          initialValues,
          values,
          undefined,
          true
        ),
        ...simpleObjIfNonEqual(path(['personalDetails', 'mobileNumber']), initialValues, values),
        ...simpleObjIfNonEqual(path(['personalDetails', 'homeNumber']), initialValues, values),
        ...(!R.equals(
          initialValues.internalUserDetails.personalDetails.emails,
          values.internalUserDetails.personalDetails.emails
        )
          ? {
              emails: (
                (values.internalUserDetails.personalDetails.emails || '').replace(' ', '') || ''
              )
                .split(',')
                .map(email => email.trim())
                .join(';')
            }
          : {}),
        // $FlowFixMe: could be optimized
        ...(!propIsEqual(
          path(['personalDetails', 'professionalPostalAddress', 'value']),
          initialValues,
          values
        ) && values.internalUserDetails.personalDetails.professionalPostalAddress != null
          ? ((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API')
            ? {
                // $FlowFixMe
                professionalPostalAddress:
                  R.path(
                    [
                      'internalUserDetails',
                      'personalDetails',
                      'professionalPostalAddress',
                      'value'
                    ],
                    values
                  ) !== '@NULL'
                    ? locations.find(
                        loc =>
                          loc.name ===
                          R.path(
                            [
                              'internalUserDetails',
                              'personalDetails',
                              'professionalPostalAddress',
                              'value'
                            ],
                            values
                          )
                      ).restUri
                    : null
              }
            : {
                // $FlowFixMe
                professionalPostalAddress: R.path(
                  ['internalUserDetails', 'personalDetails', 'professionalPostalAddress', 'value'],
                  values
                )
              }
          : {}),
        // $FlowFixMe
        ...(!propIsEqual(
          path(['departmentAndColleagues', 'department', 'value']),
          initialValues,
          values
        ) && values.internalUserDetails.departmentAndColleagues.department != null
          ? {
              departmentId: R.path(
                ['internalUserDetails', 'departmentAndColleagues', 'department', 'value'],
                values
              )
            }
          : {}),
        ...simpleObjIfNonEqual(
          path(['additionalInformation', 'duties']),
          initialValues,
          values,
          'duties',
          true
        ),
        assistants: selectedAssistants
          ? selectedAssistants.map(assistant => ({
              id: assistant.id,
              addressNumber: assistant.addressNumber || '',
              userName: assistant.userName
            }))
          : [],
        substitutes: selectedSubstitutes
          ? selectedSubstitutes.map(substitute => ({
              id: substitute.id,
              addressNumber: substitute.addressNumber || '',
              userName: substitute.userName
            }))
          : [],
        superiors: selectedSuperiors
          ? selectedSuperiors.map(superior => ({
              id: superior.id,
              addressNumber: superior.addressNumber || '',
              userName: superior.userName
            }))
          : [],
        ...simpleObjIfNonEqual(
          path(['additionalInformation', 'additionalExplanations']),
          initialValues,
          values,
          undefined,
          true
        ),
        ...simpleObjIfNonEqual(
          path(['additionalInformation', 'additionalInfo']),
          initialValues,
          values,
          undefined,
          true
        ),
        ...simpleObjIfNonEqual(
          path(['additionalInformation', 'contactInformation']),
          initialValues,
          values,
          undefined,
          true
        ),
        ...simpleObjIfNonEqual(
          path(['additionalInformation', 'corporateUserId']),
          initialValues,
          values,
          undefined,
          true
        ),
        ...simpleObjIfNonEqual(
          path(['additionalInformation', 'costCenter']),
          initialValues,
          values,
          undefined,
          true
        ),
        ...simpleObjIfNonEqual(
          path(['additionalInformation', 'accessCtrlSystemPersonId']),
          initialValues,
          values,
          undefined,
          true
        ),
        tagNames:
          !propIsEqual(path(['additionalInformation', 'tagNames']), initialValues, values) &&
          R.path(path(['additionalInformation', 'tagNames']), values) != null
            ? R.path(path(['additionalInformation', 'tagNames']), values).split(',')
            : R.path(path(['additionalInformation', 'tagNames']), initialValues)
            ? R.path(path(['additionalInformation', 'tagNames']), initialValues).split(',')
            : R.path(path(['additionalInformation', 'tagNames']), initialValues),
        ...simpleObjIfNonEqual(
          path(['userAccount', 'language', 'value']),
          initialValues,
          values,
          'language',
          true
        ),
        ...(values.internalUserDetails.voicemailSettings
          ? {
              userVoiceMailUpdate: {
                isVoiceMailNoMessage:
                  values.internalUserDetails.voicemailSettings.isVoiceMailNoMessage,
                voicemailSettings: {
                  audios: {
                    current: {
                      name:
                        values.internalUserDetails.voicemailSettings.voiceMailAudio.level ===
                          'TemporaryGreeting' &&
                        values.internalUserDetails.voicemailSettings.voiceMailAudio
                          .isTemporaryVoicemailActive
                          ? undefined
                          : values.internalUserDetails.voicemailSettings.voiceMailAudio.level,
                      endTimeInMillis:
                        values.internalUserDetails.voicemailSettings.voiceMailAudio.level ===
                        'TemporaryGreeting'
                          ? values.internalUserDetails.voicemailSettings.endTimeInMillis
                          : undefined
                    }
                  }
                }
              }
            }
          : {})
      }
    };
  }

  stopEditing: *;

  stopEditing() {
    const {
      user: { id }
    } = this.props;
    this.showUser(id);
  }

  startEditing: *;

  startEditing() {
    const {
      // eslint-disable-next-line no-shadow
      goToEditUser,
      user: { enterpriseId, id }
    } = this.props;
    goToEditUser(enterpriseId, id, 'internalUser');
  }

  saveUserAccount: *;

  async saveUserAccount(account: *) {
    const { user, currentUser } = this.props;
    const updatedUserAccount = await axios({
      method: 'PATCH',
      url: `/api/v2/enterprises/${user.enterpriseId}/useraccount/${user.personId}`,
      headers: createCsrfHeader(currentUser),
      data: account,
      validateStatus: status => status === HTTP.OK
    });
    this.updateUserAccount(updatedUserAccount.data);
  }

  handleSectionSave: *;

  async handleSectionSave(
    e: ?Event,
    values: InitialFormValuesT,
    selectedAssistants: UserListElementT[] = [],
    selectedSubstitutes: UserListElementT[] = [],
    selectedSuperiors: UserListElementT[] = [],
    hasListsChanged: * = () => {}
  ): Promise<void> {
    const { onSave, user, currentUser } = this.props;
    if (e) e.preventDefault();
    const uploadAudio = async (audioLevel, file) => {
      await uploadVoiceMailAudio(
        user.enterpriseId,
        user.id,
        audioLevel,
        'User',
        file,
        this.uploadAudioCancelTokenSource.token,
        createCsrfHeader(currentUser)
      );
    };

    if (values.internalUserDetails && values.internalUserDetails.voicemailSettings) {
      if (values.internalUserDetails.voicemailSettings.voiceMailAudio.audioNameFileToImport) {
        await uploadAudio(
          'AudioName',
          values.internalUserDetails.voicemailSettings.voiceMailAudio.audioNameFileToImport
        );
      }
      if (values.internalUserDetails.voicemailSettings.voiceMailAudio.greetingAudioToImport) {
        await uploadAudio(
          'Greeting',
          values.internalUserDetails.voicemailSettings.voiceMailAudio.greetingAudioToImport
        );
      }
      if (
        values.internalUserDetails.voicemailSettings.voiceMailAudio.temporaryGreetingAudioToImport
      ) {
        await uploadAudio(
          'TemporaryGreeting',
          values.internalUserDetails.voicemailSettings.voiceMailAudio.temporaryGreetingAudioToImport
        );
      }
    }

    if (
      ((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') &&
      this.state.userAccount !== null &&
      JSON.stringify(this.state.userAccount) !== this.state.originalUserAccount
    ) {
      await this.saveUserAccount(this.state.userAccount);
    }

    if (values.internalUserDetails.voicemailSettings.voiceMailAudio.removeTemporaryGreeting) {
      this.deleteTemporaryVoicemailSettings();
    } else if (
      values.internalUserDetails.voicemailSettings.voiceMailAudio.isTemporaryVoicemailActive
    ) {
      this.saveTemporaryVoicemailSettings(values);
    }

    const editedUser = this.createDetailsSectionUpdateUserPayload(
      values,
      selectedAssistants,
      selectedSubstitutes,
      selectedSuperiors,
      hasListsChanged
    );

    const userReturned = await onSave(editedUser.user);
    if (userReturned) {
      this.stopEditing();
    }
  }

  handleHiddenDirectorySave: *;

  async handleHiddenDirectorySave(e: ?Event, newVal: boolean): Promise<void> {
    const { onSave, user } = this.props;
    if (e) e.preventDefault();

    const payload: InternalUserEntityT = {
      id: user.id,
      personId: user.personId,
      enterpriseId: user.enterpriseId,
      userType: 'internalUser',
      locked: user.locked,
      isAcdAgent: false,
      hiddenDirectory: newVal
    };

    await onSave(payload);
  }

  handleUploadAvatar: *;

  async handleUploadAvatar(e: ?Event): Promise<void> {
    if (e) e.preventDefault();
    const { user, uploadAvatar, currentUser } = this.props;
    const { avatarToUpload } = this.state;
    if (avatarToUpload) {
      this.toggleUploadDialogLoading();
      const response = await uploadAvatar(
        user.enterpriseId,
        user.id,
        avatarToUpload,
        this.uploadAvatarCancelTokenSource.token,
        createCsrfHeader(currentUser)
      );
      if (response !== undefined) {
        this.setUrlPhoto(response.urlPhoto);
        this.setState({ isAvatarCustomized: true });
      }
      this.toggleUploadDialogLoading();
    }
    this.toggleUploadDialog();
  }

  handleDeleteAvatar: *;

  async handleDeleteAvatar(): Promise<void> {
    this.toggleUploadDialogLoading();
    const { user, deleteAvatar, currentUser } = this.props;
    const response = await deleteAvatar(
      user.enterpriseId,
      user.id,
      this.uploadAvatarCancelTokenSource.token,
      createCsrfHeader(currentUser)
    );
    if (response !== undefined) {
      this.setUrlPhoto('');
    }
    this.toggleUploadDialogLoading();
    this.toggleUploadDialog();
  }

  updateDropdownValue: *;

  updateDropdownValue(fieldName: string, value: *) {
    this.props.change(fieldName, {
      label: value.textContent,
      value: value.dataset.value
    });
  }

  renderHiddenDirectoryDialog = (): Element<*> => {
    const { t, user } = this.props;

    return (
      <div
        className={`ea-modal ea-modal--open styleguide-dialog-position ${styles['dialog-container']}`}
        role="dialog"
        aria-modal="true"
      >
        <div className="ea-modal__overlay" />
        <div id="dialog-content" className={`ea-modal__content ${styles['dialog-content']}`}>
          <Dismiss id="close-button" onClose={this.toggleHiddenDirectoryDialog} />
          <h2 className="ea-h3 ea-h3--thick">
            {t('editInternalUser.hiddenDirectoryDialog.title')}
          </h2>
          <div>
            <div>{t('editInternalUser.hiddenDirectoryDialog.description')}</div>
            <RadioGroup value={user.hiddenDirectory ? 'true' : 'false'}>
              <Radio
                id="hidden-directory-option-false"
                onChange={() => {
                  this.setHiddenDirectorySelection(false);
                }}
                label={t('callflow.hiddenDirectory.false')}
                value="false"
              />
              <Radio
                id="hidden-directory-option-true"
                label={t('callflow.hiddenDirectory.true')}
                onChange={() => {
                  this.setHiddenDirectorySelection(true);
                }}
                value="true"
              />
            </RadioGroup>
            <div className={styles['dialog-buttons']}>
              <CancelButton
                id="modal-cancel-button"
                label={t('users.personalDetails.cancel')}
                onClickAction={() => this.toggleHiddenDirectoryDialog()}
              />
              <ActionButton
                id="modal-confirm-button"
                label={t('users.personalDetails.save')}
                onClickAction={e => {
                  this.handleHiddenDirectorySave(e, this.state.hiddenDirectorySelection);
                  this.toggleHiddenDirectoryDialog();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPasswordResetDialog = (): Element<*> => {
    const {
      user,
      t,
      isUpdating,
      currentValues,
      resetSuccessIndicatorToggled,
      hasResetError
    } = this.props;
    const { selectedResetMethod, resetButtonClicked } = this.state;
    const isSmsSelected = selectedResetMethod === 'phoneNumber';
    const isEmailSelected = selectedResetMethod === 'email';
    const hasSelectedResetMethodFieldError =
      (isSmsSelected && this.hasSmsError()) || (isEmailSelected && this.hasEmailError());

    return (
      <div
        className={`ea-modal ea-modal--open styleguide-dialog-position ${styles['dialog-container']}`}
        role="dialog"
        aria-modal="true"
      >
        <div className="ea-modal__overlay" />
        <div id="dialog-content" className={`ea-modal__content ${styles['dialog-content']}`}>
          <Dismiss id="close-button" onClose={this.togglePasswordResetDialog} />
          <div>
            {renderPasswordResetSection(
              t,
              resetSuccessIndicatorToggled,
              isSmsSelected,
              isEmailSelected,
              isUpdating,
              hasResetError,
              resetButtonClicked,
              hasSelectedResetMethodFieldError,
              selectedResetMethod,
              this.handleSmsClick,
              this.handleEmailClick,
              event =>
                this.props.change(
                  'internalUserDetails.passwordReset.phoneNumber',
                  event.target.value
                ),
              event =>
                this.props.change('internalUserDetails.passwordReset.email', event.target.value),
              R.pathOr('', ['internalUserDetails', 'passwordReset', 'phoneNumber'], currentValues),
              R.pathOr('', ['internalUserDetails', 'passwordReset', 'email'], currentValues),
              user.locked,
              this.handleSendClick,
              this.togglePasswordResetDialog
            )}
          </div>
        </div>
      </div>
    );
  };

  renderPresenceStateDialog = (): Element<*> => {
    const { t, isUpdating } = this.props;

    return (
      <div
        className={`ea-modal ea-modal--open styleguide-dialog-position ${styles['dialog-container']}`}
        role="dialog"
        aria-modal="true"
      >
        <div className="ea-modal__overlay" />
        <div id="dialog-content" className={`ea-modal__content ${styles['dialog-content']}`}>
          <Dismiss id="close-button" onClose={this.togglePresenceStateDialog} />
          <div>
            <h2 id="presence-dialog-title" className="ea-h3 ea-h3--thick">
              {t('users.manualCustomPresenceState.label')}
            </h2>
            <div>&nbsp;</div>
            <Input
              id="manualCustomPresenceState-input"
              onValueChange={e => {
                this.setState({
                  userAccount: {
                    ...this.state.userAccount,
                    manualCustomPresenceState: e.target.value
                  }
                });
              }}
              defaultValue={
                this.state.userAccount ? this.state.userAccount.manualCustomPresenceState : ''
              }
              maxlength={MAX_MANUAL_CUSTOM_PRESENCE_STATE_CHARS}
              touched
              // TODO: ResizeObserver error without this fix
              type={isUpdating ? '' : 'textarea'}
              i18n_input_helpText={`${
                this.state.userAccount && this.state.userAccount.manualCustomPresenceState
                  ? this.state.userAccount.manualCustomPresenceState.length
                  : 0
              } / ${MAX_MANUAL_CUSTOM_PRESENCE_STATE_CHARS}`}
            />
            <div className={styles['dialog-buttons']}>
              <CancelButton
                id="modal-cancel-button"
                label={t('users.personalDetails.cancel')}
                onClickAction={() => this.togglePresenceStateDialog()}
              />
              <ActionButton
                id="modal-confirm-button"
                label={t('users.personalDetails.save')}
                onClickAction={() => {
                  this.saveUserAccount(this.state.userAccount);
                  this.togglePresenceStateDialog();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderUserInfo(): Element<*> {
    const {
      user,
      locations,
      languages,
      departmentIdTree,
      t,
      isUpdating,
      dirty,
      reset,
      hasError,
      fieldErrors,
      initialValues: { internalUserDetails },
      changeFormValue,
      goToEnterpriseUserForwardings, // eslint-disable-line no-shadow
      currentValues,
      user: { firstName = '', lastName = '' },
      showLoadingError,
      alwaysEdit,
      onClose,
      currentUser,
      directoryAvatar,
      goToEditAcdSupervisor // eslint-disable-line no-shadow
    } = this.props;

    const {
      successIndicatorToggled,
      forwardings,
      selectedSuperiors,
      foundSuperiors,
      selectedAssistants,
      foundAssistants,
      selectedSubstitutes,
      foundSubstitutes,
      showUploadDialog,
      uploadDialogLoading,
      urlPhoto,
      activeBarrings,
      isAvatarCustomized
    } = this.state;
    const errorMessage = !showLoadingError && hasError && dirty ? t('users.account.saveError') : '';
    const fullName = [firstName, lastName].join(' ').trim();
    const availability = getAvailability(user, t);

    return (
      <Section
        className={classnames(styles.info, {
          [`${styles['info--open']}`]: alwaysEdit
        })}
        key={`${user.id}-${DETAILS_SECTION}`}
        id={DETAILS_SECTION}
        isSaving={isUpdating}
        touched={dirty}
        wasSaved={successIndicatorToggled}
        errorMessage={errorMessage}
        onReset={reset}
        // $FlowFixMe
        sectionId="internalUserDetails"
        showEmptyMessage={false}
        showButtonsBottom={alwaysEdit}
        hideEdit
        alwaysEdit={alwaysEdit}
      >
        {this.state.showHiddenDirectoryDialog && this.renderHiddenDirectoryDialog()}
        {this.state.showPasswordResetDialog && this.renderPasswordResetDialog()}
        {this.state.showPresenceStateDialog && this.renderPresenceStateDialog()}
        <div className={classnames({ [`${styles['edit-area']}`]: alwaysEdit })}>
          <div className={styles['left-container']}>
            <div className={styles['avatar-container']}>
              {alwaysEdit && <h1>{t('editInternalUser.profile')}</h1>}
              <Avatar
                src={
                  (directoryAvatar && directoryAvatar.isAvatarCustomized) || isAvatarCustomized
                    ? urlPhoto
                    : null
                }
                name={fullName}
                availability={availability.icon}
                color="#0019AF"
                shadow
                size="large"
                hideWhenViewing
              />
              {alwaysEdit && user.hiddenDirectory && (
                <div className={styles['badge-container']}>
                  <Badge type="text" text={t('callflow.hiddenDirectory.true')} color="secondary" />
                </div>
              )}
              {alwaysEdit && renderAvailabilitySection(t, internalUserDetails.personalAvailability)}
            </div>
            <div className={styles['action-container']}>
              <div>
                <div>
                  <Button
                    id="open-avatar-upload-dialog-button"
                    color="link"
                    size="m"
                    hideWhenViewing
                    onClick={() => {
                      this.toggleUploadDialog();
                    }}
                  >
                    <IconCameraFilled size="m" />
                    {t('users.uploadAvatarDialog.title')}
                  </Button>
                </div>
                <div>
                  {((currentUser && currentUser.featureFlags) || []).includes(
                    'FEATURE-GENERIC-API'
                  ) && (
                    <Button
                      id="open-presence-state-dialog-button"
                      color="link"
                      size="m"
                      hideWhenViewing
                      onClick={() => {
                        this.togglePresenceStateDialog();
                      }}
                    >
                      <IconEditFilled size="m" />
                      {t('users.manualCustomPresenceState.label')}
                    </Button>
                  )}
                </div>
                <div>
                  {isAdmin(currentUser) && (
                    <Button
                      id="open-password-reset-dialog-button"
                      color="link"
                      size="m"
                      hideWhenViewing
                      onClick={() => {
                        this.togglePasswordResetDialog();
                      }}
                    >
                      <IconPukFilled size="m" />
                      {t('editInternalUser.changePasswordButton')}
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    id="open-hiddendirectory-dialog-button"
                    color="link"
                    size="m"
                    hideWhenViewing
                    onClick={() => {
                      this.toggleHiddenDirectoryDialog();
                    }}
                  >
                    <IconViewOffFilled size="m" />
                    {t('editInternalUser.hiddenDirectoryDialog.title')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={classnames({ [`${styles['field-area']}`]: alwaysEdit })}>
            {showUploadDialog &&
              renderUploadAvatarDialog(
                t,
                uploadDialogLoading,
                false,
                this.toggleUploadDialog,
                this.handleUploadAvatar,
                this.handleDeleteAvatar,
                file => this.setAvatarToUpload(file),
                (directoryAvatar && directoryAvatar.isAvatarCustomized) || isAvatarCustomized
                  ? urlPhoto
                  : null
              )}
            {!alwaysEdit && isAdmin(currentUser) && renderLockedSection(user, onClose)}
            {!alwaysEdit && renderAvailabilitySection(t, internalUserDetails.personalAvailability)}
            <Accordion className={styles['accordion-header']}>
              <AccordionItem
                id="contactInfoTitle"
                key="contactInfoTitle"
                heading={
                  <div>
                    {t('users.personalDetails.contactInfoTitle')}{' '}
                    {user.userProtectedFields &&
                      [
                        'superiors',
                        'homeNumbers',
                        'mobileNumbers',
                        'nickName',
                        'emails',
                        'pstnNumbers',
                        'plmnNumbers',
                        'professionalPostalAddresses'
                      ].some(r => user.userProtectedFields.includes(r)) && (
                        <IconLockRegular size="s" />
                      )}
                  </div>
                }
              >
                {renderPersonalDetailsSection(
                  t,
                  internalUserDetails.personalDetails,
                  locations,
                  currentValues,
                  this.validateNickname,
                  this.validateTitle,
                  this.validateMobileNumber,
                  this.validateEmail,
                  this.validateOfficeReferenceID,
                  !!alwaysEdit,
                  user,
                  this.updateDropdownValue
                )}
              </AccordionItem>
              {((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') &&
                this.state.userAccount && (
                  <AccordionItem
                    id="customCallerID"
                    key="customCallerID"
                    heading={t('users.customCallerID.sectionTitle')}
                  >
                    <CustomCallerID
                      updateUserAccount={this.updateUserAccount} // eslint-disable-line no-return-assign
                      enterpriseId={user.enterpriseId}
                      hideCallerId={user.hideCallerId}
                      extensionId={user.id}
                      isSectionInEditMode={alwaysEdit}
                      userAccount={this.state.userAccount}
                      saveUserAccount={this.saveUserAccount}
                    />
                  </AccordionItem>
                )}
              <AccordionItem
                id="departmentAndColleagues"
                key="departmentAndColleagues"
                heading={
                  <div>
                    {t('users.departmentAndColleagues.sectionTitle')}{' '}
                    {user.userProtectedFields &&
                      ['location', 'superiors', 'assistants', 'substitutes'].some(r =>
                        user.userProtectedFields.includes(r)
                      ) && <IconLockRegular size="s" />}
                  </div>
                }
              >
                {renderDepartmentAndColleaguesSection(
                  t,
                  departmentIdTree,
                  this.showDepartment,
                  selectedSuperiors,
                  foundSuperiors,
                  selectedAssistants,
                  foundAssistants,
                  selectedSubstitutes,
                  foundSubstitutes,
                  this.handleSearchInputChanged,
                  this.handleAddToSelectedList,
                  changeFormValue,
                  this.showUser,
                  this.superiorsInput,
                  this.assistantsInput,
                  this.substitutesInput,
                  this.removeUserFromList,
                  currentValues,
                  !!alwaysEdit,
                  user
                )}
              </AccordionItem>
              <AccordionItem
                id="additionalInformation"
                key="additionalInformation"
                heading={
                  <div>
                    {t('users.additionalInformation.sectionTitle')}{' '}
                    {user.userProtectedFields &&
                      [
                        'additionalExplanations',
                        'additionalInfo',
                        'contactInformation',
                        'corporateUserId',
                        'costCenter',
                        'accessCtrlSystemPersonId',
                        'duties'
                      ].some(r => user.userProtectedFields.includes(r)) && (
                        <IconLockRegular size="s" />
                      )}
                  </div>
                }
              >
                {renderAdditionalInformationSection(
                  t,
                  currentValues,
                  this.validateAdditionalExplanations,
                  this.validateAdditionalInfo,
                  this.validateContactInformation,
                  this.validateCorporateUserId,
                  this.validateCostCenter,
                  this.validateAccessCtrlSystemPersonId,
                  this.validateTagNames,
                  this.validateDuties,
                  changeFormValue,
                  !!alwaysEdit,
                  user
                )}
              </AccordionItem>
              {!((currentUser && currentUser.featureFlags) || []).includes(
                'FEATURE-HIDE-BARRINGS-SECTION'
              ) && (
                <AccordionItem
                  id="barrings"
                  key="barrings"
                  heading={t('users.barrings.sectionTitle')}
                >
                  {t('users.barrings.activeBarrings', {
                    barrings: activeBarrings.join(', ')
                  })}
                </AccordionItem>
              )}
              <AccordionItem
                id="personalCallForwardings"
                key="personalCallForwardings"
                heading={t('users.personalCallForwardings.forwardingsTitle')}
              >
                {renderPersonalCallForwardingsSection(
                  t,
                  goToEnterpriseUserForwardings,
                  user,
                  forwardings,
                  currentValues,
                  alwaysEdit
                )}
              </AccordionItem>
              <AccordionItem
                id="accountTitle"
                key="accountTitle"
                heading={t('users.account.accountTitle')}
              >
                {renderUserAccountSection(
                  t,
                  user,
                  languages,
                  currentValues,
                  this.updateDropdownValue
                )}
              </AccordionItem>
              {isAdmin(currentUser) && (
                <AccordionItem
                  id="voicemailSettings"
                  key="voicemailSettings"
                  heading={t('voicemailSettings.title')}
                >
                  {renderVoiceMailSettings(
                    t,
                    user.voicemailSettings,
                    this.state.temporaryVoicemailSettings
                  )}
                </AccordionItem>
              )}
              {user.isAcdSupervisor && (
                <AccordionItem
                  id="acdSettings"
                  key="acdSettings"
                  heading={t('users.acdSettingsTitle')}
                >
                  {renderACDSettings(t, user, goToEditAcdSupervisor)}
                </AccordionItem>
              )}
            </Accordion>
            <div className={styles['button-area']}>
              <CancelButton
                id="user-cancel-button"
                className={styles['cancel-button']}
                label={t('users.personalDetails.cancel')}
                onClickAction={this.stopEditing}
                hideWhenViewing
              />
              <ActionButton
                id="user-save-button"
                className={styles['save-button']}
                label={t('users.personalDetails.save')}
                loading={isUpdating}
                disabled={!R.isEmpty(fieldErrors)}
                hideWhenViewing
                onClickAction={(e: ?Event): void => {
                  this.handleSectionSave(
                    e,
                    currentValues,
                    selectedAssistants,
                    selectedSubstitutes,
                    selectedSuperiors,
                    this.hasListsChanged
                  );
                }}
              />
            </div>
          </div>
        </div>
      </Section>
    );
  }

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  render(): Element<'form'> {
    const {
      t,
      onClose,
      user: { title = '', firstName = '', lastName = '' },
      user,
      hasError,
      showLoadingSpinner,
      showFixedTitle,
      isFavourite,
      onToggleFavourite,
      showLoadingError,
      reloadFunc,
      alwaysEdit,
      directoryAvatar,
      numberOfFavourites
    } = this.props;
    const fullName = [firstName, lastName].join(' ').trim();
    return (
      <form
        onKeyPress={e => {
          // eslint-disable-next-line no-unused-expressions
          e.key === 'Enter' && e.preventDefault();
        }}
      >
        <div
          className={classnames(styles.container, {
            [`${styles['container--open']}`]: alwaysEdit
          })}
        >
          {showFixedTitle && !alwaysEdit && (
            <FixedTitle
              fullName={fullName}
              onClose={onClose}
              isFavourite={isFavourite}
              onToggleFavourite={onToggleFavourite}
              handleOnEditClick={this.startEditing}
              disableButtons={showLoadingSpinner}
              disableFavouriteButton={numberOfFavourites >= MAX_FAVOURITES_RESULTS}
            />
          )}
          {!alwaysEdit && (
            <Title
              isInternalUser
              onClose={onClose}
              fullName={fullName}
              showFixedTitle={showFixedTitle}
              showLoadingSpinner={showLoadingSpinner}
              wasUnsuccessFullyUpdated={this.wasUnsuccessFullyUpdated}
              hasError={hasError}
              dataFetchFailedErrorMessage={t(
                'users.personalDetails.error.dataFetchFailedErrorMessage'
              )}
              defaultUserName={t('generic.defaultUserName')}
              title={title}
              isFavourite={isFavourite}
              onToggleFavourite={onToggleFavourite}
              handleOnEditClick={this.startEditing}
              disableButtons={showLoadingSpinner}
              disableFavouriteButton={numberOfFavourites >= MAX_FAVOURITES_RESULTS}
              user={user}
              directoryAvatar={directoryAvatar}
              translate={t}
            />
          )}
          {showLoadingError && <UserLoadingError reloadFunc={reloadFunc} translate={t} />}
          {(!hasError || this.wasUnsuccessFullyUpdated) && (
            <ErrorBoundary
              errorElement={
                <TextError message={t('users.personalDetails.error.genericErrorMessage')} />
              }
            >
              {this.renderUserInfo()}
            </ErrorBoundary>
          )}
        </div>
      </form>
    );
  }
}

type MapUserDataToFieldValuesFnT = (InternalUserStateEntityT, *, *) => InitialFormValuesT;
export const mapUserDataToFieldValues: MapUserDataToFieldValuesFnT = (
  {
    hideCallerId,
    firstName,
    lastName,
    addressNumber,
    plmnNumbers,
    pstnNumbers,
    emails,
    forwardings,
    login,
    department,
    title,
    officeReferenceID,
    mobileNumber,
    homeNumber,
    nickName,
    duties,
    additionalExplanations,
    additionalInfo,
    contactInformation,
    corporateUserId,
    costCenter,
    accessCtrlSystemPersonId,
    language,
    professionalPostalAddress,
    presenceState,
    telephonicState,
    voicemailSettings,
    tagNames,
    protectedFields,
    userProtectedFields,
    statusMessage
  },
  translate,
  languages
) => {
  const activeLang = R.find(R.propEq('code', language || 'en'), languages || []);
  let firstMobileNumber;
  if (plmnNumbers && plmnNumbers.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    firstMobileNumber = plmnNumbers[0];
  }
  const currentlyActiveFile =
    R.path(['audios', 'current', 'name'], voicemailSettings) || 'PhoneNumber';

  return {
    internalUserDetails: {
      firstName,
      lastName,
      hideCallerId,
      personalAvailability: {
        availability: getAvailabilityInfo(presenceState, telephonicState, translate).text,
        customPresenceState: statusMessage
      },
      personalDetails: {
        nickName: nickName || '',
        addressNumber: addressNumber || '',
        title: title || '',
        officeReferenceID: officeReferenceID || '',
        mobileNumber: mobileNumber || '',
        homeNumber: homeNumber || '',
        pstnNumbers: (pstnNumbers || []).join(', '),
        plmnNumbers: (plmnNumbers || []).join(', '),
        hidePstnNumbers: protectedFields ? protectedFields.includes(PSTN_NUMBERS_SP) : false,
        hidePlmnNumbers: protectedFields ? protectedFields.includes(PLMN_NUMBERS_SP) : false,
        emails: emails
          ? emails
              .split(';')
              .join(', ')
              .replace(/(^[,\s]+)|([,\s]+$)/g, '') // trims leading and trailing commas
          : '',
        hideEmails: userProtectedFields ? userProtectedFields.includes(EMAILS_SP) : false,
        hideNickname: userProtectedFields ? userProtectedFields.includes(NICKNAME_SP) : false,
        hideMobileNumber: userProtectedFields
          ? userProtectedFields.includes(MOBILE_NUMBERS_SP)
          : false,
        hideHomeNumber: userProtectedFields ? userProtectedFields.includes(HOME_NUMBERS_SP) : false,
        professionalPostalAddress: {
          value: professionalPostalAddress || NO_VALUE_FIELD_ID,
          label:
            professionalPostalAddress ||
            translate('users.personalDetails.noProfessionalPostalAddress')
        },
        hideProfessionalPostalAddress: userProtectedFields
          ? userProtectedFields.includes(PROFESSIONAL_POSTAL_ADDRESS_SP)
          : false
      },
      departmentAndColleagues: {
        department:
          department && department.id && department.name
            ? {
                value: department.id,
                label: department.name
              }
            : null,
        hideLocation: userProtectedFields ? userProtectedFields.includes(LOCATION_SP) : false,
        superiors: null,
        hideSuperiors: protectedFields ? protectedFields.includes(SUPERIORS_SP) : false,
        assistants: null,
        hideAssistants: protectedFields ? protectedFields.includes(ASSISTANTS_SP) : false,
        substitutes: null,
        hideSubstitutes: protectedFields ? protectedFields.includes(SUBSTITUTES_SP) : false
      },
      additionalInformation: {
        additionalExplanations,
        additionalInfo,
        contactInformation,
        corporateUserId,
        costCenter,
        accessCtrlSystemPersonId,
        hideCorporateUserId: userProtectedFields
          ? userProtectedFields.includes(CORPORATE_USER_ID_SP)
          : false,
        hideContactInformation: userProtectedFields
          ? userProtectedFields.includes(CONTACT_INFORMATION_SP)
          : false,
        hideCostCenter: userProtectedFields ? userProtectedFields.includes(COST_CENTER_SP) : false,
        hideAccessCtrlSystemPersonId: userProtectedFields
          ? userProtectedFields.includes(ACCESS_CTRL_SYSTEM_PERSON_ID_SP)
          : false,
        hideAdditionalExplanations: userProtectedFields
          ? userProtectedFields.includes(ADDITIONAL_EXPLANATIONS_SP)
          : false,
        hideAdditionalInfo: userProtectedFields
          ? userProtectedFields.includes(ADDITIONAL_INFO_SP)
          : false,
        duties,
        hideDuties: userProtectedFields ? userProtectedFields.includes(DUTIES_SP) : false,
        tagNames: tagNames ? tagNames.join(',') : ''
      },
      personalCallForwardings: formPersonalCallForwardings(forwardings, translate),
      userAccount: {
        login,
        language: {
          value: language || 'en',
          label: activeLang ? activeLang.name : 'English'
        }
      },
      passwordReset: {
        phoneNumber: firstMobileNumber || '',
        email: emails ? emails.split(';')[0] : ''
      },
      voicemailSettings: {
        isVoiceMailNoMessage: voicemailSettings ? voicemailSettings.isVoiceMailNoMessage : true,
        startTimeInMillis: undefined,
        endTimeInMillis: voicemailSettings?.audios?.temporaryGreeting.endTimeInMillis,
        voiceMailAudio: {
          level: currentlyActiveFile,
          audioNameFileToImport: undefined,
          greetingAudioToImport: undefined,
          temporaryGreetingAudioToImport: undefined,
          isTemporaryVoicemailActive: false
        }
      }
    }
  };
};

export const decorateUserDepartmentWithName = (
  user: InternalUserStateEntityT,
  state: StoreStateT
): InternalUserStateEntityT => ({
  ...user,
  ...(user.department != null &&
  user.department.id != null &&
  !(user.department.name != null) &&
  user.department.id in state.entities.department.byId
    ? {
        department: {
          id: user.department.id,
          name: state.entities.department.byId[user.department.id].name
        }
      }
    : {})
});

const mapStateToProps = (state: StoreStateT, { userId, t }: PropsT) => {
  const user: InternalUserStateEntityT = (state.entities.user.byId[userId]: any); // eslint-disable-line flowtype/no-weak-types
  return {
    initialValues: mapUserDataToFieldValues(
      decorateUserDepartmentWithName(user, state),
      t,
      state.config.languages
    ),
    currentValues: getFormValues('internalUserDetails')(state),
    fieldErrors: getFormSyncErrors('internalUserDetails')(state),
    user,
    directoryAvatar: state.entities.directory.avatarById[`IUser-${user.personId}`],
    isUpdating: userSelectors.isUpdating(state, user.id),
    hasError: userSelectors.hasError(user),
    languages: state.config.languages,
    hasResetError: userSelectors.hasPasswordResetErrorById(state, user.id),
    usersWithUserName: state.entities.user.allIds
      .map(id => state.entities.user.byId[id])
      .filter(R.propEq('enterpriseId', user.enterpriseId))
      .filter(R.propEq('userType', 'internalUser'))
      .filter(R.propSatisfies(R.complement(R.isNil), 'addressNumber'))
      .filter(R.propSatisfies(R.complement(R.isNil), 'userName')),
    departmentIdTree: state.entities.department.idTrees[user.enterpriseId] || [],
    locations: selectLocations.byEnterpriseId(state, user.enterpriseId),
    isFavourite: selectConfig.hasFavouriteConfig(state, 'internal', user.enterpriseId, user.id),
    numberOfFavourites: selectConfig.selectFavouriteConfigsAsUsers(state, user.enterpriseId).length,
    resetSuccessIndicatorToggled:
      notificationUiSelectors.byTags(state, ['internal-user-password-deleted']).length > 0,
    formInitialValues: mapUserDataToFieldValues(
      decorateUserDepartmentWithName(user, state),
      t,
      state.config.languages
    ),
    currentUser: state.currentUser
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      goToDepartment,
      fetchLanguages: configOps.getLanguages,
      searchDirectory: directoryOps.searchDirectory,
      retrieveDepartments: departmentOps.retrieveCollection,
      retrieveLocations: locationOps.retrieveCollection,
      changeFormValue: change,
      goToUserDetails,
      goToEditUser,
      goToEnterpriseUserForwardings,
      goToEditAcdSupervisor,
      retrieveForwardings: userOps.retrieveForwardings,
      resetPassword: userOps.resetPassword,
      retrieveAvatar: userOps.retrieveAvatar,
      notify: notificationActions.createCreateNotificationAction,
      uploadAvatar: userOps.uploadAvatar,
      deleteAvatar: userOps.deleteAvatar
    },
    dispatch
  );
};

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'internalUserDetails',
    enableReinitialize: true
  })
)(InternalUserDetails);
