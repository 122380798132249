/* eslint-disable flowtype/no-weak-types */
/* eslint-disable no-unused-vars */
// @flow

import React, { type Element, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosPromise } from 'axios';
import type { Canceler } from 'axios';
import * as yup from 'yup';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button } from '@design-system/component-library';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import Input from '@design-system/component-library/src/components/Input';
import IconDeleteRegular from '@design-system/component-library/src/components/Icon/lib/IconDeleteRegular';
import IconEditRegular from '@design-system/component-library/src/components/Icon/lib/IconEditRegular';
import BaseContainer from '../../BaseContainer/BaseContainer';
import {
  goToCalendarTemplates,
  goToEditCalendarTemplate,
  goToEditAndAttachCalendarTemplate,
  goToCallFlowCalendar
} from '../../../navigationOperations';
import GoBackLink from '../../../components/Button/GoBackLink';
import EditCallflowDetails from '../components/edit/EditCallflowDetails';
import { actions as notificationActions } from '../../../ducks/ui/notification';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';
import TopNavigation from '../../navigation/TopNavigation';
import { findTitleForDate, filterVisibleDates } from './calendarTemplateUtil';
import InputField from '../components/edit/children/InputField';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import GenericError from '../../../components/Error/GenericError';
import Dismiss from '../../../components/Button/Dismiss';
import Dialog from '../../../components/Dialog';
import OpeningHoursDialog from '../components/OpeningHoursDialog/OpeningHoursDialog';
import AddHolidaysDialog from './AddHolidaysDialog';
import UsageDialog from './UsageDialog';
import DateTable from './DateTable';

import styles from './EditCalendarTemplate.module.scss';

export type PropsT = {|
  match: {
    params: {
      id: string,
      templateId: string,
      attachToCalendarId?: string
    }
  }
|};

const EditCalendarTemplate = (props: PropsT): Element<typeof BaseContainer> => {
  const { id: enterpriseId, templateId, attachToCalendarId } = props.match.params;
  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();
  const updateCalendarCanceller = React.useRef<Canceler>();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [datePickerSelectedDate, setDatePickerSelectedDate] = useState(null);
  const [calendarData, setCalendarData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [showAddHolidaysDialog, setShowAddHolidaysDialog] = useState(false);
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showDeleteDateDialog, setShowDeleteDateDialog] = useState(null);
  const [bankHolidays, setBankHolidays] = useState([]);
  const [newName, setNewName] = useState('');

  const isNew = () => {
    return templateId === 'create';
  };

  const fetchData = async () => {
    try {
      const response: AxiosPromise = await axios({
        method: 'GET',
        url: `/api/v1/config/bank_holidays/${currentUser.country || 'fi'}`
      });
      if (!response || !response.data) {
        throw new Error('Error getting holiday data');
      }
      setBankHolidays(response.data);

      if (isNew()) {
        // $FlowFixMe
        setCalendarData({
          name: '',
          dates: []
        });
      } else {
        const cResponse: AxiosPromise = await axios({
          method: 'GET',
          url: `/api/v1/enterprises/${enterpriseId}/calendartemplates/${templateId}`
        });
        if (!cResponse || !cResponse.data) {
          throw new Error('Error getting calendar data');
        }
        const cdata = cResponse.data;
        cdata.dates = cdata.effectiveDates.map(s => {
          return {
            ...s,
            title: findTitleForDate(s.date, response.data)
          };
        });
        cdata.dates = filterVisibleDates(cdata.dates);
        setCalendarData(cdata);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.error('Error', error.message);
      }
      setError(true);
    }
    setIsLoading(false);
  };

  const navigateOut = () => {
    if (attachToCalendarId) {
      dispatch(goToCallFlowCalendar(enterpriseId, attachToCalendarId));
    } else {
      dispatch(goToCalendarTemplates(enterpriseId));
    }
  };

  const isInUse = () => {
    return (
      calendarData &&
      calendarData.usedInCalendars != null &&
      calendarData.usedInCalendars.length > 0
    );
  };

  const saveDate = async (date, refresh = true) => {
    const payload = date;
    let response: AxiosPromise;
    setIsLoading(true);

    try {
      const method = date.identifier ? 'PATCH' : 'POST';
      response = await axios({
        method,
        url: `/api/v1/enterprises/${enterpriseId}/calendartemplates/${templateId}/dates`,
        data: payload,
        headers: createCsrfHeader(currentUser)
      });
    } catch (error) {
      console.error(error);
    }

    const success = !!response;
    if (refresh) fetchData();
  };

  const deleteDate = async date => {
    let response: AxiosPromise;
    setIsLoading(true);
    try {
      response = await axios({
        method: 'DELETE',
        url: `/api/v1/enterprises/${enterpriseId}/calendartemplates/${templateId}/dates/${date.identifier}`,
        headers: createCsrfHeader(currentUser)
      });
    } catch (error) {
      console.error(error);
    }
    const success = !!response;
    fetchData();
    setShowDeleteDateDialog(null);
  };

  const editDate = dateEntry => {
    setDatePickerSelectedDate(dateEntry);
    setIsDatePickerVisible(true);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const calendarTopic = (
    <>
      <GoBackLink
        id="callflow-page-back-link"
        text={t('tours.common.back')}
        onClick={() => navigateOut()}
      />
      <div className={styles['subheader-container']}>
        <div>
          {isNew() ? t('calendars.templates.createTitle') : t('calendars.templates.editTitle')}
        </div>
      </div>
    </>
  );

  const onSaveCalendar = async (name: string): Promise<void> => {
    const payload = {
      enterpriseId,
      name
    };

    let response: AxiosPromise;

    try {
      if (isNew()) {
        response = await axios({
          method: 'POST',
          url: `/api/v1/enterprises/${enterpriseId}/calendartemplates`,
          data: payload,
          headers: createCsrfHeader(currentUser)
        });
      } else {
        // $FlowFixMe
        payload.id = templateId;

        response = await axios({
          method: 'PATCH',
          url: `/api/v1/enterprises/${enterpriseId}/calendartemplates/${templateId}`,
          data: payload,
          headers: createCsrfHeader(currentUser)
        });
      }
    } catch (error) {
      console.error(error);
    }

    const success = !!response;
    dispatch(
      notificationActions.createCreateNotificationAction({
        tag: success ? `calendar-edit-success` : `calendar-edit-fail`,
        duration: 15000,
        type: success ? 'info' : 'error',
        message: success
          ? t('calendars.templates.saveSuccessNotification')
          : t('calendars.templates.saveFailureNotification')
      })
    );

    const newId = response?.data?.id;

    if (!isNew() || !newId) {
      if (attachToCalendarId) {
        try {
          const cResponse: AxiosPromise = await axios({
            method: 'GET',
            url: `/api/v1/enterprises/${enterpriseId}/calendarconfigs/byplatformid/${attachToCalendarId}`
          });
          if (!cResponse || !cResponse.data) {
            throw new Error('Error getting calendar data');
          }
          const calendarConfigId = cResponse.data.id;

          await axios({
            method: 'PATCH',
            url: `/api/v1/enterprises/${enterpriseId}/calendarconfigs/${calendarConfigId}`,
            data: { calendarTemplateId: templateId || null },
            headers: createCsrfHeader(currentUser)
          });
        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
    } else if (attachToCalendarId) {
      dispatch(goToEditAndAttachCalendarTemplate(enterpriseId, `${newId}`, attachToCalendarId));
    } else {
      dispatch(goToEditCalendarTemplate(enterpriseId, `${newId}`));
    }
  };

  const deleteCalendar = async () => {
    if (isNew()) return;

    const response: AxiosPromise = await axios({
      method: 'DELETE',
      url: `/api/v1/enterprises/${enterpriseId}/calendartemplates/${templateId}`,
      headers: createCsrfHeader(currentUser)
    });

    const success = !!response;
    dispatch(
      notificationActions.createCreateNotificationAction({
        tag: success ? `calendar-delete-success` : `calendar-delete-fail`,
        duration: 15000,
        type: success ? 'info' : 'error',
        message: success
          ? t('calendars.templates.deleteSuccessNotification')
          : t('calendars.templates.deleteFailureNotification')
      })
    );
    navigateOut();
  };

  return (
    <BaseContainer header={<TopNavigation />} containerStyle={styles.container}>
      {calendarTopic}
      {isError && <GenericError message={t('efax.efaxListFailedMsg')} />}
      {isLoading && (
        <CenterHorizontally>
          <LoadingSpinner />
        </CenterHorizontally>
      )}
      {calendarData && (
        <>
          <div className={styles.contassiner}>
            <div className={styles['form-container']}>
              <div className={styles['form-section']}>
                {isNew() ? (
                  <Input
                    id="calendar-name-input"
                    type="text"
                    label={t('calendars.editCalendarForm.nameDescription')}
                    onValueChange={e => {
                      // $FlowFixMe
                      setCalendarData({ ...calendarData, name: e.target.value });
                    }}
                    optional
                    i18n_input_optionalText=""
                    maxlength={107}
                  />
                ) : (
                  <h2>
                    {calendarData.name}
                    <Button
                      color="link"
                      onClick={() => {
                        setShowRenameDialog(true);
                      }}
                    >
                      <IconEditRegular />
                    </Button>
                  </h2>
                )}
              </div>
              {!isNew() && (
                <>
                  <DateTable
                    editable
                    templateName=""
                    dates={calendarData.dates}
                    onEdit={d => {
                      editDate(d);
                    }}
                    onDelete={d => {
                      setShowDeleteDateDialog(d);
                    }}
                  />

                  <div className={styles['form-section']}>
                    <div className={styles['flex-container']}>
                      <div>
                        <div className={styles['section-title']}>
                          {t('calendars.templates.includeBankHolidaysTitle')}
                        </div>
                        <div className={styles['info-text']}>
                          {t('calendars.templates.bankHolidaysInfoText')}
                        </div>
                        <Button
                          size="m"
                          color="light"
                          onClick={() => {
                            setShowAddHolidaysDialog(true);
                          }}
                        >
                          {t('calendars.templates.includeBankHolidaysButton')}
                        </Button>
                      </div>
                      <div>
                        <div className={styles['section-title']}>
                          {t('calendars.templates.singleDateTitle')}
                        </div>
                        <div className={styles['info-text']}>
                          {t('calendars.templates.singleDateInfoText')}
                        </div>
                        <Button
                          size="m"
                          color="light"
                          onClick={() => {
                            editDate(null);
                          }}
                        >
                          {t('calendars.templates.singleDateButton')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div>
              {isDatePickerVisible && (
                <OpeningHoursDialog
                  title={t('calendars.templates.addDateDialogTitle')}
                  holidays={bankHolidays}
                  dates={calendarData?.dates}
                  initialDate={datePickerSelectedDate}
                  onCancel={() => {
                    setIsDatePickerVisible(false);
                    setDatePickerSelectedDate(null);
                  }}
                  onConfirm={val => {
                    saveDate(val);
                    setIsDatePickerVisible(false);
                    setDatePickerSelectedDate(null);
                  }}
                  onDelete={val => {
                    setShowDeleteDateDialog(val);
                  }}
                />
              )}

              {showAddHolidaysDialog ? (
                <AddHolidaysDialog
                  onCancel={() => {
                    setShowAddHolidaysDialog(false);
                  }}
                  onConfirm={async (selectedHolidays, openingHoursDefintion) => {
                    const startDate = Moment().format('YYYY-MM-DD');
                    const endDate = Moment()
                      .add(1, 'y')
                      .format('YYYY-MM-DD');

                    const nextYearHolidays = bankHolidays.filter(h => {
                      return h.date >= startDate && h.date <= endDate;
                    });

                    if (selectedHolidays.includes('all_official')) {
                      selectedHolidays.splice(selectedHolidays.indexOf('all_official'), 1);
                      nextYearHolidays.forEach(h => {
                        if (h.official) {
                          selectedHolidays.push(h.name);
                        }
                      });
                    }

                    const newDates = [];
                    selectedHolidays.forEach(sh => {
                      const dt = nextYearHolidays.find(h => h.name === sh);
                      if (!dt) {
                        console.error(`no holiday found for name ${sh}`);
                      } else {
                        const newDateEntry = {
                          date: Moment(dt.date ? dt.date : null).format('YYYY-MM-DD'),
                          identifier: null,
                          slots: openingHoursDefintion.closedAllDay
                            ? null
                            : openingHoursDefintion.slots,
                          closedAllDay: openingHoursDefintion.closedAllDay,
                          repeatsYearly: false,
                          repeatsYearlyAtHoliday: openingHoursDefintion.repeats ? sh : null
                        };
                        newDates.push(newDateEntry);
                      }
                    });

                    await Promise.all(newDates.map(d => saveDate(d, false)));
                    fetchData();
                    setShowAddHolidaysDialog(false);
                  }}
                />
              ) : null}

              {showRenameDialog ? (
                <div
                  className={`ea-modal ea-modal--open styleguide-dialog-position ${styles['dialog-container']}`}
                  role="dialog"
                  aria-modal="true"
                >
                  <div className="ea-modal__overlay" />
                  <div
                    id="dialog-content"
                    className={`ea-modal__content ${styles['dialog-content']}`}
                  >
                    <Dismiss
                      id="close-button"
                      onClose={() => {
                        setShowRenameDialog(false);
                      }}
                    />
                    <div>
                      <h2 id="presence-dialog-title" className="ea-h3 ea-h3--thick">
                        {t('calendars.editCalendarForm.nameDescription')}
                      </h2>
                      <div>&nbsp;</div>
                      <Input
                        id="manualCustomPresenceState-input"
                        onValueChange={e => {
                          setNewName(e.target.value);
                        }}
                        defaultValue={calendarData.name}
                        maxlength={107}
                      />
                      <div className={styles['dialog-buttons']}>
                        <Button
                          id="modal-cancel-button"
                          color="link"
                          onClick={() => {
                            setShowRenameDialog(false);
                          }}
                        >
                          {t('calendars.templates.dialogActions.cancel')}
                        </Button>
                        <Button
                          id="modal-confirm-button"
                          disabled={!newName || calendarData.name === newName}
                          color="primary"
                          onClick={() => {
                            setIsLoading(true);
                            onSaveCalendar(newName);
                            setShowRenameDialog(false);
                          }}
                        >
                          {t('calendars.templates.dialogActions.save')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {showDeleteDateDialog ? (
                <Dialog
                  onCancel={() => setShowDeleteDateDialog(null)}
                  onConfirm={() => {
                    deleteDate(showDeleteDateDialog);
                  }}
                  onClose={() => {
                    setShowDeleteDateDialog(null);
                  }}
                  title={t('calendars.templates.deleteDateDialogTitle')}
                  description={`${Moment(showDeleteDateDialog.date).format('D.M.YYYY')} ${
                    showDeleteDateDialog.title ? t(`holidays.${showDeleteDateDialog.title}`) : ''
                  }
                    ${
                      showDeleteDateDialog.closedAllDay
                        ? t('callflows.details.closed')
                        : showDeleteDateDialog.slots.map(s => {
                            return `${s.startTime.substring(0, 5)}-${s.endTime.substring(0, 5)}`;
                          })
                    }
                    ${
                      showDeleteDateDialog.repeatsYearly ||
                      showDeleteDateDialog.repeatsYearlyAtHoliday
                        ? `, ${t('calendars.templates.addHolidaysDialogRepeatLabel')}`
                        : ''
                    }`}
                  confirmLabel={t('calendars.templates.dialogActions.confirm')}
                  cancelLabel={t('calendars.templates.dialogActions.cancel')}
                  loading={false}
                  disabled={false}
                />
              ) : null}

              {showDeleteDialog && !isLoading && !isInUse() && (
                <Dialog
                  onCancel={() => setShowDeleteDialog(false)}
                  onConfirm={() => {
                    deleteCalendar();
                  }}
                  onClose={() => {
                    setShowDeleteDialog(false);
                  }}
                  title={t('calendars.templates.deleteDialogTitle')}
                  description=""
                  confirmLabel={t('calendars.templates.dialogActions.confirm')}
                  cancelLabel={t('calendars.templates.dialogActions.cancel')}
                  loading={false}
                  disabled={false}
                />
              )}
              {showDeleteDialog && !isLoading && isInUse() && (
                <UsageDialog
                  usedInCalendars={calendarData.usedInCalendars}
                  enterpriseId={enterpriseId}
                  onClose={() => {
                    setShowDeleteDialog(false);
                  }}
                />
              )}
            </div>
            <div className={styles['button-section']}>
              <Button
                className={styles.button}
                size="m"
                disabled={isNew() && !calendarData.name}
                color="primary"
                onClick={() => {
                  if (isNew()) onSaveCalendar(calendarData.name);
                  else navigateOut();
                }}
              >
                {isNew() ? t('tours.common.next') : t('tours.common.last')}
              </Button>
              {!isNew() && (
                <Button
                  size="m"
                  color="light"
                  onClick={() => {
                    setIsLoading(true);
                    fetchData();
                    setShowDeleteDialog(true);
                  }}
                >
                  <IconDeleteRegular /> {t('generic.commonAction.delete')}
                </Button>
              )}
              {isNew() && (
                <Button
                  size="m"
                  color="link"
                  onClick={() => {
                    navigateOut();
                  }}
                >
                  {t('callflows.editCallflowDetails.onCancelLabel')}
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </BaseContainer>
  );
};

export default EditCalendarTemplate;
