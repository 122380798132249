// @flow

import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../../components/Tooltip';
import styles from './CheckboxField.module.scss';

type PropsT = {|
  field: string,
  title?: string,
  label?: string,
  postFixMsg?: string,
  disabled?: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  disabledFn?: any => boolean,
  tooltip?: string,
  reverseValue?: boolean
|};

export const CheckboxField = (props: PropsT): Element<typeof Checkbox> => {
  const { field, title, label, postFixMsg, disabled, disabledFn, tooltip, reverseValue } = props;
  const { setValue, getValues } = useFormContext();
  const values = getValues();

  const { t } = useTranslation();

  const postFixIsDefined = postFixMsg !== undefined && postFixMsg !== null;
  const disabledBoolean: boolean =
    disabledFn === undefined ? disabled || false : disabledFn(values);
  const disabledValue: ?string = disabledBoolean ? 'disabled' : null;

  return (
    <div className={styles.container} data-cy={`checkbox-${field}`}>
      <div className={styles.title}>
        {title}
        {tooltip && <Tooltip containerStyle={styles.tooltip}>{tooltip}</Tooltip>}
      </div>
      <Checkbox
        id={`checkbox-${field}`}
        name={field}
        label={label}
        value={values[field]}
        checked={values[field]}
        onChange={() => {
          const currentValue = reverseValue ? !values[field] : values[field];
          setValue(field, !currentValue, {
            shouldValidate: false,
            shouldDirty: true
          });
        }}
        disabled={disabledValue}
      >
        {postFixIsDefined ? postFixMsg : t('callflows.checkbox.postFixMsg')}
      </Checkbox>
    </div>
  );
};

export default CheckboxField;
