// @flow strict-local

import React, { type Element } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import AudioPlayerContainer from '../../../../../components/AudioPlayer/AudioPlayerContainer';
import Tooltip from '../../../../../components/Tooltip';
import type { AudioFieldT, PropertyAudioT } from '../../edit/children/audio/CallflowAudioUtils';
import { isDefaultAudioSet } from '../../edit/children/audio/CallflowAudioUtils';
import type { AudioLevelT } from '../../../../../ducks/entities/callFlow/callFlowTypes';

import styles from './CallflowAudioProperty.module.scss';

type PropsT = {|
  title?: string,
  audioField: AudioFieldT<PropertyAudioT>,
  isActive?: boolean,
  key?: string,
  postDescription?: string,
  tooltip?: string,
  noAudioLabel?: string,
  audioFilename?: string,
  notInUse?: boolean
|};

export type AudioDataT = {|
  loading?: boolean,
  error?: boolean,
  level: ?AudioLevelT,
  audio: PropertyAudioT
|};

export const CallflowAudioProperty = (props: PropsT): Element<'div'> => {
  const {
    key,
    title,
    audioFilename,
    isActive,
    postDescription,
    tooltip,
    noAudioLabel,
    audioField,
    notInUse
  } = props;

  const { t } = useTranslation();
  const defaultAudioSet = isDefaultAudioSet(audioField);
  const enterpriseAudioSet = audioField.level === 'Entreprise';
  const filename =
    audioField.level === 'Default'
      ? 'Default'
      : (audioField.audioPlayData.filename || audioField.fieldName).split('.')[0];

  const audioData: AudioDataT = {
    audio: {
      ...audioField.audioPlayData,
      filename: `${filename}.mp3`
    },
    level: defaultAudioSet ? undefined : 'Default',
    loading: undefined,
    error: undefined
  };
  const activeStyle = isActive ? styles.active : styles.inactive;

  return (
    <div className={styles.detail} key={key}>
      <div className={classnames(styles.header, activeStyle)}>
        {title}
        {tooltip && <Tooltip containerStyle={styles.tooltip}>{tooltip}</Tooltip>}
      </div>
      {audioField.level !== 'Inactive' && !notInUse ? (
        <div className={classnames(styles.value, activeStyle)}>
          {defaultAudioSet || enterpriseAudioSet ? t('callflows.audioField.defaultOption') : ''}
          <AudioPlayerContainer
            {...audioData}
            active={isActive}
            customNoAudioLabel={noAudioLabel}
            audioFilename={audioFilename}
          />
        </div>
      ) : (
        <div>{t('callflows.audioField.defaultAudioLabel')}</div>
      )}
      {postDescription && (
        <div className={classnames(styles.value, activeStyle)}>{postDescription}</div>
      )}
    </div>
  );
};

export default CallflowAudioProperty;
