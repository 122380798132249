// @flow

import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'moment/locale/fi';
import 'moment/locale/sv';
import 'moment/locale/et';
import Modal from '@design-system/component-library/src/components/Modal';
import Button from '@design-system/component-library/src/components/Button';
import { CancelToken } from 'axios';
import type { Canceler } from 'axios';
import { useTranslation } from 'react-i18next';
import { closePopovers, parsePresenceDate } from '../../helpers';
import { removeCalendarSlot } from '../../ducks/entities/calendar/calendarOperations';
import { actions as notificationActions } from '../../ducks/ui/notification';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import type { CurrentUserT } from '../../ducks/currentUser/currentUserTypes';
import styles from './DeletePresenceModal.module.scss';

export type PropsT = {
  selectedCalendarSlot: *,
  modalRef: *,
  enterpriseId: string,
  calendarId: string,
  reloadFunc: () => void
};

const DeletePresenceModal = ({
  enterpriseId,
  calendarId,
  selectedCalendarSlot,
  modalRef,
  reloadFunc
}: PropsT): Element<'div'> => {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;
  const removeCalendarCanceller = React.useRef<Canceler>();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const dispatch = useDispatch();

  const deleteCalendarSlot = async scheduleId => {
    const returnValue = await dispatch(
      removeCalendarSlot(
        enterpriseId,
        calendarId,
        scheduleId,
        new CancelToken(canceler => {
          removeCalendarCanceller.current = canceler;
        }),
        createCsrfHeader(currentUser)
      )
    );
    if (returnValue) {
      if (modalRef.current) {
        modalRef.current.closeModal();
      }
      reloadFunc();
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'presence-delete-success',
          duration: 15000,
          type: 'info',
          message: t('presenceCalendarListing.deleteSuccess')
        })
      );
    } else {
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'presence-delete-failure',
          duration: 15000,
          type: 'error',
          message: t('presenceCalendarListing.deleteFailure')
        })
      );
    }
  };

  const generateDeleteDialogContent = () => {
    if (selectedCalendarSlot) {
      const startTime = parsePresenceDate(selectedCalendarSlot.startTime);
      const endTime = selectedCalendarSlot.endTime
        ? parsePresenceDate(selectedCalendarSlot.endTime)
        : null;
      startTime.locale(activeLanguage || 'fi');
      if (endTime && startTime.isSame(endTime, 'date')) {
        return t('presenceCalendarListing.removeSingleDay', {
          date: `${startTime.format('dd')} ${startTime.format('HH.mm')} - ${endTime.format(
            'HH.mm'
          )}`
        });
      }
      return t('presenceCalendarListing.removeMultiDay', {
        date: `${startTime.format('DD.MM')} - ${endTime ? endTime.format('DD.MM') : ''}`
      });
    }
    return '';
  };

  return (
    <Modal
      ref={modalRef}
      className={styles['presence-modal']}
      autoOpen={false}
      id="deleteModal"
      size="s"
      heading={t('presenceCalendarListing.removeTitle')}
      content={generateDeleteDialogContent()}
      closeWithBackdropClick={false}
      buttons={[
        <Button
          key="btnCancel"
          id="btnCancel"
          size="l"
          color="link"
          onClick={() => {
            closePopovers();
            if (modalRef.current) {
              modalRef.current.closeModal();
            }
          }}
        >
          {t('presenceCalendarListing.cancel')}
        </Button>,
        <Button
          key="btnConfirm"
          id="btnConfirm"
          size="l"
          color="primary"
          onClick={() => {
            closePopovers();
            if (modalRef.current && selectedCalendarSlot) {
              deleteCalendarSlot(selectedCalendarSlot.id);
            }
          }}
        >
          {t('presenceCalendarListing.confirmRemove')}
        </Button>
      ]}
    />
  );
};
export default DeletePresenceModal;
