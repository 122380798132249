/* eslint-disable flowtype/no-weak-types */
// @flow

import React, { type Element, useState } from 'react';
import Moment from 'moment';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import { useTranslation } from 'react-i18next';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import { Button } from '@design-system/component-library';
import IconDeleteRegular from '@design-system/component-library/src/components/Icon/lib/IconDeleteRegular';
import { findHolidayForDate, OFFICE_HOURS } from '../../calendarTemplates/calendarTemplateUtil';
import Dismiss from '../../../../components/Button/Dismiss';
import TimeSlotEditor from './TimeSlotEditor';
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker';
import 'react-time-picker/dist/TimePicker.css';
import { validateTimeSlots } from '../../callFlowGrid/details/CalendarUtils';

import styles from './OpeningHoursDialog.module.scss';

export type PropsT = {
  onCancel: (*) => *,
  onConfirm: any => *,
  onDelete: any => *,
  onClose?: (*) => *,
  title: string,
  dates: any,
  initialDate: any,
  holidays: any
};

const OpeningHoursDialog = ({
  onCancel,
  onConfirm,
  onDelete,
  onClose,
  title,
  dates,
  initialDate,
  holidays
}: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  const [selectedDate, _setSelectedDate] = useState(
    initialDate || {
      date: Moment().format('YYYY-MM-DD'),
      identifier: null,
      slots: [],
      closedAllDay: true,
      repeatsYearly: false,
      repeatsYearlyAtHoliday: null,
      title: ''
    }
  );

  const [closedAllDay, setClosedAllDay] = useState(selectedDate.closedAllDay);
  const [timeSlots, setTimeSlots] = useState(selectedDate.slots);
  const [selectedDateHoliday, setSelectedDateHoliday] = useState(
    findHolidayForDate(selectedDate.date, holidays)
  );

  const setSelectedDate = (date: any) => {
    _setSelectedDate(date);
    setClosedAllDay(date.closedAllDay);
    setTimeSlots(date.slots);
    setSelectedDateHoliday(findHolidayForDate(date.date, holidays));
  };

  const selectDate = dateTime => {
    const dateStr = Moment(dateTime).format('YYYY-MM-DD');

    for (let i = 0; i < dates.length; i++) {
      if (dates[i].date === dateStr) {
        setSelectedDate(dates[i]);
        return;
      }
    }
    const dateOb = {
      date: dateStr,
      identifier: null,
      slots: [],
      closedAllDay: true,
      repeatsYearly: false,
      repeatsYearlyAtHoliday: null,
      title: ''
    };
    setSelectedDate(dateOb);
  };

  const toggleClosedAllDay = () => {
    const val = !selectedDate.closedAllDay;
    selectedDate.closedAllDay = val;
    if (!val && (selectedDate.slots == null || selectedDate.slots.length < 1)) {
      selectedDate.slots.push({ ...OFFICE_HOURS });
    }
    setClosedAllDay(val);
  };

  const handleTimeSlotsUpdated = slots => {
    selectedDate.slots = slots;
    setSelectedDate({
      ...selectedDate
    });
  };

  return (
    <div
      className={`ea-modal ea-modal--open styleguide-dialog-position ${styles.container}`}
      role="dialog"
      aria-modal="true"
    >
      <div className="ea-modal__overlay" />
      <div className={`ea-modal__content ${styles.content}`}>
        {onClose && <Dismiss id="close-button" onClose={onClose} />}
        <div className={styles.box}>
          <h2 id="dialog-title" className="ea-h3 ea-h3--thick">
            {title}
          </h2>
          <div className={styles['flex-container']}>
            <div>
              <CustomDatePicker
                selected={selectedDate ? new Date(selectedDate.date) : null}
                onChange={selectDate}
                minDate={new Date()}
                bankHolidayData={holidays}
                inline
                highlightDates={dates.map(d => new Date(Date.parse(d.date)))}
              />
              <br />
              <div className={styles['legend-container']}>
                <span>
                  <span
                    className={`${styles['legend-marker']} ${styles['legend-marker-selected-date']}`}
                  />
                  {t('calendars.templates.datePickerLegend.selectedDate')}
                </span>
                <span>
                  <span
                    className={`${styles['legend-marker']} ${styles['legend-marker-holiday']}`}
                  />
                  {t('calendars.templates.datePickerLegend.holiday')}
                </span>
                <span>
                  <span
                    className={`${styles['legend-marker']} ${styles['legend-marker-unofficial-holiday']}`}
                  />
                  {t('calendars.templates.datePickerLegend.unofficialHoliday')}
                </span>
                <span>
                  <span
                    className={`${styles['legend-marker']} ${styles['legend-marker-exceptional-hours']}`}
                  />
                  {t('calendars.templates.datePickerLegend.exceptionalHours')}
                </span>
                <span>
                  <span
                    className={`${styles['legend-marker']} ${styles['legend-marker-current-date']}`}
                  />
                  {t('calendars.templates.datePickerLegend.currentDate')}
                </span>
              </div>
            </div>
            <div>
              <div className={styles.subheader}>
                {`${Moment(selectedDate.date).format('D.M.YYYY')} ${
                  selectedDateHoliday ? t(`holidays.${selectedDateHoliday.name}`) : ''
                }`}
              </div>

              <div className={styles.subheader}>{t('calendars.templates.openingHoursTitle')}</div>
              <div className={styles.infotext}>{t('calendars.templates.openingHoursHelp')}</div>

              <Checkbox
                id="allday-switch"
                key="allday-switch"
                name="allday-switch"
                data-cy="allday-switch"
                value={closedAllDay}
                checked={closedAllDay}
                onChange={() => {
                  toggleClosedAllDay();
                }}
                label={t('generic.dateTimePicker.allDay')}
              />

              {!closedAllDay && (
                <div>
                  <TimeSlotEditor
                    slots={timeSlots}
                    disabled={selectedDate.inherited}
                    onUpdated={handleTimeSlotsUpdated}
                  />
                  {validateTimeSlots(closedAllDay, timeSlots) ? (
                    <div className={styles.error}>{t('calendars.timeSlotError')}</div>
                  ) : null}
                </div>
              )}

              <div className={styles.subheader}>{t('calendars.templates.repeatTitle')}</div>
              <div className={styles.infotext}>{t('calendars.templates.repeatHelp')}</div>
              <RadioGroup
                disabled={selectedDate.inherited}
                value={
                  selectedDate.repeatsYearly
                    ? 'yearly'
                    : selectedDate.repeatsYearlyAtHoliday
                    ? 'at_holiday'
                    : 'no'
                }
              >
                <Radio
                  key="repeats_option_no"
                  name="repeats_option_no"
                  label={t('calendars.templates.repeatOptionNo')}
                  value="no"
                  onChange={() => {
                    selectedDate.repeatsYearly = false;
                    selectedDate.repeatsYearlyAtHoliday = null;
                  }}
                  disabled={false}
                />
                <Radio
                  key="repeats_option_yearly"
                  name="repeats_option_yearly"
                  label={
                    t('calendars.templates.repeatOptionYearly') +
                    Moment(selectedDate.date).format('D.M.')
                  }
                  value="yearly"
                  onChange={() => {
                    selectedDate.repeatsYearly = true;
                    selectedDate.repeatsYearlyAtHoliday = null;
                  }}
                  disabled={false}
                />
                <Radio
                  key="repeats_option_at_holiday"
                  name="repeats_option_at_holiday"
                  label={
                    selectedDateHoliday
                      ? t('calendars.templates.repeatOptionYearly') +
                        t(`holidays.${selectedDateHoliday.name}`)
                      : null
                  }
                  value="at_holiday"
                  onChange={() => {
                    if (!selectedDateHoliday) {
                      console.error('no holiday selected or found!');
                      return;
                    }
                    selectedDate.repeatsYearly = false;
                    selectedDate.repeatsYearlyAtHoliday = selectedDateHoliday.name;
                  }}
                  disabled={!selectedDateHoliday}
                  style={{ visibility: selectedDateHoliday ? 'visible' : 'hidden' }}
                />
              </RadioGroup>

              <div className={styles.buttons}>
                <Button
                  id="opening-hours-delete-button"
                  size="m"
                  disabled={!selectedDate.identifier || selectedDate.inherited}
                  color="light"
                  onClick={() => {
                    onDelete(selectedDate);
                  }}
                >
                  {t('calendars.templates.dialogActions.delete')}
                  <IconDeleteRegular />
                </Button>
                <Button size="m" color="link" id="opening-hours-cancel-button" onClick={onCancel}>
                  {t('calendars.templates.dialogActions.cancel')}
                </Button>
                <Button
                  id="opening-hours-save-button"
                  size="m"
                  color="primary"
                  disabled={selectedDate.inherited || validateTimeSlots(closedAllDay, timeSlots)}
                  onClick={() => onConfirm(selectedDate)}
                >
                  {t('calendars.templates.dialogActions.save')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpeningHoursDialog;
