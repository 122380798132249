// @flow
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import configOperations from '../ducks/config/configOperations';
import type { AcdSettingsT } from '../ducks/config/configTypes';

export type AcdSettingsResponseT = {|
  acdSettings?: ?AcdSettingsT,
  isLoadingAcdSettings?: boolean
|};

const useAcdSettings = (
  enterpriseId: string | typeof undefined | null,
  acdId: string | typeof undefined | null
): AcdSettingsResponseT => {
  // Redux
  const dispatch = useDispatch();

  // State
  const [isLoadingAcdSettings, setIsLoadingAcdSettings] = useState<boolean>(true);
  const [acdSettings, setAcdSettings] = useState<?AcdSettingsT>();

  const fetchAcdSettings = async () => {
    if (enterpriseId && acdId) {
      const res = await dispatch(configOperations.getAcdSettings(enterpriseId, acdId));
      setAcdSettings(res);
    }
    setIsLoadingAcdSettings(false);
  };

  useEffect(() => {
    fetchAcdSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    acdSettings,
    isLoadingAcdSettings
  };
};

export default useAcdSettings;
