/* eslint-disable flowtype/no-weak-types */
// @flow

import React, { type Element } from 'react';
import uuid from 'uuid';
import { useTranslation } from 'react-i18next';
import { Button } from '@design-system/component-library';
import IconDeleteRegular from '@design-system/component-library/src/components/Icon/lib/IconDeleteRegular';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import Moment from 'moment';
import styles from './TimeSlotEditor.module.scss';

export type PropsT = {
  slots: any,
  disabled: boolean,
  onUpdated: any => *
};

const TimeSlotEditor = ({ slots, disabled, onUpdated }: PropsT): Element<'div'> => {
  const { t } = useTranslation();

  const handleTimeSlotStartChange = (slot, value) => {
    // eslint-disable-next-line no-param-reassign
    slot.startTime = value;
    onUpdated(slots);
  };

  const handleTimeSlotEndChange = (slot, value) => {
    // eslint-disable-next-line no-param-reassign
    slot.endTime = value;
    onUpdated(slots);
  };

  const deleteTimeSlot = slot => {
    onUpdated(
      slots.filter(s => {
        return slot !== s;
      })
    );
  };

  const addNewSlot = () => {
    let newStartTime = Moment()
      .startOf('day')
      .format('HH:mm');

    if (slots.length > 0) {
      const lastEndTime = slots[slots.length - 1].endTime;
      newStartTime = Moment(lastEndTime, 'HH:mm')
        .add(1, 'minute')
        .format('HH:mm');
    }

    slots.push({
      startTime: newStartTime,
      endTime: Moment(newStartTime, 'HH:mm')
        .add(1, 'hour')
        .format('HH:mm')
    });

    onUpdated(slots);
  };

  return (
    <div>
      {slots.map(
        s =>
          s && (
            <div>
              <TimePicker
                id="time-begin"
                data-cy="time-begin-input"
                name="time-begin"
                onChange={value => {
                  handleTimeSlotStartChange(s, value);
                }}
                className={styles['time-input']}
                value={s.startTime}
                disabled={disabled}
                disableClock
                required
                locale="fi-FI"
                format="HH:mm"
              />
              <span>-</span>
              <TimePicker
                id="time-end"
                data-cy="time-end-input"
                name="time-end"
                onChange={value => {
                  handleTimeSlotEndChange(s, value);
                }}
                className={styles['time-input']}
                value={s.endTime}
                disabled={disabled}
                disableClock
                required
                locale="fi-FI"
                format="HH:mm"
              />
              {slots.length > 1 && !disabled && (
                <Button
                  key={uuid()}
                  size="s"
                  color="light"
                  onClick={() => {
                    deleteTimeSlot(s);
                  }}
                >
                  <IconDeleteRegular />
                </Button>
              )}
            </div>
          )
      )}
      {!disabled && (
        <Button
          className={styles['add-slot-button']}
          id="add-slot-button"
          size="s"
          color="light"
          onClick={() => {
            addNewSlot();
          }}
        >
          {t('generic.dateTimePicker.addTimeRangeButton')}
        </Button>
      )}
    </div>
  );
};

export default TimeSlotEditor;
