/* eslint-disable flowtype/no-weak-types */
// @flow

import React, { type Element } from 'react';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button } from '@design-system/component-library';
import IconDeleteRegular from '@design-system/component-library/src/components/Icon/lib/IconDeleteRegular';
import IconReplyRegular from '@design-system/component-library/src/components/Icon/lib/IconReplyRegular';
import IconEditRegular from '@design-system/component-library/src/components/Icon/lib/IconEditRegular';
import 'react-time-picker/dist/TimePicker.css';
import styles from './DateTable.module.scss';

export type PropsT = {
  dates: any,
  editable: boolean,
  templateName: string,
  onEdit?: any => *,
  onDelete?: any => *
};

const DateTable = ({ dates, editable, templateName, onEdit, onDelete }: PropsT): Element<'div'> => {
  const { t } = useTranslation();

  return (
    <div>
      {dates != null && dates.length === 0 && <span>{t('calendars.templates.noDates')}</span>}
      {dates != null && dates.length > 0 && (
        <table className={styles['date-table']}>
          <thead>
            <tr>
              <th>{t('calendars.templates.date')}</th>
              <th>{t('calendars.templates.slots')}</th>
              <th>{t('calendars.templates.repeating')}</th>
              {editable && (
                <th className={styles['action-column']}>{t('calendars.templates.actions')}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {dates.map(
              d =>
                d && (
                  <tr
                    key={(d.identifier || '_') + d.date}
                    className={d.overridden ? styles.overridden : ''}
                  >
                    <td>
                      {d.overridden && <IconReplyRegular />}&nbsp;
                      {Moment(d.date).format('D.M.YYYY')} {d.title && t(`holidays.${d.title}`)}
                    </td>
                    <td>
                      {d.closedAllDay
                        ? t('callflows.details.closed')
                        : d.slots.map(
                            s =>
                              s && (
                                <>
                                  {s.startTime.substring(0, 5)}-{s.endTime.substring(0, 5)}{' '}
                                </>
                              )
                          )}
                    </td>
                    <td>
                      {d.repeatsYearly
                        ? t('generic.yes')
                        : d.repeatsYearlyAtHoliday
                        ? `${t('generic.yes')} (${t(`holidays.${d.repeatsYearlyAtHoliday}`)})`
                        : t('generic.no')}
                    </td>
                    {editable && (
                      <td className={styles['action-column']}>
                        {d.inherited ? (
                          <span> {templateName} </span>
                        ) : (
                          <div>
                            <Button
                              id={`edit-date-button-${d.identifier || '_'}${d.date}`}
                              size="s"
                              color="light"
                              onClick={() => {
                                if (onEdit) onEdit(d);
                              }}
                            >
                              <IconEditRegular />
                            </Button>
                            &nbsp;
                            <Button
                              id={`delete-date-button-${d.identifier || '_'}${d.date}`}
                              size="s"
                              color="light"
                              onClick={() => {
                                if (onDelete) onDelete(d);
                              }}
                            >
                              <IconDeleteRegular />
                            </Button>
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                )
            )}
          </tbody>
        </table>
      )}{' '}
    </div>
  );
};

export default DateTable;
