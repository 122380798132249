// @flow

import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Table from '@design-system/component-library/src/components/Table';
import { useTranslation } from 'react-i18next';
import Button from '@design-system/component-library/src/components/Button';
import axios, { CancelToken } from 'axios';
import parse from 'html-react-parser';
// $FlowFixMe
import writeXlsxFile from 'write-excel-file';
import moment from 'moment';
import IconDownloadRegular from '@design-system/component-library/src/components/Icon/lib/IconDownloadRegular';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import Input from '@design-system/component-library/src/components/Input';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import type { Canceler } from 'axios';
import Modal from '@design-system/component-library/src/components/Modal';
import { ReactComponent as AcdIcon } from '../../../assets/callflow/grid/acd.svg';
import LinkButton from '../../../components/Button/LinkButton';
import InfoBox from '../../../components/InfoBox';
import GoBackLink from '../../../components/Button/GoBackLink';
import UserSelectorComponent from '../../../components/UserSelector/UserSelector';
import type { InternalUserStateEntityT } from '../../../ducks/entities/user/userTypes';
import CancelButton from '../../../components/Button/CancelButton';
import ActionButton from '../../../components/Button/ActionButton';
import Avatar from '../../../components/Avatar/Avatar';
import { createCsrfHeader, isAdmin } from '../../../utils/accessRightUtils';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import { goToNoPermissionPage } from '../../../navigationOperations';
import styles from './AcdSupervisors.module.scss';

export type PropsT = {|
  enterpriseId: string
|};
const PROCESS_CHECK_DELAY = 5000;

const AcdSupervisors = ({ enterpriseId }: PropsT) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [acds, setAcds] = useState([]);
  const [acdSupervisors, setAcdSupervisors] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [displayAcdBy, setDisplayAcdBy] = useState('byAcd');
  const [filterTerm, setFilterTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectionKey, setSelectionKey] = useState(0);
  const [selectedAcdSupervisor, setSelectedAcdSupervisor] = useState(location.state?.supervisorId);
  const [selectedAcd, setSelectedAcd] = useState(location.state?.acdId);
  const [toBeAdded, setToBeAdded] = useState();
  const [preselection, setPreselection] = useState([]);
  const [updating, setUpdating] = useState();
  const [loading, setLoading] = useState();
  const cancelGetAcdsRequest = CancelToken.source();
  const currentUser = useSelector(state => state.currentUser);
  const [uuid, setUuid] = useState();
  const [processed, setProcessed] = useState();
  const [toBeProcessed, setToBeProcessed] = useState();
  const cancelRequest = React.useRef<Canceler>();
  const processModalRef = React.useRef<Modal>();
  const MAX_DISPLAYED = 5;
  const MAX_ACDS = 10000;

  const fetchData = async (url, setData) => {
    try {
      const { data } = await axios.get(url, {
        cancelToken: cancelGetAcdsRequest.token
      });
      setData(data.results);
      return data.results;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const findACDSupervisors = addressNumber => {
    return acdSupervisors.filter(
      acdSupervisor =>
        !acdSupervisor.iUser.isAcdSupervisorAccessRestricted ||
        acdSupervisor.restrictedACDGroups.some(acdGroup => acdGroup.addressNumber === addressNumber)
    );
  };

  const findACDs = acdSupervisor => {
    return acds.filter(
      acd =>
        acdSupervisor &&
        (!acdSupervisor.iUser.isAcdSupervisorAccessRestricted ||
          acdSupervisor.restrictedACDGroups.some(
            acdGroup => acdGroup.addressNumber === acd.addressNumber
          ))
    );
  };

  const generatePreselection = () => {
    setPreselection(
      selectedAcd
        ? findACDSupervisors(selectedAcd?.addressNumber || '').map(supervisor => ({
            id: supervisor.addressNumber,
            userName: supervisor.iUserName,
            addressNumber: supervisor.addressNumber,
            title: supervisor.iUser.title,
            internalAddressID: supervisor.internalAddressID,
            isAcdSupervisorAccessRestricted: supervisor.iUser.isAcdSupervisorAccessRestricted,
            restrictedACDGroupIds: supervisor.restrictedACDGroups.map(
              group => group.internalAddressID
            )
          }))
        : // $FlowFixMe
          findACDs(selectedAcdSupervisor).map(acd => ({
            id: acd.id,
            userName: acd.label,
            addressNumber: acd.addressNumber,
            isAcd: true
          }))
    );
    setSelectionKey(Date.now());
  };

  const isNotAllowedToViewPage = () => {
    return !isAdmin(currentUser);
  };

  useEffect(() => {
    if (isNotAllowedToViewPage()) {
      return () => cancelGetAcdsRequest.cancel();
    }
    const fetchAllData = async () => {
      setLoading(true);
      const acdsReturned = await fetchData(
        `/api/v1/enterprises/${enterpriseId}/services/acds?size=${MAX_ACDS}`,
        setAcds
      );
      const supervisorsReturned = await fetchData(
        `/api/v1/enterprises/${enterpriseId}/acdsupervisors`,
        setAcdSupervisors
      );
      setLoading(false);
      if (
        acdsReturned &&
        supervisorsReturned &&
        (location.state?.acdId || location.state?.supervisorId)
      ) {
        if (location.state?.acdId) {
          setSelectedAcd(acdsReturned.find(acd => acd.id === location.state?.acdId));
        }
        if (location.state?.supervisorId) {
          setSelectedAcdSupervisor(
            supervisorsReturned.find(s => s.internalAddressID === location.state?.supervisorId)
          );
        }
      }
    };
    fetchAllData();
    return () => cancelGetAcdsRequest.cancel();
  }, [enterpriseId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isNotAllowedToViewPage()) {
      return;
    }
    setFilterTerm('');
    if (selectedAcd || selectedAcdSupervisor) {
      generatePreselection();
    }
  }, [selectedAcdSupervisor, selectedAcd]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isNotAllowedToViewPage()) {
    dispatch(goToNoPermissionPage());
  }

  const getACDSupervisorsForXLSX = addressNumber => {
    const foundAcdSupervisors = findACDSupervisors(addressNumber);
    return acdSupervisors.length > 0 && acdSupervisors.length === foundAcdSupervisors.length
      ? t('callflows.acdSupervisors.allAcdSupervisors')
      : foundAcdSupervisors
          .map(acdSupervisor => `${acdSupervisor.iUserName} | ${acdSupervisor.addressNumber}`)
          .join('\n');
  };

  const getACDSupervisors = (addressNumber, maxResults) => {
    const foundAcdSupervisors = findACDSupervisors(addressNumber);
    const supervisors =
      acdSupervisors.length > 0 && acdSupervisors.length === foundAcdSupervisors.length
        ? t('callflows.acdSupervisors.allAcdSupervisors')
        : parse(
            foundAcdSupervisors
              .map(acdSupervisor => `${acdSupervisor.iUserName} | ${acdSupervisor.addressNumber}`)
              .slice(0, maxResults)
              .join('<br />')
              .concat(
                maxResults < foundAcdSupervisors.length
                  ? `<br /><div style="color: #767677; margin-top: 1em;">${t(
                      'callflows.acdSupervisors.totalSupervisors',
                      {
                        total: foundAcdSupervisors.length
                      }
                    )}</div>`
                  : ''
              )
          );

    return supervisors;
  };

  const getACDs = (acdSupervisor, maxResults) => {
    const foundAcds = findACDs(acdSupervisor);
    return acds.length > 0 && acds.length === foundAcds.length
      ? t('callflows.acdSupervisors.allAcds')
      : parse(
          foundAcds
            .map(acd => `${acd.label} | ${acd.addressNumber}`)
            .slice(0, maxResults)
            .join('<br />')
            .concat(
              maxResults < foundAcds.length
                ? `<br /><div style="color: #767677; margin-top: 1em;">${t(
                    'callflows.acdSupervisors.totalACDs',
                    {
                      total: foundAcds.length
                    }
                  )}</div>`
                : ''
            )
        );
  };

  const getACDsForXLSX = acdSupervisor => {
    const foundAcds = findACDs(acdSupervisor);
    return acds.length > 0 && acds.length === foundAcds.length
      ? t('callflows.acdSupervisors.allAcds')
      : foundAcds.map(acd => `${acd.label} | ${acd.addressNumber}`).join('\n');
  };

  const getAcdsRows = () =>
    acds
      .filter(
        acd =>
          (acd.label && acd.label.toLowerCase().includes(filterTerm.toLowerCase())) ||
          acd.pstnNumbers.some(number => number.includes(filterTerm))
      )
      .map(acd => ({
        acdname: acd.label,
        phonenumber: acd.pstnNumbers.map(number =>
          parse(`<a href="tel:${number}">${number}</a>&nbsp;`)
        ),
        extension: acd.addressNumber,
        supervisors: getACDSupervisors(acd.addressNumber, MAX_DISPLAYED),
        actions: (
          <LinkButton
            id={`${acd.id}-link-button`}
            label={t('callflows.acdSupervisors.modify')}
            disabled={loading}
            onClickAction={() => {
              setSelectedAcdSupervisor(null);
              setSelectedAcd(acd);
            }}
          />
        )
      }));

  const getAcdSupervisorsRows = () =>
    acdSupervisors
      .filter(
        supervisor =>
          supervisor.iUserName.toLowerCase().includes(filterTerm.toLowerCase()) ||
          supervisor.addressNumber.includes(filterTerm)
      )
      .map(acdSupervisor => ({
        acdname: getACDs(acdSupervisor, MAX_DISPLAYED),
        email: acdSupervisor.iUser.emails
          ? acdSupervisor.iUser.emails
              .split(';')
              .map(email => parse(`<a href="mailto:${email}">${email}</a>&nbsp;`))
          : '',
        extension: acdSupervisor.addressNumber,
        supervisor: acdSupervisor.iUserName,
        actions: (
          <LinkButton
            id={`${acdSupervisor.addressNumber}-link-button`}
            label={t('callflows.acdSupervisors.modify')}
            disabled={loading}
            onClickAction={() => {
              setSelectedAcd(null);
              setSelectedAcdSupervisor(acdSupervisor);
            }}
          />
        )
      }));

  const exportAcdSupervisors = async () => {
    setExporting(true);
    const HEADER_ROWS_ACD = [
      {
        value: t('callflows.acdSupervisors.acdname'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.phonenumber'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.extension'),
        fontWeight: 'bold'
      },
      {
        value: t('callflows.acdSupervisors.supervisors'),
        fontWeight: 'bold',
        width: 20
      }
    ];
    const rowsAcd = acds.map(acd => [
      {
        type: String,
        value: acd.label
      },
      {
        type: String,
        value: acd.pstnNumbers.map(number => `${number}`).join(', ')
      },
      {
        type: String,
        value: acd.addressNumber
      },
      {
        type: String,
        value: getACDSupervisorsForXLSX(acd.addressNumber)
      }
    ]);
    const HEADER_ROWS_USER = [
      {
        value: t('callflows.acdSupervisors.supervisors'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.email'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.extension'),
        fontWeight: 'bold'
      },
      {
        value: t('callflows.acdSupervisors.acdname'),
        fontWeight: 'bold',
        width: 20
      }
    ];
    const rowsUser = acdSupervisors.map(acdSupervisor => [
      {
        type: String,
        value: acdSupervisor.iUserName
      },
      {
        type: String,
        value: acdSupervisor.iUser.emails
      },
      {
        type: String,
        value: acdSupervisor.addressNumber
      },
      {
        type: String,
        value: getACDsForXLSX(acdSupervisor)
      }
    ]);
    const HEADER_ROWS_SINGLE = [
      {
        value: t('callflows.acdSupervisors.supervisors'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.email'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.personExtension'),
        fontWeight: 'bold'
      },
      {
        value: t('callflows.acdSupervisors.acdname'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.acdPhonenumber'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.acdExtension'),
        fontWeight: 'bold',
        width: 20
      }
    ];
    const rowsSingle = acdSupervisors.flatMap(acdSupervisor => {
      const foundAcds = findACDs(acdSupervisor);
      return foundAcds.map(acd => [
        {
          type: String,
          value: acdSupervisor.iUserName
        },
        {
          type: String,
          value: acdSupervisor.iUser.emails
        },
        {
          type: String,
          value: acdSupervisor.addressNumber
        },
        {
          type: String,
          value: acd.label
        },
        {
          type: String,
          value: acd.pstnNumbers.map(number => `${number}`).join(', ')
        },
        {
          type: String,
          value: acd.addressNumber
        }
      ]);
    });
    const data = [
      [HEADER_ROWS_ACD, ...rowsAcd],
      [HEADER_ROWS_USER, ...rowsUser],
      [HEADER_ROWS_SINGLE, ...rowsSingle]
    ];
    await writeXlsxFile(data, {
      columns: [HEADER_ROWS_ACD, HEADER_ROWS_USER, HEADER_ROWS_SINGLE],
      sheets: [
        t('callflows.acdSupervisors.acdTitle'),
        t('callflows.acdSupervisors.userTitle'),
        t('callflows.acdSupervisors.singleTitle')
      ],
      fileName: `acdsupervisors_export_${moment().format('YYYYMMDD')}.xlsx`
    });

    setExporting(false);
  };

  const reloadAcdsAndAcdSupervisors = async () => {
    await fetchData(`/api/v1/enterprises/${enterpriseId}/services/acds`, setAcds);
    await fetchData(`/api/v1/enterprises/${enterpriseId}/acdsupervisors`, setAcdSupervisors);
    setLoading(false);
    setUpdating(false);
    setSelectedAcdSupervisor(null);
    setSelectedAcd(null);
  };

  const patchSelectedAcd = async () => {
    if (!toBeAdded || !selectedAcd) return;
    setUpdating(true);
    try {
      const addRequests = toBeAdded
        .filter(
          supervisor =>
            supervisor.isAcdSupervisorAccessRestricted &&
            !supervisor.restrictedACDGroupIds.includes(selectedAcd.id)
        )
        .map(supervisor => ({
          extensionId: supervisor.internalAddressID,
          restrictedACDGroupIds: [...supervisor.restrictedACDGroupIds, selectedAcd.id]
        }));

      const removeRequests = preselection
        .filter(
          acdSupervisor =>
            !toBeAdded.some(selectedSupervisor => selectedSupervisor.id === acdSupervisor.id)
        )
        .map(supervisor => ({
          extensionId: supervisor.internalAddressID,
          restrictedACDGroupIds: supervisor.isAcdSupervisorAccessRestricted
            ? supervisor.restrictedACDGroupIds.filter(id => id !== selectedAcd.id)
            : acds.map(acd => acd.id).filter(id => id !== selectedAcd.id)
        }));

      const response = await axios.post(
        `/api/v1/enterprises/${enterpriseId}/acdsupervisors/process`,
        [...addRequests, ...removeRequests],
        {
          cancelToken: cancelGetAcdsRequest.token,
          headers: createCsrfHeader(currentUser)
        }
      );

      if (response) {
        setUuid(response.data);
      }
    } catch (error) {
      console.log('Error patching acd', error);
    }
  };

  const stopProcessingAcdSupervisors = () => {
    axios({
      method: 'POST',
      // $FlowFixMe: enterpriseId already null checked
      url: `/api/v1/enterprises/${enterpriseId}/acdsupervisors/process/done?uuid=${uuid}`,
      cancelToken: new CancelToken(canceler => {
        cancelRequest.current = canceler;
      }),
      headers: createCsrfHeader(currentUser)
    });
    setUuid();
    setProcessed();
    setToBeProcessed();

    reloadAcdsAndAcdSupervisors();
  };

  useEffect(() => {
    let id;
    if (uuid && uuid !== '' && enterpriseId) {
      id = setInterval(async () => {
        try {
          const response = await axios({
            method: 'GET',
            url: `/api/v1/enterprises/${enterpriseId}/acdsupervisors/process?uuid=${uuid}`,
            cancelToken: new CancelToken(canceler => {
              cancelRequest.current = canceler;
            })
          });
          setProcessed(response.data.processed);
          setToBeProcessed(response.data.toBeProcessed);
          if (
            response &&
            response.data.toBeProcessed > 0 &&
            response.data.processed === response.data.toBeProcessed
          ) {
            clearInterval(id);
            stopProcessingAcdSupervisors();
          }
        } catch (e) {
          console.log(e);
        }
      }, PROCESS_CHECK_DELAY);
    }
    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  const patchSelectedSupervisor = async () => {
    if (!toBeAdded || !selectedAcdSupervisor) return;
    setUpdating(true);
    try {
      await axios.patch(
        `/api/v1/enterprises/${enterpriseId}/acdsupervisors/${selectedAcdSupervisor.internalAddressID}`,
        [...toBeAdded.map(acd => acd.id)],
        {
          cancelToken: cancelGetAcdsRequest.token,
          headers: createCsrfHeader(currentUser)
        }
      );
    } catch (error) {
      console.log('Error patching supervisor', error);
    }
    reloadAcdsAndAcdSupervisors();
  };

  let searchResults = selectedAcd
    ? acdSupervisors.map(supervisor => ({
        id: supervisor.addressNumber,
        userName: supervisor.iUserName,
        addressNumber: supervisor.addressNumber,
        title: supervisor.iUser.title,
        internalAddressID: supervisor.internalAddressID,
        isAcdSupervisorAccessRestricted: supervisor.iUser.isAcdSupervisorAccessRestricted,
        restrictedACDGroupIds: supervisor.restrictedACDGroups.map(group => group.internalAddressID)
      }))
    : acds.map(acd => ({
        id: acd.id,
        userName: acd.label,
        addressNumber: acd.addressNumber,
        isAcd: true
      }));
  searchResults = searchResults.filter(
    supervisor =>
      (supervisor.userName &&
        supervisor.userName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      supervisor.addressNumber.includes(searchTerm)
  );

  const editView = () => {
    return loading ? (
      <div className={styles['center-vertically']}>
        <CenterHorizontally>
          <LoadingSpinner />
        </CenterHorizontally>
      </div>
    ) : (
      <div className={styles.container}>
        <GoBackLink
          className={styles.link}
          id="header-link"
          text={t('callflows.acdSupervisors.return')}
          onClick={() => {
            setSelectedAcd(null);
            setSelectedAcdSupervisor(null);
          }}
        />
        <InfoBox extraStyle={styles.infobox}>
          <div className={styles.strong}>
            {selectedAcdSupervisor
              ? t('callflows.acdSupervisors.modifyAcds')
              : t('callflows.acdSupervisors.modifyAcdSupervisors')}
          </div>
          <div className={styles['icon-area']}>
            {selectedAcdSupervisor ? (
              <Avatar
                hideAvailability
                name={selectedAcdSupervisor?.iUserName}
                color="#0019AF"
                size="small"
              />
            ) : (
              <AcdIcon />
            )}
            <h2 className={styles['icon-title']}>
              {selectedAcdSupervisor?.iUserName || selectedAcd?.label || ''}
            </h2>
          </div>
        </InfoBox>

        <UserSelectorComponent
          key={selectionKey}
          searchResults={searchResults}
          hideButtons
          isLoadingSearchResults={false}
          onSearchTermChange={newSearchTerm => setSearchTerm(newSearchTerm)}
          onSearchTermClear={() => setSearchTerm('')}
          onSelectionChange={(selectedUsers: InternalUserStateEntityT[]) => {
            setToBeAdded(selectedUsers);
          }}
          preselection={preselection}
          errorMessage=""
          isAcd={selectedAcdSupervisor}
        />

        <div className={styles['button-area']}>
          <CancelButton
            id="cancel-external-user-edit-button"
            className={styles['cancel-button']}
            label={t('users.personalDetails.cancel')}
            onClickAction={() => {
              setSelectedAcd(null);
              setSelectedAcdSupervisor(null);
            }}
            hideWhenViewing
          />
          <ActionButton
            id="save-external-user-changes-button"
            className={styles['save-button']}
            label={t('users.personalDetails.save')}
            hideWhenViewing
            loading={updating}
            onClickAction={async () => {
              if (selectedAcdSupervisor) {
                await patchSelectedSupervisor();
              } else {
                await patchSelectedAcd();
              }
            }}
          />
        </div>
        {toBeProcessed ? (
          <Modal
            ref={processModalRef}
            autoOpen
            className={styles['process-modal']}
            id="processModal"
            size="s"
            heading={t('callflows.acdSupervisors.processDialogTitle')}
            onModalClose={() => {
              stopProcessingAcdSupervisors();
            }}
            content={
              <div>
                {t('callflows.acdSupervisors.processDialogContent', {
                  processed,
                  toBeProcessed
                })}
              </div>
            }
            closeWithBackdropClick={false}
            buttons={[
              <Button
                key="btnCancel"
                id="btnCancel"
                size="l"
                color="link"
                onClick={() => {
                  if (processModalRef.current) {
                    processModalRef.current.closeModal();
                  }
                }}
              >
                {t('presenceCalendarListing.cancel')}
              </Button>
            ]}
          />
        ) : null}
      </div>
    );
  };

  return !selectedAcd && !selectedAcdSupervisor ? (
    <div className={styles.container}>
      <div>
        <h2>{t('callflows.acdSupervisors.pageTitle')}</h2>
        <div>{t('callflows.acdSupervisors.pageDescription')}</div>
      </div>
      <div className={styles['top-area']}>
        <div className={styles['top-area--sub']}>
          <RadioGroup
            value={displayAcdBy}
            name="displayBy"
            label={t('callflows.acdSupervisors.displayByLabel')}
          >
            <Radio
              value="byAcd"
              label={t('callflows.acdSupervisors.displayByOption1')}
              onChange={() => setDisplayAcdBy('byAcd')}
            />
            <Radio
              value="byAcdSupervisor"
              label={t('callflows.acdSupervisors.displayByOption2')}
              onChange={() => setDisplayAcdBy('byAcdSupervisor')}
            />
          </RadioGroup>
          <div className={styles['top-area--sub--input']}>
            <Input
              id="filterInput"
              label={t('callflows.acdSupervisors.filterLabel')}
              i18n_input_helpText={t('callflows.acdSupervisors.filterHelpText')}
              i18n_input_optionalText=""
              name="filterInput"
              type="search"
              placeholder=""
              optional
              onValueChange={event => {
                if (event) setFilterTerm(event.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <Button
            loading={exporting}
            onClick={() => exportAcdSupervisors()}
            icon={<IconDownloadRegular />}
          >
            {t('callflows.acdSupervisors.exportACDSupervisorsButton')}
          </Button>
        </div>
      </div>
      {displayAcdBy === 'byAcd' ? (
        <Table
          id="acdTable"
          useRowHeader
          hover
          columns={[
            { key: 'acdname', label: t('callflows.acdSupervisors.acdname') },
            { key: 'phonenumber', label: t('callflows.acdSupervisors.phonenumber') },
            { key: 'extension', label: t('callflows.acdSupervisors.extension') },
            { key: 'supervisors', label: t('callflows.acdSupervisors.supervisors') },
            { key: 'actions', label: '' }
          ]}
          rows={getAcdsRows()}
        />
      ) : (
        <Table
          id="acdSupervisorsTable"
          useRowHeader
          hover
          columns={[
            { key: 'supervisor', label: t('callflows.acdSupervisors.supervisors') },
            { key: 'email', label: t('callflows.acdSupervisors.email') },
            { key: 'extension', label: t('callflows.acdSupervisors.extension') },
            { key: 'acdname', label: t('callflows.acdSupervisors.acdname') },
            { key: 'actions', label: '' }
          ]}
          rows={getAcdSupervisorsRows()}
        />
      )}
    </div>
  ) : (
    editView()
  );
};

export default AcdSupervisors;
