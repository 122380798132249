// @flow
import React, { type Element, useEffect, useState } from 'react';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import * as R from 'ramda';
import moment from 'moment';
import type { FieldProps } from 'redux-form';
import { useParams } from 'react-router-dom';
import AudioFileSelection from '../../../callFlows/components/edit/children/audio/AudioFileSelection';
import type { VoiceMailEntityT } from '../../../../ducks/entities/voicemailTypes';
import type { TranslateT } from '../../../../commonTypes';
import Item from '../../../../components/Item';
import VoiceMailAudio from '../../../callFlows/components/edit/children/audio/VoiceMailAudio';
import type { VoiceMailAudioFieldT } from '../../../callFlows/components/edit/children/audio/CallflowAudioUtils';
import TemporaryGreetingDateSelector from '../../../callFlows/components/edit/children/TemporaryGreetingDateSelector/TemporaryGreetingDateSelector';
import styles from './UserVoiceMailAudioField.module.scss';

export type PropsT = FieldProps & {|
  voicemailSettings: VoiceMailEntityT,
  translate: TranslateT<string>,
  wrapperStyle: *,
  isSectionInEditMode: boolean,
  id: string,
  isSectionDisabled?: boolean,
  temporaryVoicemailSettings: *
|};

export const UserVoiceMailAudioField = (props: PropsT): Element<'div'> => {
  const {
    id,
    input,
    translate,
    isSectionInEditMode = false,
    wrapperStyle,
    voicemailSettings,
    temporaryVoicemailSettings
  } = props;
  const { id: enterpriseId, userId } = useParams();
  const hasAudioNameFile = !!R.path(
    ['audios', 'audioName', 'files', 'durationInSeconds'],
    voicemailSettings
  );
  const hasGreetingFile = !!R.path(
    ['audios', 'greeting', 'files', 'durationInSeconds'],
    voicemailSettings
  );

  const inputAudioLevel =
    input.value && input.value.voiceMailAudio ? input.value.voiceMailAudio.level : 'PhoneNumber';

  const [temporaryGreetingStartTime, setTemporaryGreetingStartTime] = useState(
    temporaryVoicemailSettings ? temporaryVoicemailSettings.startTimeInMillis : moment().valueOf()
  );
  const endTimeInMillis = temporaryVoicemailSettings
    ? temporaryVoicemailSettings.endTimeInMillis
    : input.value.endTimeInMillis;
  const [temporaryGreetingEndTime, setTemporaryGreetingEndTime] = useState(endTimeInMillis);

  const audioNameAudio = {
    enterpriseId: enterpriseId || '',
    callflowId: userId || '',
    audioType: 'voicemail',
    propertyName: 'AudioName',
    serviceType: 'User'
  };

  const greetingAudio = {
    enterpriseId: enterpriseId || '',
    callflowId: userId || '',
    audioType: 'voicemail',
    propertyName: 'Greeting',
    serviceType: 'User'
  };

  const temporaryGreetingAudio = {
    enterpriseId: enterpriseId || '',
    callflowId: userId || '',
    audioType: 'voicemail',
    propertyName: 'TemporaryGreeting',
    serviceType: 'User'
  };

  const voiceMailAudio: VoiceMailAudioFieldT = {
    enterpriseId: enterpriseId || '',
    callflowId: userId || '',
    audioType: 'voicemail',
    propertyName: inputAudioLevel,
    serviceType: 'User'
  };

  useEffect(() => {
    if (temporaryVoicemailSettings) {
      setTemporaryGreetingStartTime(temporaryVoicemailSettings.startTimeInMillis);
      setTemporaryGreetingEndTime(temporaryVoicemailSettings.endTimeInMillis);
    }
    input.onChange({
      ...input.value,
      startTimeInMillis: temporaryGreetingStartTime,
      endTimeInMillis:
        temporaryGreetingEndTime && temporaryGreetingEndTime !== '0'
          ? temporaryGreetingEndTime
          : moment()
              .add(1, 'day')
              .valueOf(),
      voiceMailAudio: {
        ...input.value.voiceMailAudio,
        isTemporaryVoicemailActive: !!temporaryVoicemailSettings
      }
    });
    return () => {};
  }, [temporaryVoicemailSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const getHelpText = () => {
    if (inputAudioLevel === 'PhoneNumber') {
      return translate('voicemailSettings.audioDefaultTooltip');
    }
    if (inputAudioLevel === 'AudioName') {
      return translate('voicemailSettings.audioAudioTooltip');
    }
    if (inputAudioLevel === 'Greeting') {
      return translate('voicemailSettings.audioGreetingTooltip');
    }
    if (inputAudioLevel === 'TemporaryGreeting') {
      return translate('voicemailSettings.temporaryGreetingTooltip', {
        lastSelection:
          voicemailSettings.audios.current.name &&
          voicemailSettings.audios.current.name !== 'TemporaryGreeting'
            ? translate(`voicemailSettings.${voicemailSettings.audios.current.name}`)
            : ''
      });
    }
    return '';
  };

  return (
    <div className={wrapperStyle} id={id}>
      {isSectionInEditMode && (
        <div>
          <div>
            <div className={styles['small-title']}>
              {translate('voicemailSettings.isVoiceMailMessageTitle')}
            </div>
            <Checkbox
              className={styles['no-message-checkbox']}
              value={!input.value.isVoiceMailNoMessage}
              data-cy="user-voice-mail-check"
              checked={!input.value.isVoiceMailNoMessage}
              onChange={() => {
                input.onChange({
                  ...input.value,
                  isVoiceMailNoMessage: !input.value.isVoiceMailNoMessage
                });
              }}
              label={translate('voicemailSettings.isVoiceMailMessage')}
            />
          </div>
          <div
            className={styles.container}
            role="group"
            aria-label="Message type"
            id="voicemail-type-selection"
          >
            <div className={styles['small-title']}>
              {translate('voicemailSettings.voicemailMessageTitle')}
            </div>
            <RadioGroup value={inputAudioLevel} i18n_radiogroup_helpText={getHelpText()}>
              <Radio
                name="voicemailMessage"
                label={translate('voicemailSettings.audioDefault')}
                value="PhoneNumber"
                onChange={(): void => {
                  input.onChange({
                    ...input.value,
                    voiceMailAudio: {
                      ...input.value.voiceMailAudio,
                      level: 'PhoneNumber'
                    }
                  });
                }}
              />
              <Radio
                name="voicemailMessage"
                value="AudioName"
                onChange={(): void => {
                  input.onChange({
                    ...input.value,
                    voiceMailAudio: {
                      ...input.value.voiceMailAudio,
                      level: 'AudioName'
                    }
                  });
                }}
                label={translate('voicemailSettings.audioAudioName')}
              />
              <Radio
                name="voicemailMessage"
                value="Greeting"
                onChange={(): void => {
                  input.onChange({
                    ...input.value,
                    voiceMailAudio: {
                      ...input.value.voiceMailAudio,
                      level: 'Greeting'
                    }
                  });
                }}
                label={translate('voicemailSettings.audioGreeting')}
              />
              <Radio
                name="voicemailMessage"
                value="TemporaryGreeting"
                onChange={(): void =>
                  input.onChange({
                    ...input.value,
                    voiceMailAudio: {
                      ...input.value.voiceMailAudio,
                      level: 'TemporaryGreeting'
                    }
                  })
                }
                label={translate('voicemailSettings.audioTemporaryGreeting')}
              />
            </RadioGroup>
            {inputAudioLevel === 'AudioName' && (
              <AudioFileSelection
                audio={audioNameAudio}
                onChange={file =>
                  input.onChange({
                    ...input.value,
                    voiceMailAudio: {
                      ...input.value.voiceMailAudio,
                      audioNameFileToImport: file
                    }
                  })
                }
                showInitialView={hasAudioNameFile}
                disabled={false}
                title={translate('voicemailSettings.audioAudioName')}
              />
            )}
            {inputAudioLevel === 'Greeting' && (
              <AudioFileSelection
                className={styles.audio}
                audio={greetingAudio}
                onChange={file =>
                  input.onChange({
                    ...input.value,
                    voiceMailAudio: {
                      ...input.value.voiceMailAudio,
                      greetingAudioToImport: file
                    }
                  })
                }
                showInitialView={hasGreetingFile}
                disabled={false}
                title={translate('voicemailSettings.audioGreeting')}
              />
            )}
            <TemporaryGreetingDateSelector
              infoVisible={
                inputAudioLevel !== 'TemporaryGreeting' &&
                !!temporaryVoicemailSettings &&
                !input.value.voiceMailAudio.removeTemporaryGreeting
              }
              visible={inputAudioLevel === 'TemporaryGreeting'}
              temporaryGreetingAudio={temporaryGreetingAudio}
              voicemailSettings={voicemailSettings}
              updateRemoveTemporaryGreeting={value =>
                input.onChange({
                  ...input.value,
                  voiceMailAudio: {
                    ...input.value.voiceMailAudio,
                    removeTemporaryGreeting: value
                  }
                })
              }
              updateAudioFileSelection={file =>
                input.onChange({
                  ...input.value,
                  voiceMailAudio: {
                    ...input.value.voiceMailAudio,
                    temporaryGreetingAudioToImport: file
                  }
                })
              }
              updateIsTemporaryVoicemailActive={isManual => {
                input.onChange({
                  ...input.value,
                  voiceMailAudio: {
                    ...input.value.voiceMailAudio,
                    isTemporaryVoicemailActive: isManual
                  }
                });
              }}
              updateStartDate={dateTime => {
                input.onChange({
                  ...input.value,
                  startTimeInMillis: dateTime,
                  endTimeInMillis: temporaryGreetingEndTime,
                  voiceMailAudio: {
                    ...input.value.voiceMailAudio
                  }
                });
                setTemporaryGreetingStartTime(dateTime);
              }}
              updateEndDate={dateTime => {
                input.onChange({
                  ...input.value,
                  startTimeInMillis: temporaryGreetingStartTime,
                  endTimeInMillis: dateTime,
                  voiceMailAudio: {
                    ...input.value.voiceMailAudio
                  }
                });
                setTemporaryGreetingEndTime(dateTime);
              }}
              updateAudioLevel={level => {
                input.onChange({
                  ...input.value,
                  voiceMailAudio: {
                    ...input.value.voiceMailAudio,
                    level
                  }
                });
              }}
              active={!!temporaryVoicemailSettings}
              startTimeInMillis={parseInt(temporaryGreetingStartTime, 10)}
              endTimeInMillis={
                parseInt(temporaryGreetingEndTime, 10) ||
                moment()
                  .add(1, 'day')
                  .valueOf()
              }
            />
          </div>
        </div>
      )}
      {!isSectionInEditMode && input.value && (
        <div>
          <Item
            id="isVoiceMailNoMessage"
            label={translate('voicemailSettings.isVoiceMailMessage')}
            value={
              input.value.isVoiceMailNoMessage
                ? translate('voicemailSettings.voiceMailNotInUse')
                : translate('voicemailSettings.voiceMailInUse')
            }
          />
          <VoiceMailAudio
            active
            title={translate('voicemailSettings.title')}
            audioData={voiceMailAudio}
            noAudioLabel={
              inputAudioLevel === 'PhoneNumber' ? translate('voicemailSettings.audioDefault') : ''
            }
          />
        </div>
      )}
    </div>
  );
};

export default UserVoiceMailAudioField;
