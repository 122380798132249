// @flow

import React, { Component, type Element, type ElementRef } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { CancelToken, type Canceler } from 'axios';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import type { FormProps } from 'redux-form';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import parse from 'html-react-parser';
import type { StoreStateT } from '../../commonTypes';
import { selectors as callFlowSelect } from '../../ducks/entities/callFlow';
import {
  retrieveCollection,
  pooledBatchRetrieve
} from '../../ducks/entities/callFlow/callFlowOperations';
import { createClearCallFlows } from '../../ducks/entities/callFlow/callFlowActions';
import ExpandableCallFlowGrid from './callFlowGrid/ExpandableCallFlowGrid';
import OptionGroup from '../../components/OptionGroup/OptionGroup';
import Option from '../../components/OptionGroup/Option';
import BlocksModeIcon from '../../components/TableIcons/BlocksModeIcon';
import TableModeIcon from '../../components/TableIcons/TableModeIcon';
import CallFlowList from './callFlowList/CallFlowList';
import { Desktop } from '../../Responsive';
import ErrorBoundary from '../../components/Error/ErrorBoundary';
import GenericError from '../../components/Error/GenericError';
import type { CurrentUserStateT } from '../../ducks/currentUser';
import type { ViewModeT } from '../../ducks/ui/callflow/callflowUiTypes';
import {
  createUpdateEditStatus,
  createResetGridView,
  createSetCategory,
  createSetViewMode,
  createSetSearchTerm
} from '../../ducks/ui/callflow/callflowUiActions';
import type {
  CreateResetGridViewFnT,
  CreateSetSearchTermFnT,
  CreateSetViewModeFnT
} from '../../ducks/ui/callflow/callflowUiActions';
import { InputField } from '../../components/InputComponent/ReduxFormField';
import type { CallFlowEntityT } from '../../ducks/entities/callFlow';
import type { CallFlowTypeT } from '../../ducks/entities/callFlow/callFlowTypes';
import { createSelectEnterpriseAction } from '../../ducks/currentUser/currentUserActions';
import { ReactComponent as NoCallFlowsIcon } from '../../assets/callflow/no-callflows.svg';
import ActionButton from '../../components/Button/ActionButton';
import ExportCallflowsModal from './exportServices/ExportCallflowsModal';
import CenterHorizontally from '../../components/CenterHorizontally/CenterHorizontally';
import CreateButton from '../../components/Button/CreateButton';
import { goToCreateCallflowService } from '../../navigationOperations';
import { isAdmin } from '../../utils/accessRightUtils';
import { searchCallFlows } from './components/CallFlowUtils';
import styles from './CallFlowServices.module.scss';

type OwnPropsT = {|
  noDebounce?: boolean,
  enterpriseId: string
|};

type StatePropsT = {|
  currentUser: CurrentUserStateT,
  isRetrieving: boolean,
  hasLoadError: boolean,
  viewMode: ViewModeT,
  callflows: CallFlowEntityT[],
  activeLanguage: string,
  selectedCategory: *,
  searchTerm: string,
  searchWord: string,
  category: *
|};

type DispatchPropsT = {
  getCallFlows: typeof retrieveCollection,
  setViewMode: CreateSetViewModeFnT,
  setSearchTerm: CreateSetSearchTermFnT,
  clear: () => void,
  resetGridView: CreateResetGridViewFnT,
  goToCreateCallflowService: typeof goToCreateCallflowService,
  updateEditStatus: typeof createUpdateEditStatus,
  setCategory: typeof createSetCategory,
  getCallFlowsAsBatch: typeof pooledBatchRetrieve
};

export type StateT = {
  innerWidth: number,
  categorySelectionOptions: { value: CallFlowTypeT, label: string }[],
  userChangedViewMode: boolean,
  isSearching: boolean,
  showExportCallflowsModal: boolean,
  isRetrievingCallFlows: boolean
};
export type PropsT = {|
  // $FlowFixMe
  ...FormProps,
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};

const CALL_FLOW_BATCH_RETRIEVE_POOL_SIZE = 3;
const CALLFLOW_TYPES: CallFlowTypeT[] = [
  'ACD_CUSTOMER_SERVICE',
  'ACD_SWITCHBOARD',
  'PLAY_MUSIC',
  'EXTENSION_GROUP',
  ...(process.env.REACT_APP_ENV !== 'production' ? ['SPEED_DIAL', 'OC', 'WELCOME_ATTENDANT'] : [])
];
const CALLFLOW_TYPES_CATEGORIES: CallFlowTypeT[] = [
  'ACD_CUSTOMER_SERVICE',
  'ACD_SWITCHBOARD',
  'PLAY_MUSIC',
  'EXTENSION_GROUP',
  'SPEED_DIAL',
  'WELCOME_ATTENDANT',
  'OC'
];
const ACD_MANAGER_CATEGORIES: CallFlowTypeT[] = ['ACD_CUSTOMER_SERVICE', 'ACD_SWITCHBOARD'];
const CALL_FLOWS_MAX_SIZE = CALL_FLOW_BATCH_RETRIEVE_POOL_SIZE * 1000;
const SEARCH_DEBOUNCE_DURATION = 250;

export class CallFlowServices extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.state = {
      innerWidth: 0,
      categorySelectionOptions: [],
      userChangedViewMode: false,
      isSearching: true,
      isRetrievingCallFlows: false,
      showExportCallflowsModal: false
    };
    this.handleOnResize = this.handleOnResize.bind(this);
    this.showGridView = this.showGridView.bind(this);
    this.showListView = this.showListView.bind(this);
    this.handleSearchInputChanged = this.handleSearchInputChanged.bind(this);
    this.handleCategoryChanged = this.handleCategoryChanged.bind(this);
    this.generateCategorySelectionMap = this.generateCategorySelectionMap.bind(this);
    this.cleanUp = this.cleanUp.bind(this);
    this.loadCallflows = this.loadCallflows.bind(this);
    this.IsInGridMode = this.IsInGridMode.bind(this);
    this.renderView = this.renderView.bind(this);
    this.mounted = React.createRef();
    this.batchRequestCancelers = {};
  }

  mounted: { current: null | boolean };

  async componentDidMount() {
    const { selectedCategory, searchWord } = this.props;
    window.addEventListener('resize', this.handleOnResize);
    this.mounted.current = true;
    this.handleOnResize();
    this.generateCategorySelectionMap();
    this.props.change('categoryField', selectedCategory);
    this.props.change('searchField', searchWord);
    this.showGridView();
  }

  // eslint-disable-next-line camelcase
  componentDidUpdate(prevProps: PropsT) {
    const { activeLanguage: oldActiveLanguage } = prevProps;
    const {
      activeLanguage: newActiveLanguage,
      selectedCategory: newSelectedCategory,
      setCategory,
      category,
      setSearchTerm
    } = this.props;
    if (oldActiveLanguage !== newActiveLanguage) {
      this.generateCategorySelectionMap();
    }
    if (newSelectedCategory && category !== newSelectedCategory.value) {
      setCategory(newSelectedCategory.value);
      setSearchTerm('');
      this.loadCallflows(1, false, '', newSelectedCategory.value, category);
    }
  }

  componentWillUnmount() {
    this.mounted.current = false;
    window.removeEventListener('resize', this.handleOnResize);
    this.cleanUp();
  }

  IsInGridMode: () => boolean;

  IsInGridMode() {
    const { viewMode, currentUser } = this.props;
    const { userChangedViewMode, innerWidth } = this.state;
    const tableByDefault = !currentUser.hasMultipleCallflowServices && !userChangedViewMode;
    const nonDefaultAndIsInGridMode = viewMode === 'grid';
    const gridSizedScreen = innerWidth > parseInt(styles.laptopMinWidth, 10);
    return (tableByDefault || nonDefaultAndIsInGridMode) && gridSizedScreen;
  }

  loadCallflows: (
    number,
    boolean,
    string,
    CallFlowTypeT,
    ?CallFlowTypeT | void,
    ?boolean
  ) => Promise<void>;

  async loadCallflows(
    page: number,
    includeSingles: boolean,
    search: string,
    newCategoryKey: CallFlowTypeT,
    oldCategoryKey?: CallFlowTypeT | void
  ) {
    const {
      getCallFlows,
      getCallFlowsAsBatch,
      enterpriseId,
      noDebounce,
      updateEditStatus
    } = this.props;
    const getCallFlowsDebounced = AwesomeDebouncePromise(getCallFlows, SEARCH_DEBOUNCE_DURATION);
    if (this.summaryCanceler && (oldCategoryKey || (search && search.length > 0))) {
      this.summaryCanceler();
    }
    const cancelTokenForPreviousQueries = new CancelToken(canceler => {
      this.summaryCanceler = canceler;
      if (page < 2) {
        (this.batchRequestCancelers[newCategoryKey] || []).forEach(cancel => cancel());
      }
    });
    const getCallFlowParams = [
      enterpriseId,
      cancelTokenForPreviousQueries,
      newCategoryKey,
      {
        page,
        size: CALL_FLOWS_MAX_SIZE,
        search: newCategoryKey === 'ACD_SWITCHBOARD' ? `kutsu:` : ''
      }
    ];
    const callFlows = searchCallFlows(
      noDebounce
        ? await getCallFlows(...getCallFlowParams)
        : await getCallFlowsDebounced(...getCallFlowParams),
      search
    );

    if (callFlows && includeSingles) {
      callFlows.forEach(cf => updateEditStatus(cf.id, false));
      await getCallFlowsAsBatch(
        enterpriseId,
        new CancelToken(canceler => {
          this.batchRequestCancelers[newCategoryKey] = [
            ...(newCategoryKey in this.batchRequestCancelers
              ? this.batchRequestCancelers[newCategoryKey]
              : []),
            canceler
          ];
        }),
        callFlows.map(cf => ({
          callFlowId: cf.id,
          callFlowType: cf.type
        }))
      );
    }

    if (callFlows && this.mounted.current) {
      this.setState({ isSearching: false });
    }
  }

  handleOnResize: () => void;

  handleOnResize() {
    const { innerWidth } = window;
    const { setViewMode } = this.props;

    if (
      innerWidth > parseInt(styles.laptopMinWidth, 10) &&
      innerWidth < parseInt(styles.desktopMinWidth, 10)
    ) {
      this.setState({ innerWidth, userChangedViewMode: true });
      setViewMode('list');
    } else {
      this.setState({ innerWidth });
    }
  }

  cleanUp: () => void;

  cleanUp() {
    const { resetGridView } = this.props;
    resetGridView();
    this.summaryCanceler();
    CALLFLOW_TYPES.forEach(type => {
      if (type in this.batchRequestCancelers) {
        this.batchRequestCancelers[type].forEach(canceler => canceler());
      }
    });
    this.props.clear();
  }

  showListView: () => void;

  showListView() {
    const { setViewMode, clear, selectedCategory, searchWord, resetGridView } = this.props;
    resetGridView();
    clear();
    this.setState({ userChangedViewMode: true });
    setViewMode('list');
    this.loadCallflows(1, false, searchWord, selectedCategory.value);
  }

  showGridView: () => void;

  showGridView() {
    const { setViewMode, clear, selectedCategory, searchWord } = this.props;
    clear();
    this.setState({ userChangedViewMode: true });
    setViewMode('grid');
    this.loadCallflows(1, false, searchWord, selectedCategory.value);
  }

  // $FlowFixMe
  userForwardingRef: ElementRef<*>;

  handleSearchInputChanged: string => void;

  handleSearchInputChanged(searchTerm: string) {
    const { setSearchTerm } = this.props;
    setSearchTerm(searchTerm);
  }

  handleCategoryChanged: () => void;

  handleCategoryChanged() {
    this.setState({ isSearching: true });
  }

  generateCategorySelectionMap: () => void;

  generateCategorySelectionMap() {
    const { t, currentUser } = this.props;
    if (isAdmin(currentUser)) {
      let categorySelectionOptions = CALLFLOW_TYPES_CATEGORIES.map(type => ({
        value: type,
        label: t(`callflows.searchCategoryStatuses.${type}`)
      }));
      if ((currentUser.featureFlags || []).includes('FEATURE-HIDE-CALLFLOW-WELCOMEATTENDANT')) {
        categorySelectionOptions = categorySelectionOptions.filter(
          type => type.value !== 'WELCOME_ATTENDANT'
        );
      }
      if ((currentUser.featureFlags || []).includes('FEATURE-HIDE-CALLFLOW-PLAYMUSIC')) {
        categorySelectionOptions = categorySelectionOptions.filter(
          type => type.value !== 'PLAY_MUSIC'
        );
      }
      this.setState({ categorySelectionOptions });
    } else {
      const acdCategories = ACD_MANAGER_CATEGORIES.map(type => ({
        value: type,
        label: t(`callflows.searchCategoryStatuses.${type}`)
      }));
      this.setState({ categorySelectionOptions: acdCategories });
    }
  }

  summaryCanceler: Canceler;

  batchRequestCancelers: { [CallFlowTypeT]: Canceler[] };

  renderResults: () => Element<'div'>;

  renderResults() {
    const { enterpriseId, isRetrieving, hasLoadError, searchTerm, category } = this.props;
    const { innerWidth } = this.state;
    return (
      <div id="callflowsArea" className={styles['call-flows-container']}>
        {this.IsInGridMode() ? (
          <ExpandableCallFlowGrid
            enterpriseId={enterpriseId}
            selectedCategory={category}
            loadMore={page => this.loadCallflows(page, false, searchTerm, category)}
            key={category}
            searchTerm={searchTerm}
          />
        ) : (
          <CallFlowList
            enterpriseId={enterpriseId}
            isSmallTable={innerWidth <= parseInt(styles.laptopMinWidth, 10)}
            selectedCategory={category}
            loadMore={page => this.loadCallflows(page, false, searchTerm, category)}
            searchTerm={searchTerm}
          />
        )}
        {isRetrieving && !hasLoadError && (
          <CenterHorizontally style={{ marginTop: '20px' }}>
            <LoadingSpinner />
          </CenterHorizontally>
        )}
      </div>
    );
  }

  renderNoResults: () => Element<'div'>;

  renderNoResults() {
    const { t, searchTerm } = this.props;
    return (
      <div id="callflowsArea" className={styles['no-callflows-area']}>
        <div className={styles['image-area']}>
          <NoCallFlowsIcon />
        </div>
        {searchTerm && searchTerm.length > 0 ? (
          <div className={styles['text-area']}>
            <div className={styles['title-text']}>{t('callflows.empty.search')}</div>
          </div>
        ) : (
          <div className={styles['text-area']}>
            <div className={styles['title-text']}>
              {parse(`${t('callflows.empty.title1')}<br>${t('callflows.empty.title2')}`)}
            </div>
            <div className={styles['info-text']}>
              {parse(`${t('callflows.empty.info1')}<br>${t('callflows.empty.info2')}`)}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderView: () => Element<'div'>;

  renderView() {
    const { isRetrieving, callflows } = this.props;
    const { isSearching } = this.state;
    return callflows.length > 0 || isRetrieving || isSearching
      ? this.renderResults()
      : this.renderNoResults();
  }

  render(): Element<*> | null {
    const {
      t,
      enterpriseId,
      hasLoadError,
      viewMode,
      currentUser,
      selectedCategory,
      searchTerm,
      callflows,
      goToCreateCallflowService // eslint-disable-line no-shadow
    } = this.props;
    const {
      categorySelectionOptions,
      showExportCallflowsModal,
      isRetrievingCallFlows
    } = this.state;
    const showExportFeature = currentUser
      ? !(currentUser.featureFlags || []).includes('FEATURE-HIDE-CALLFLOW-EXPORT')
      : false;
    const showCreateCallflow = currentUser
      ? !(currentUser.featureFlags || []).includes('FEATURE-HIDE-CALLFLOW-CREATE') &&
        isAdmin(currentUser)
      : false;
    const showAcdExportButton =
      isAdmin(currentUser) &&
      (selectedCategory.value === 'ACD_CUSTOMER_SERVICE' ||
        selectedCategory.value === 'ACD_SWITCHBOARD') &&
      showExportFeature;

    const visibleCallflowIds = (callflows || [])
      .filter(cf => cf && cf.type === selectedCategory.value)
      .map(cf => ({ id: cf.id, callFlowType: cf.type }));

    return categorySelectionOptions.length > 0 ? (
      <>
        <div className={styles.title}>{t('callflows.title')}</div>
        <div id="call-flows-page" className={styles['content-container']}>
          <h4>{t('callflows.flows.title')}</h4>
          <div className={styles['section-description']}>
            <div>{t('callflows.flows.description')}</div>
            {showCreateCallflow && (
              <div className={styles['section-description--button']}>
                <CreateButton
                  id="create-user-callforwarding-button"
                  text={t('callflows.flows.order')}
                  onClickAction={() => goToCreateCallflowService(enterpriseId)}
                  showTextAlways
                />
              </div>
            )}
          </div>

          <ErrorBoundary
            showError={hasLoadError}
            errorElement={<GenericError showReloadLink message={t('users.userListFailedMsg')} />}
          >
            <div id="searchContainer" className={styles['search-container']}>
              <div className={styles['search-area']}>
                <div id="searchName" className={styles['search-name']}>
                  <Field
                    id="search-callflows-field"
                    name="searchField"
                    placeholder={t('callflows.searchFieldPlaceholder')}
                    onChange={e => {
                      // $FlowFixMe
                      if (e && e.currentTarget) {
                        this.handleSearchInputChanged(e.currentTarget.value);
                      }
                    }}
                    value={searchTerm}
                    maxlength={50}
                    component={InputField}
                    type="search"
                    optional
                    i18n_input_optionalText=""
                  />
                </div>
                <Dropdown
                  id="callflows-category-field"
                  name="categoryField"
                  className={styles['category-field']}
                  onValueChange={e => {
                    this.props.change('categoryField', { value: e.dataset.value });
                    this.handleCategoryChanged();
                  }}
                  items={categorySelectionOptions}
                  selectedValue={selectedCategory.value}
                />
                <Desktop>
                  {showAcdExportButton && (
                    <ActionButton
                      label="export"
                      id="export-services"
                      loading={isRetrievingCallFlows}
                      onClickAction={async e => {
                        e.preventDefault();
                        this.setState({ isRetrievingCallFlows: true });
                        await this.loadCallflows(
                          0,
                          false,
                          '',
                          selectedCategory.value,
                          selectedCategory.value,
                          true
                        );
                        this.setState({
                          showExportCallflowsModal: true,
                          isRetrievingCallFlows: false
                        });
                      }}
                      className={styles['export-button']}
                    />
                  )}
                  {showExportCallflowsModal ? (
                    <ExportCallflowsModal
                      enterpriseId={enterpriseId}
                      callflowIds={visibleCallflowIds}
                      onClose={() => this.setState({ showExportCallflowsModal: false })}
                    />
                  ) : null}
                </Desktop>
              </div>
              <div id="searchViewMode" className={styles['search-view-mode']}>
                <Desktop>
                  <div className={styles.switcher}>
                    <OptionGroup
                      key={viewMode}
                      default={viewMode}
                      onOptionChange={name => {
                        if (name === viewMode) {
                          return;
                        }
                        if (name === 'grid') {
                          this.showGridView();
                        } else {
                          this.showListView();
                        }
                      }}
                    >
                      <Option
                        name="grid"
                        disabled={false}
                        id="enterprises-blocks-mode-select"
                        render={BlocksModeIcon}
                      />
                      <Option
                        name="list"
                        id="enterprises-list-mode-select"
                        render={TableModeIcon}
                      />
                    </OptionGroup>
                  </div>
                </Desktop>
              </div>
            </div>
            {this.renderView()}
          </ErrorBoundary>
        </div>
      </>
    ) : null;
  }
}

const mapStateToProps = (state: StoreStateT, ownProps: PropsT) => {
  const { enterpriseId, t, i18n } = ownProps;
  const { category } = state.ui.callflow;
  return {
    initialValues: {
      searchField: state.ui.callflow.searchTerm,
      categoryField: {
        value: category,
        label: t(`callflows.searchCategoryStatuses.${category}`)
      },
      enterpriseId
    },
    callflows: callFlowSelect
      .getCallFlowsByEnterpriseIdAndSearchFilter(
        state,
        enterpriseId,
        state.ui.callflow.searchTerm,
        category
      )
      .sort((a, b) => parseInt(a.addressNumber, 10) - parseInt(b.addressNumber, 10)),
    currentUser: state.currentUser,
    isRetrieving: callFlowSelect.isRetrieving(state),
    hasLoadError: callFlowSelect.hasLoadError(state),
    viewMode: state.ui.callflow.viewMode,
    searchTerm: state.ui.callflow.searchTerm,
    category,
    selectedCategory: formValueSelector('callflowsSearch')(state, 'categoryField') || {
      value: category,
      label: t(`callflows.searchCategoryStatuses.${category}`)
    },
    searchWord: formValueSelector('callflowsSearch')(state, 'searchField'),
    activeLanguage: i18n.language
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCallFlows: retrieveCollection,
      getCallFlowsAsBatch: pooledBatchRetrieve,
      selectEnterprise: createSelectEnterpriseAction,
      setViewMode: createSetViewMode,
      updateEditStatus: createUpdateEditStatus,
      resetGridView: createResetGridView,
      setCategory: createSetCategory,
      setSearchTerm: createSetSearchTerm,
      clear: createClearCallFlows,
      goToCreateCallflowService
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'callflowsSearch', enableReinitialize: true })
)(CallFlowServices);
