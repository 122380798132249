export const searchCallFlows = (callFlows, searchTerm) => {
  if (searchTerm && callFlows) {
    // eslint-disable-next-line no-param-reassign
    callFlows = callFlows
      .filter(result => {
        const stringFields = [result.name || '', result.label || '', result.addressNumber || ''];
        if (result.pnNumbers && Array.isArray(result.pnNumbers)) {
          stringFields.push(...result.pnNumbers);
        }
        return stringFields.some(field =>
          field.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        );
      })
      .sort((a, b) => parseInt(a.addressNumber, 10) - parseInt(b.addressNumber, 10));
  }
  return callFlows;
};

export const GroupHistoryCallBackOptions = {
  ACDControlled: 'acd_controlled',
  Disabled: 'disabled',
  ACDAgents: 'acd_agents',
  ACDAgentsAndSwitchboardOperators: 'acd_agents_and_switchboard_operators'
};

export const getGroupHistoryCallbackValue = (groupHistoryCallback, acdSettings) => {
  if (
    groupHistoryCallback === GroupHistoryCallBackOptions.ACDAgents ||
    groupHistoryCallback === GroupHistoryCallBackOptions.ACDAgentsAndSwitchboardOperators
  ) {
    return true;
  }
  return !!(acdSettings && acdSettings.groupHistoryCallback);
};
