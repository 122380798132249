// @flow

import * as R from 'ramda';
import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';
import classnames from 'classnames';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import TabNavigation from '@design-system/component-library/src/components/TabNavigation';
import IconCheckRegular from '@design-system/component-library/src/components/Icon/lib/IconCheckRegular';
import IconArrowLeftAltRegular from '@design-system/component-library/src/components/Icon/lib/IconArrowLeftAltRegular';
import { Button } from '@design-system/component-library';
import { createCloseChangelogAction } from '../../ducks/ui/header/headerUiActions';
import { BaseModal } from '../../components/BaseModal';
import { operations } from '../../ducks/config';
import {
  getSortedAndFilteredBulletins,
  getChangelogKey,
  markReadBulletins
} from '../login/LoginUtil';
import { getConfigurationValue } from '../../userConfigHelpers';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import styles from './ChangelogModal.module.scss';

type PropsT = {||};

// eslint-disable-next-line no-unused-vars
export const ChangelogModal = (props: PropsT): Element<typeof BaseModal> | null => {
  // redux
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const changelogOpen: boolean = useSelector(state => state.ui.header.changelogOpen);
  const goToBulletinId: number = useSelector(state => state.ui.header.goToBulletinId);
  const previewMessageFi: ?string = useSelector(state => state.ui.header.previewMessageFi);
  const previewMessageEn: ?string = useSelector(state => state.ui.header.previewMessageEn);
  const previewMessageSv: ?string = useSelector(state => state.ui.header.previewMessageSv);
  const previewMessageEt: ?string = useSelector(state => state.ui.header.previewMessageEt);
  const userConfig = useSelector(state => state.config.userConfig);
  const currentUser = useSelector(state => state.currentUser);
  const bulletins = useSelector(state => state.config.bulletins);
  const activeLanguage = i18n.language;
  const [selectedBulletin, setSelectedBulletin] = useState(undefined); // undefined = not set, null = open bulletin explicitly closed

  const isPreview = () =>
    previewMessageFi !== undefined ||
    previewMessageEn !== undefined ||
    previewMessageSv !== undefined ||
    previewMessageEt !== undefined;

  useEffect(() => {
    const fetchUserConfig = async () => {
      const userCfg = await dispatch(operations.getUserConfig());
      const currentChangelogDisplayedValue = getConfigurationValue(
        userCfg,
        getChangelogKey(`${currentUser.environment}${currentUser.country || ''}`)
      );
      const allBulletins = await dispatch(operations.getBulletins());
      markReadBulletins(allBulletins, currentChangelogDisplayedValue);
    };
    if (!isPreview()) {
      fetchUserConfig();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const findHeadlineFromMessage = (msg: ?string, includeTags: boolean): string => {
    if (!msg || !(msg instanceof String || typeof msg === 'string')) {
      return '';
    }
    let headLineStart = msg.indexOf('<h');
    let headLineEnd = msg.indexOf('</h') + 5;
    if (!includeTags) {
      headLineStart += 4;
      headLineEnd -= 5;
    }
    if (headLineStart >= 0 && headLineEnd > headLineStart) {
      const foundHeadline = msg.substring(headLineStart, headLineEnd).trim();
      return foundHeadline;
    }
    return '';
  };

  const getMessageInSelectedLanguage = (bulletin): ?string => {
    if (bulletin) {
      if (activeLanguage === 'fi') return bulletin.messageFi;
      if (activeLanguage === 'en') return bulletin.messageEn;
      if (activeLanguage === 'sv') return bulletin.messageSv;
      if (activeLanguage === 'et') return bulletin.messageEt;
    }
    return '';
  };

  const getPreviewMessageInSelectedLanguage = (): ?string => {
    if (activeLanguage === 'fi') return previewMessageFi;
    if (activeLanguage === 'en') return previewMessageEn;
    if (activeLanguage === 'sv') return previewMessageSv;
    if (activeLanguage === 'et') return previewMessageEt;
    return '';
  };

  const handleOnClose = async (): Promise<void> => {
    setSelectedBulletin(undefined);
    dispatch(createCloseChangelogAction());
  };

  const filteredBulletins = getSortedAndFilteredBulletins(currentUser, bulletins);

  for (let i = 0; i < filteredBulletins.length; i++) {
    if (!filteredBulletins[i].headline) {
      filteredBulletins[i].headline = findHeadlineFromMessage(
        getMessageInSelectedLanguage(filteredBulletins[i]),
        false
      );
    }
  }

  const markAsRead = b => {
    if (!b || !b.id || !bulletins) {
      return;
    }

    // on underlying state array
    const bulletinsRead = [];
    for (let i = 0; i < bulletins.length && b; i++) {
      if (bulletins[i].id === b.id) {
        if (bulletins[i].read) {
          // already marked as read - dont touch
          return;
        }
        bulletins[i].read = true;
      }
      if (bulletins[i].read) {
        bulletinsRead.push(bulletins[i].id);
      }
    }

    // backend
    const changelogDisplayedKey = getChangelogKey(
      `${currentUser.environment}${currentUser.country || ''}`
    );
    dispatch(
      operations.updateUserConfig(
        [
          ...R.reject(item => 'key' in item && item.key === changelogDisplayedKey, userConfig),
          { key: changelogDisplayedKey, value: bulletinsRead.join(',') }
        ],
        createCsrfHeader(currentUser)
      )
    );
  };

  let message = '';
  if (isPreview()) {
    message = getPreviewMessageInSelectedLanguage();
    // bulletin.activationDate = new Date();
  } else if (changelogOpen) {
    if (selectedBulletin === undefined) {
      if (goToBulletinId) {
        setSelectedBulletin(
          filteredBulletins.find(b => {
            return b.id === goToBulletinId;
          })
        );
      } else if (filteredBulletins.length > 0) {
        setSelectedBulletin(filteredBulletins[0]);
      }
    }

    markAsRead(selectedBulletin);
    message = getMessageInSelectedLanguage(selectedBulletin);
  }

  let headline = '';
  let unreadCount = 0;
  if (changelogOpen) {
    headline = findHeadlineFromMessage(message, true);
    if (headline && message) message = message.replace(headline, '');
    unreadCount = filteredBulletins.filter(b => b.read === false).length;
  }

  const nextBulletin = () => {
    const index = filteredBulletins.indexOf(selectedBulletin);
    setSelectedBulletin(filteredBulletins[index - 1]);
  };

  const prevBulletin = () => {
    const index = filteredBulletins.indexOf(selectedBulletin);
    setSelectedBulletin(filteredBulletins[index + 1]);
  };

  const renderBulletinSelectionFor = b => {
    return (
      <Button
        key={`select_bulletin_${b.id ? b.id : ''}`}
        className={classnames(
          styles['changelog-browser-item'],
          selectedBulletin && selectedBulletin.id === b.id
            ? styles['changelog-browser-item-selected']
            : ''
        )}
        block
        onClick={() => setSelectedBulletin(b)}
      >
        <span
          className={classnames(
            styles['changelog-browser-item-content'],
            !b.read ? styles.unread : ''
          )}
        >
          <span className={styles.headline}>{b.headline}</span>
          <span>{Moment(b.activationDate).format('DD.MM.Y')}</span>
        </span>
      </Button>
    );
  };

  return changelogOpen ? (
    <BaseModal modalStyles={[styles.modal]} onClose={() => handleOnClose()}>
      <>
        <h3 className={classnames(selectedBulletin ? styles['hide-on-mobile'] : '')}>
          {isPreview() ? t('adminui.previewButton') : t('helpDropdown.changelog')}
        </h3>
        {selectedBulletin && (
          <Button
            color="link"
            onClick={() => setSelectedBulletin(null)}
            className={styles['hide-unless-mobile']}
          >
            <IconArrowLeftAltRegular />
          </Button>
        )}
        <div className={styles['changelog-container']}>
          {!isPreview() && (
            <div
              className={classnames(
                styles['changelog-browser'],
                selectedBulletin ? styles['hide-on-mobile'] : ''
              )}
            >
              <TabNavigation id="changeLogItemTabs" className={styles['tab-navigation']}>
                <TabNavigation.Tab title={t('changelog.all')} selected>
                  <div className={styles['changelog-items']}>
                    {filteredBulletins.map(b => renderBulletinSelectionFor(b))}
                  </div>
                </TabNavigation.Tab>
                <TabNavigation.Tab title={`${t('changelog.unread')} (${unreadCount})`}>
                  <div className={styles['changelog-items']}>
                    {filteredBulletins
                      .filter(b => b.read === false)
                      .map(b => renderBulletinSelectionFor(b))}
                    {unreadCount === 0 && (
                      <div className={styles['no-unread-items']}>
                        <IconCheckRegular />
                        <br />
                        {t('changelog.noUnread')}
                      </div>
                    )}
                  </div>
                </TabNavigation.Tab>
              </TabNavigation>
            </div>
          )}
          <div
            className={classnames(
              styles['right-column'],
              !selectedBulletin && !isPreview() ? styles['hide-on-mobile'] : ''
            )}
          >
            <div className={styles['text-container']}>
              {selectedBulletin?.activationDate && (
                <span>{Moment(selectedBulletin?.activationDate).format('DD.MM.Y')}</span>
              )}
              <div className={styles.header}>{headline ? parse(headline) : ''}</div>
              <div className={styles['text-content']}>{message ? parse(message) : ''}</div>
            </div>
            <div className={styles['bottom-area']}>
              <div>
                {!isPreview() ? (
                  <>
                    <Button
                      id="changelog-previous-button"
                      color="light"
                      disabled={
                        !selectedBulletin ||
                        filteredBulletins.length < 2 ||
                        selectedBulletin.id === filteredBulletins[filteredBulletins.length - 1].id
                      }
                      onClick={() => {
                        prevBulletin();
                      }}
                    >
                      {t('changelog.previous')}
                    </Button>
                    <Button
                      id="changelog-next-button"
                      color="light"
                      disabled={
                        !selectedBulletin ||
                        filteredBulletins.length < 2 ||
                        selectedBulletin.id === filteredBulletins[0].id
                      }
                      onClick={() => {
                        nextBulletin();
                      }}
                    >
                      {t('changelog.next')}
                    </Button>
                  </>
                ) : (
                  <Button id="close-button" color="light" onClick={() => handleOnClose()}>
                    {t('changelog.ok')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </BaseModal>
  ) : null;
};

export default ChangelogModal;
