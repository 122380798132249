// @flow
import * as yup from 'yup';
import * as R from 'ramda';
import fieldValidators from '../fieldValidators';
import { secondsToHumanReadableString } from './timeutils';
import type { CommandEntityT } from '../ducks/entities/welcomeAttendant/welcomeAttendantTypes';

type FieldLimitsT = {
  min: ?number,
  max: ?number,
  default: ?number
};

type DurationFieldLimitsT = {
  min: ?string,
  max: ?string,
  default: ?string
};

// eslint-disable-next-line import/prefer-default-export
export const getIntegerFieldLimits = (fieldSchema: *): FieldLimitsT => {
  const minTest = fieldSchema.tests.find(test => test.OPTIONS.name === 'min');
  const maxTest = fieldSchema.tests.find(test => test.OPTIONS.name === 'max');
  return {
    min: minTest ? minTest.OPTIONS.params.min : undefined,
    max: maxTest ? maxTest.OPTIONS.params.max : undefined,
    default: fieldSchema.default()
  };
};

export const getDurationFieldLimits = (fieldSchema: *): DurationFieldLimitsT => {
  const limits = getIntegerFieldLimits(fieldSchema);
  return {
    min: limits.min ? secondsToHumanReadableString(limits.min) : undefined,
    max: limits.max ? secondsToHumanReadableString(limits.max) : undefined,
    default: limits.default ? secondsToHumanReadableString(limits.default) : undefined
  };
};

export const validateForwardingFieldWithType = (fieldValue: *) => {
  switch (fieldValue.type) {
    case 'TRANSFER_EXTENSION_GROUP':
    case 'TRANSFER_INTERNAL':
    case 'TRANSFER_ACD_CUSTOMER_SERVICE':
    case 'TRANSFER_IVR':
    case 'TRANSFER_WELCOME_ATTENDANT':
    case 'TRANSFER_ACD_SWITCHBOARD':
    case 'TRANSFER_SPEED_DIAL':
    case 'TRANSFER_OC':
      return fieldValue && fieldValue.value && fieldValue.value.length > 0;
    case 'TRANSFER_EXTERNAL':
      return (
        fieldValue &&
        fieldValue.value &&
        fieldValidators.phoneNumberValidator(fieldValue.value) === undefined
      );
    default:
      return true;
  }
};

export const isValidAddressNumber = (addressNumber: ?string) => {
  return addressNumber && /^\d{3,8}$/.test(addressNumber);
};

export const validateForwardingField = (errorMsg: string): * =>
  yup.mixed().test({
    name: 'value',
    exclusive: false,
    params: {},
    message: errorMsg,
    test: value => validateForwardingFieldWithType(value)
  });

export const validateStepNameIsCorrectFormat = (fieldValue: string) =>
  !!fieldValue.match(/^[A-Za-z0-9_]+$/);

export const validateStepNameWithExistingStepNames = (
  fieldValue: string,
  commandNames: Array<string>
) =>
  fieldValue
    ? !commandNames.map(name => name.toUpperCase()).includes(fieldValue.toUpperCase())
    : true;

const validateWithOtherFormValues = (value, valuesToCompare) =>
  valuesToCompare.find(v => v && value.toUpperCase() === v.toUpperCase()) === undefined;

const getNewTargetNamesFromForm = (valuesToCompare, parent): Array<string> =>
  valuesToCompare.length > 0
    ? valuesToCompare.flatMap(value =>
        Array.isArray(R.path(value, parent))
          ? R.path(value, parent).map(v => v.newFieldName && v.newFieldName.toUpperCase())
          : R.path(value, parent) && R.path(value, parent).toUpperCase()
      )
    : [];

export const isStepNameValid = (value: string): boolean => {
  return validateStepNameIsCorrectFormat(value);
};

export const isStepNameUnique = (
  value: ?string,
  commandNames: Array<string>,
  valuesToCompare: Array<string>
): boolean => {
  return value || value === ''
    ? validateStepNameWithExistingStepNames(value, commandNames) &&
        validateWithOtherFormValues(value, valuesToCompare)
    : true;
};

export const validateDialOptions = (
  errorMsgInvalid: string,
  errorMsgUnique: string,
  parent: *,
  value: ?string,
  currentField: *,
  createError: *,
  commandNames: Array<string>
) => {
  if (value && !isStepNameValid(value)) {
    return createError({ message: errorMsgInvalid });
  }
  // Validate with parent current step name
  if (value && value.toUpperCase() === parent.stepName.toUpperCase()) {
    return createError({ message: errorMsgUnique });
  }
  // Validate with retry new step name
  if (
    value &&
    parent.retryStep.newStepName &&
    value.toUpperCase() === parent.retryStep.newStepName.toUpperCase()
  ) {
    return createError({ message: errorMsgUnique });
  }
  // Validate with other step names
  if (value && !validateStepNameWithExistingStepNames(value, commandNames)) {
    return createError({ message: errorMsgUnique });
  }
  // Validate with other dial option names
  const dialOptionNames = parent.dialOptions
    .filter(opt => opt.choice !== currentField.choice && opt.newFieldName)
    .map(opt => opt.newFieldName.toUpperCase());
  if (value && dialOptionNames.includes(value.toUpperCase())) {
    return createError({ message: errorMsgUnique });
  }
  return true;
};

export const validateStepName = (
  errorMsgInvalid: string,
  errorMsgUnique: string,
  commandNames: Array<string>,
  valuesToCompare: Array<Array<string>>,
  fieldToCompare?: Array<string>
) =>
  yup.mixed().test('validateStepName', errorMsgInvalid, function(value) {
    const valueToCompare = fieldToCompare ? R.path(fieldToCompare, value) : value;
    if (!valueToCompare) {
      // If the value starts with +, it is a new step and should not be empty
      return !(value && value.value && value.value[0] && value.value[0].startsWith('+'));
    }
    if (!isStepNameValid(valueToCompare)) {
      return this.createError({ message: errorMsgInvalid });
    }
    if (
      !isStepNameUnique(
        valueToCompare,
        commandNames,
        getNewTargetNamesFromForm(valuesToCompare, this.parent)
      )
    ) {
      return this.createError({ message: errorMsgUnique });
    }
    return true;
  });

export const getNumbers = (fieldValue: string): * => {
  return fieldValue ? fieldValue.split(',').map(number => ({ label: number, value: number })) : [];
};

export const getCommandNames = (
  commands: { [string]: CommandEntityT },
  currentNodeName: string
): Array<string> =>
  R.values(commands)
    .map(value => value && value.name.toUpperCase())
    .filter(name => currentNodeName.toUpperCase() !== name);
