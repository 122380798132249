// @flow
import React, { type Element } from 'react';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import { useTranslation } from 'react-i18next';
import AudioFileSelection from '../../callFlows/components/edit/children/audio/AudioFileSelection';

import styles from './IvrAudio.module.scss';

export type PropsT = {|
  audio: *,
  updateAudio: (*) => Promise<void>,
  updateRecordingNotification: (*) => void,
  title?: string,
  description?: string,
  audioKey?: string,
  disabled?: boolean
|};

export const IvrAudio = (props: PropsT): Element<'div'> => {
  const {
    audio,
    title,
    description,
    updateAudio,
    audioKey,
    updateRecordingNotification,
    disabled
  } = props;
  const { t } = useTranslation();

  const viewWithDefaultAudio = t('callflows.audioField.defaultOption');
  const customAudioLabel = t('callflows.audioField.customAudioLabel');
  const defaultAudioFileSelector = viewWithDefaultAudio;
  const customAudioFileSelector = (
    <div>
      <AudioFileSelection
        key={audioKey}
        audio={audio}
        onChange={file => {
          updateRecordingNotification({
            ...audio,
            audioFileToImport: file
          });
        }}
        disabled={audio.level === 'Default'}
        showInitialView
        fileRestUri={audio.fileRestUri}
      />
    </div>
  );

  return (
    <div className={title && styles['title-padding']}>
      {title && <div>{title}</div>}
      <div className={styles.description}>
        {description === undefined ? t('callflows.audioField.description') : description}
      </div>
      <div role="group" aria-label="Message type">
        <RadioGroup value={audio.level} disabled={disabled}>
          <Radio
            id="default_audio"
            name="audioSelector"
            label={defaultAudioFileSelector}
            value="Default"
            onChange={() => {
              updateAudio({
                ...audio,
                level: 'Default',
                filename: 'EnterpriseConfiguration_RecordingNotification_Default.mp3'
              });
            }}
          />
          <Radio
            id="custom_audio"
            name="audioSelector"
            value="Entreprise"
            onChange={() => {
              updateAudio({
                ...audio,
                level: 'Entreprise',
                filename: 'EnterpriseConfiguration_RecordingNotification_Entreprise.mp3'
              });
            }}
            label={customAudioLabel}
          />
        </RadioGroup>
        {customAudioFileSelector}
      </div>
    </div>
  );
};

export default IvrAudio;
